import { applyMiddleware, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import rootSaga from "./sagas";
import { createReducers } from "./reducers";

// Create a history of your choosing (we're using a browser history in this case)
export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const middlewareEnhancer = applyMiddleware(
  routerMiddleware(history),
  sagaMiddleware
);
const composedEnhancers = composeWithDevTools(middlewareEnhancer);

const store = createStore(createReducers(history), {}, composedEnhancers);

sagaMiddleware.run(rootSaga);

export default store;
