import { push } from "connected-react-router";
import { takeLatest, call, put } from "redux-saga/effects";

import * as Sentry from "@sentry/browser";

import Auth from "../../utils/Auth";
import * as authActions from "../actions/AuthActions";

function* login({ credentials }) {
  try {
    yield call(Auth.login, credentials);
    yield call(fetchUserInfo);
    yield put(push("/")); //TODO: Redirect to previous page
  } catch (err) {
    yield put(authActions.loginFailed());
  }
}

function* logout() {
  try {
    yield call(Auth.logout);
    yield put(push("/"));
  } catch (err) {
    yield put(push("/"));
  }
}

function* fetchUserInfo() {
  try {
    const userInfo = yield call(Auth.getUserInfo);
    yield put(authActions.userInfoFetched(userInfo));
  } catch (err) {
    yield put(authActions.authenticationRequired());
  }
}

function* renewToken() {
  try {
    yield call(Auth.renewToken);
    yield put(authActions.tokenRenewed());
  } catch (err) {
    //Error renewing. Ignore.
  }
}

function identifyUser(action) {
  Sentry.configureScope(scope => {
    scope.setUser({ id: action.userInfo.id });
  });
}

export default function* root() {
  yield takeLatest(authActions.LOGIN, login);
  yield takeLatest(authActions.LOGOUT, logout);
  yield takeLatest(authActions.RENEW_TOKEN, renewToken);
  yield takeLatest(authActions.USER_INFO_REQUESTED, fetchUserInfo);
  yield takeLatest(authActions.USER_INFO_FETCHED, identifyUser);
}
