import styled from "styled-components";

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Title = styled.h3`
  display: flex;
  align-items: center;
  font-size: 18px;
`;

export const IconWrapper = styled.div`
  margin-right: 10px;
`;

export const ChartContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
  gap: 20px;
`;

export const IFrameWrapper = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
