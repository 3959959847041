import React from "react";
import _ from "lodash";
import FormattedDate from "../common/FormattedDate";

const SearchResultTable = ({ result, accountId }) => {
  const dismissData = (result.dismissed_for || []).find(
    d => d.account_id === accountId
  );

  return (
    <tr>
      <td>
        <div className="m-widget5__pic">
          <img
            className="m-widget7__img"
            style={{ height: "100px", maxWidth: "150px" }}
            src={result.thumbnail}
            alt=""
          />
        </div>
      </td>
      <td>
        <h5 className="m-widget5__title">{result.title}</h5>
        <div className="m-widget5__info">
          <span className="m-widget5__info-label">Published:&nbsp;</span>
          <span className="m-widget5__info-date m--font-info">
            <FormattedDate date={result.published_date} />
          </span>
          <br />
          <span className="m-widget5__info-label">Sources:&nbsp;</span>
          <span className="m-widget5__info-date m--font-info">
            {_.uniq(
              (result.sources || []).map(s => s.source_name || s.type)
            ).join(", ")}
          </span>
          <br />
          <span className="m-widget5__info-date m--font-info">
            <a href={result.url} target="_blank" rel="noopener noreferrer">
              {result.domain} <i className="fa fa-external-link-alt" />
            </a>
          </span>
          {dismissData && (
            <div>
              <span className="m-widget5__info-label">Dismissed On:&nbsp;</span>
              <span className="m-widget5__info-date m--font-info">
                <FormattedDate date={dismissData.date} />
              </span>
              <br />
              <span className="m-widget5__info-label">Reason:&nbsp;</span>
              <span className="m-widget5__info-date m--font-info">
                {dismissData.details?.reason || "N/A"}
              </span>
              <br />
              {dismissData.details?.domain && (
                <div>
                  <span className="m-widget5__info-label">
                    Blocked Domain:&nbsp;
                  </span>
                  <span className="m-widget5__info-date m--font-info">
                    {dismissData.details?.domain}
                  </span>
                  <br />
                </div>
              )}
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

const DismissedList = ({ results, accountId }) => {
  return (
    <div className="dataTables_wrapper">
      <div className="row">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {results.map(result => (
              <SearchResultTable
                key={result.id}
                accountId={accountId}
                result={result}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DismissedList;
