import { takeLatest, put, select } from "redux-saga/effects";

import * as suggestedPostActions from "../actions/SuggestedPostActions";

import { callApi } from "../../utils/ContentoApi";
import { toastr } from "react-redux-toastr";

const getSelectedAccount = state => {
  return state.accounts.selectedAccount;
};

function* fetchSuggestedPosts() {
  const account = yield select(getSelectedAccount);
  const suggestedPosts = yield callApi({
    method: "get",
    url: `accounts/${account.id}/suggested-posts`
  });
  yield put(suggestedPostActions.suggestedPostsFetched(suggestedPosts));
}

function* deleteSuggestedPost({ postId, accountId }) {
  try {
    yield callApi({
      method: "delete",
      url: `accounts/${accountId}/suggested-posts/${postId}`
    });

    yield put(suggestedPostActions.fetchSuggestedPosts());
  } catch (error) {
    toastr.error("Error deleting suggested post", error.message);
  }
}

export default function* root() {
  yield takeLatest(
    suggestedPostActions.FETCH_SUGGESTED_POSTS,
    fetchSuggestedPosts
  );
  yield takeLatest(
    suggestedPostActions.DELETE_SUGGESTED_POST,
    deleteSuggestedPost
  );
}
