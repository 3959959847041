import React from "react";

import { Text } from "rebass/styled-components";
import { StyledInputLabel } from "components/common/form/styles";
import { StyledContainer } from "./styles";

type SelectorContainerProps = {
  onClick: () => void;
  displayText?: string;
  hasError: boolean;
  label: string;
  placeHolder: string;
};
const SelectorContainer = ({
  displayText,
  onClick,
  hasError,
  label,
  placeHolder
}: SelectorContainerProps) => {
  return (
    <>
      <StyledInputLabel>
        {label} {<span style={{ color: "#FF0E0E" }}>{` * `}</span>}
      </StyledInputLabel>

      <StyledContainer onClick={onClick} hasError={hasError}>
        {displayText ? (
          <Text>{displayText}</Text>
        ) : (
          <Text sx={{ color: "#3C3F4254" }}>{placeHolder}</Text>
        )}
      </StyledContainer>
    </>
  );
};

export default SelectorContainer;
