import React from "react";
import { Keyword, KeywordToolbar } from "./styles";

function KeywordBar({ keywords, onClickKeyword }) {
  return (
    <KeywordToolbar>
      <span>Keywords: </span>
      {keywords.map(keyword => (
        <Keyword key={keyword} onClick={() => onClickKeyword(keyword)}>
          {keyword}
        </Keyword>
      ))}
    </KeywordToolbar>
  );
}
export default KeywordBar;
