import React, { useState } from "react";
import FileUpload from "components/attachment/FileUpload";
import { Flex, Text } from "rebass/styled-components";
import { FileUpload as UploadIcon } from "@styled-icons/material-outlined/FileUpload";
import {
  Control,
  FieldValues,
  Path,
  UseControllerProps,
  useController
} from "react-hook-form";
import FormattedDate from "components/common/FormattedDate";
import { Calendar } from "@styled-icons/boxicons-regular/Calendar";
import { CalendarEvent } from "./CalendarEventsForm";
import { Trash3 as Trash } from "@styled-icons/bootstrap/Trash3";
import { toastr } from "react-redux-toastr";
import Papa from "papaparse";
import { ErrorMessage } from "components/common/form/styles";

interface Props<T extends FieldValues>
  extends UseControllerProps<T>,
    React.CSSProperties {
  control?: Control<T>;
  name: Path<T>;
  helperText?: string;
}

const CalendarCsvInput = <T extends FieldValues>(inputs: Props<T>) => {
  let {
    fieldState,
    field: { value, onChange }
  } = useController(inputs);

  if (!value) {
    onChange([]);
  }

  const hasError = fieldState.error !== undefined;

  const [currentFile, setCurrentFile] = useState<File>();

  const parseCsv = (file: File) => {
    const evnts: CalendarEvent[] = [];
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: function (results) {
        console.log(results.data);
        results.data.forEach((e: any) => {
          if (e.Start_Date && e.Name) {
            evnts.push({
              startsAt: new Date(e.Start_Date),
              ...(e.End_Date ? { endsAt: new Date(e.End_Date) } : {}),
              name: e.Name
            });
          }
        });

        // console.log("Events:: ", evnts);

        onChange(evnts);
      },
      error: function (err, file) {
        toastr.error(
          `There was an error parsing the CSV file. Please ensure adheres to template and try again later or contact support.`,
          ""
        );
      }
    });
  };

  return (
    <Flex
      flexDirection={"column"}
      sx={{
        padding: "12px 20px",
        gap: "20px",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "15px",
        color: "black"
      }}
    >
      <UploadCsvButton
        currentFile={currentFile}
        onCsvFileChange={file => {
          setCurrentFile(file);
          parseCsv(file);
        }}
        onRemoveFile={() => {
          setCurrentFile(undefined);
          onChange([]);
        }}
      />
      {hasError && <ErrorMessage>{fieldState.error?.message}</ErrorMessage>}
      <CalendarEventsList events={value} />
    </Flex>
  );
};

const UploadCsvButton = ({
  onCsvFileChange,
  currentFile,
  onRemoveFile
}: {
  onCsvFileChange: (file: File) => void;
  currentFile?: File;
  onRemoveFile: () => void;
}) => {
  return (
    <Flex
      sx={{
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #DDDDDD",
        borderRadius: "5px",
        width: "100%"
      }}
    >
      {currentFile ? (
        <FileView file={currentFile} onRemoveFile={onRemoveFile} />
      ) : (
        <FileUpload
          onUpload={(files: any) => {
            if (files) {
              console.log("New Files:: ", files);
              onCsvFileChange(files[0]);
            }
            // console.log("Image Upload:: ", images);
          }}
          width="100%"
          height={"fit-content"}
          border="none"
          idleStateView={<NoCSVView onClick={() => {}} />}
          multiple={false}
          accept=".csv"
          // {...formField}
        />
      )}
    </Flex>
  );
};

const NoCSVView = ({ onClick }: { onClick: () => void }) => {
  return (
    <Flex
      sx={{
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "20px",
        color: "#4A4A4A",
        width: "100%",
        cursor: "pointer",
        padding: "15px 10px"
      }}
      onClick={onClick}
    >
      <UploadIcon size={"18px"} />
      <Text>Upload CSV</Text>
    </Flex>
  );
};

const CalendarEventsList = ({ events }: { events: CalendarEvent[] }) => {
  return (
    <Flex
      flexDirection={"column"}
      sx={{ gap: "8px", height: "30vh", overflowY: "auto" }}
    >
      {!events || events.length === 0 ? (
        <NoEventIndicator />
      ) : (
        events.map((event, index) => {
          return (
            <Flex
              key={`${index}-${event.startsAt}`}
              flexDirection={"column"}
              sx={{
                borderRadius: "5px",
                padding: "10px 20px 8px",
                border: "1px solid #F6F6F6",
                boxShadow: "0px 4px 4px 0px #0000000D",
                backgroundColor: "#F6F6F6",
                gap: "8px",
                textTransform: "capitalize"
              }}
            >
              <Text>{event.name}</Text>
              <Flex
                sx={{ gap: "10px", alignItems: "center", color: "#7A7A7A" }}
              >
                <Calendar size={"14px"} />
                <Text>
                  {FormattedDate({
                    date: event.startsAt,
                    format: "Do MMM YYYY"
                  })}
                  {!!event.endsAt &&
                    ` - ${FormattedDate({
                      date: event.endsAt,
                      format: "Do MMM YYYY"
                    })}`}
                </Text>
              </Flex>
            </Flex>
          );
        })
      )}
    </Flex>
  );
};

const NoEventIndicator = () => {
  return (
    <Flex
      sx={{
        borderRadius: "5px",
        padding: "10px 20px 8px",
        border: "1px solid #F6F6F6",
        boxShadow: "0px 4px 4px 0px #0000000D",
        backgroundColor: "#F6F6F6",
        color: "black"
      }}
    >
      <Text fontSize={"12px"} fontWeight={400} lineHeight={"15px"}>
        Upload a CSV file to add events
      </Text>
    </Flex>
  );
};

const FileView = ({
  file,
  onRemoveFile
}: {
  file: File;
  onRemoveFile: () => void;
}) => {
  return (
    <Flex sx={{ gap: "20px", alignItems: "center", margin: "15px" }}>
      <Text>{file.name}</Text>
      <Trash
        size={"12px"}
        onClick={() => onRemoveFile()}
        data-tip="Remove File"
        cursor={"pointer"}
      />
    </Flex>
  );
};

export default CalendarCsvInput;
