import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";

import { required, validWebsite } from "../../utils/Validation";
import TextInput from "../common/form/TextInput";
import Select from "../common/form/Select";
import Checkboxes from "../common/form/Checkboxes";

import { callApi } from "../../utils/ContentoApi";
import { languages } from "../../constants";

const SourceForm = props => {
  const { handleSubmit, submitting, sourceTypeValue, change, onCancel } = props;
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    callApi({
      method: "get",
      url: "/topics"
    })
      .then(allTopics =>
        setTopics(
          _.uniq(allTopics.map(t => t.label)).map(label => ({
            label,
            value: label
          }))
        )
      )
      .catch(error => {
        console.error(error);
        toastr.error(error.message);
      });
  }, []);

  function getTypeSpecificElements() {
    if (!sourceTypeValue) {
      return null;
    }

    if (sourceTypeValue === "twitter") {
      return [
        <Field
          key="twittter_screen_name"
          label="Twitter Handle"
          component={TextInput}
          name="handle"
          validate={[required]}
        />
      ];
    }

    return [
      <Field
        key="url"
        label="URL"
        component={TextInput}
        name="url"
        validate={[required, validWebsite]}
      />
    ];
  }

  function onTypeChange() {
    change("url", "");
    change("handle", "");
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="m-form m-form--fit m-form--label-align-right"
    >
      <div className="m-portlet__body">
        <Field
          label="Language"
          component={Select}
          options={languages}
          name="language"
        />

        <Field label="Topic" component={Select} options={topics} name="topic" />

        <div className="m-form__seperator m-form__seperator--dashed m-form__seperator--space-2x" />

        <Field
          label="Type"
          component={Select}
          options={[
            { value: "rss", label: "RSS" },
            { value: "twitter", label: "Twitter" },
            { value: "html-links", label: "HTML Links" }
          ]}
          name="type"
          validate={[required]}
          onChange={onTypeChange}
        />

        {getTypeSpecificElements()}

        <div className="m-form__seperator m-form__seperator--dashed m-form__seperator--space-2x" />

        <Field
          label="URL Filter"
          component={TextInput}
          name="urlFilter"
          validate={sourceTypeValue === "html-links" ? [required] : []}
        />

        <Field
          component={Checkboxes}
          options={[
            {
              value: "true",
              label: "URL Filter is a Regular Expression"
            }
          ]}
          name="isUrlFilterRegExp"
        />
      </div>
      <div className="m-portlet__foot m-portlet__foot--fit">
        <div className="m-form__actions">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-7">
              <button
                type="submit"
                className="btn btn-accent m-btn m-btn--air m-btn--custom"
                disabled={submitting}
              >
                Save changes
              </button>
              &nbsp;&nbsp;
              <button
                className="btn btn-secondary m-btn m-btn--air m-btn--custom"
                disabled={submitting}
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

//const selector = formValueSelector('selectingFormValues')

const formName = "sourceForm";

let theForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true
})(SourceForm);

const selector = formValueSelector(formName);

theForm = connect(state => {
  const sourceTypeValue = selector(state, "type");
  return {
    sourceTypeValue
  };
})(theForm);

export default theForm;
