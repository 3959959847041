import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import FallbackImage from "../../components/common/FallbackImage";
import emptyProfileIcon from "../../assets/images/profile-icon.png";
import Auth from "../../utils/Auth";

import PaginatedAccountList from "./PaginatedAccountList";

export default function InactiveSinceOnboarding(props) {
  const tableHeader = (
    <tr>
      <th style={{ width: "10%" }} />
      <th style={{ width: "25%" }}>Name</th>
      <th style={{ width: "25%", textAlign: "center" }}>
        Are channels connected?
      </th>
      <th style={{ width: "20%", textAlign: "center" }}>Create date</th>
      <th style={{ width: "10%", textAlign: "center" }} />
    </tr>
  );

  const handleButtonClick = accountId => {
    return Auth.impersonateCustomer(accountId, props.currentUser.id);
  };

  const buildRecord = account => (
    <tr key={account.id} style={{ borderBottom: "1px solid #eee" }}>
      <td
        style={{
          verticalAlign: "middle",
          textAlign: "center"
        }}
      >
        <FallbackImage
          src={account.logo || emptyProfileIcon}
          alt={account.companyName}
          height={50}
          width={50}
        />
      </td>
      <td style={{ verticalAlign: "middle" }}>
        <Link to={`/accounts/${account.id}`}>{account.companyName}</Link>
      </td>
      <td
        style={{
          verticalAlign: "middle",
          textAlign: "center"
        }}
      >
        {account.hasConnectedChannels ? "Yes" : "No"}
      </td>
      <td
        style={{
          verticalAlign: "middle",
          textAlign: "center"
        }}
      >
        {moment(account.createdAt).format("DD/MM/YYYY")}
      </td>
      <td
        style={{
          verticalAlign: "middle",
          textAlign: "center"
        }}
      >
        <button
          onClick={() => handleButtonClick(account.id)}
          className="m-btn m-btn--pill m-btn--hover-brand btn btn-sm btn-secondary"
        >
          Create Post
        </button>
      </td>
    </tr>
  );

  return (
    <PaginatedAccountList
      {...props}
      title="Inactive since onboarding (No posts since onboarding)"
      url="/admin/accounts/inactive-since-onboarding"
      tableHeader={tableHeader}
      buildRecord={buildRecord}
    />
  );
}
