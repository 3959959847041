import React from "react";
import CreateAccountModalFooter from "./CreateAccountModalFooter";
import { IAdmin, Stages } from "./CreateAccountModal";
import { useForm } from "react-hook-form";

import { ModalBody } from "../styles";
import AdminSelector from "./AdminSelector";
import StyledTextAreaInput from "components/common/form/StyledTextAreaInput";
import PackageSelector, { IPackageOption } from "./PackageSelector";
import { required } from "utils/Validation";
import { AddonSelectorInput, IAddonOption } from "./AddonSelector/index";

type QuoteDetailsFormProps = {
  setCurrentStage: (newStage: Stages) => void;
  onSubmit: (values: QuoteDetailsFormInputs) => Promise<void>;
  admins: IAdmin[];
  isLoadingAdmins: boolean;
  quoteDetails?: QuoteDetailsFormInputs;
};

export interface QuoteDetailsFormInputs {
  accountExecutive: IAdmin;
  message?: string;
  package: IPackageOption;
  addons?: IAddonOption[];
}

const QuoteDetailsForm = ({
  setCurrentStage,
  admins,
  onSubmit,
  quoteDetails
}: QuoteDetailsFormProps) => {
  const { handleSubmit, control } = useForm<QuoteDetailsFormInputs>({
    defaultValues: quoteDetails
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        <AdminSelector
          control={control}
          name="accountExecutive"
          label="Account Executive"
          placeholder="Select an Account Executive"
          admins={admins}
        />

        <StyledTextAreaInput<QuoteDetailsFormInputs>
          control={control}
          label="Quote Details"
          name="message"
          placeholder="Add discussion points, next steps, or even just a thank you note..."
        />

        <PackageSelector
          control={control}
          name="package"
          label="Package Type & Terms"
          placeholder="Select a package and payment terms"
          required
          rules={{
            validate: {
              required
            }
          }}
        />

        <AddonSelectorInput
          label="Addon Types"
          control={control}
          defaultValue={[]}
          name="addons"
        />
      </ModalBody>

      <CreateAccountModalFooter
        currentStage={Stages.QUOTE_PREPARATION}
        setCurrentStage={setCurrentStage}
        actionButtonText="Review & Create"
      />
    </form>
  );
};

export default QuoteDetailsForm;
