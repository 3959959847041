export const FETCH_ACCOUNTS = "@@account/FETCH_ACCOUNTS";
export const FETCH_ACCOUNT = "@@account/FETCH_ACCOUNT";

export const ACCOUNTS_FETCHED = "@@account/ACCOUNTS_FETCHED";
export const ACCOUNT_FETCHED = "@@account/ACCOUNT_FETCHED";
export const UPDATE_ACCOUNT = "@@account/UPDATE_ACCOUNT";
export const UPDATE_ACCOUNT_ERROR = "@@account/UPDATE_ACCOUNT_ERROR";
export const ACCOUNT_UPDATED = "@@account/ACCOUNT_UPDATED";
export const DELETE_ACCOUNT = "@@account/DELETE_ACCOUNT";

export const FETCH_ACCOUNT_SEARCH_TERMS =
  "@@account/FETCH_ACCOUNT_SEARCH_TERMS";
export const ACCOUNT_SEARCH_TERMS_FETCHED =
  "@@account/ACCOUNT_SEARCH_TERMS_FETCHED";
export const ADD_ACCOUNT_SEARCH_TERM = "@@account/ADD_ACCOUNT_SEARCH_TERM";
export const REMOVE_ACCOUNT_SEARCH_TERM =
  "@@account/REMOVE_ACCOUNT_SEARCH_TERM";

export const FETCH_PROFILES = "@@account/FETCH_PROFILES";
export const PROFILES_FETCHED = "@@account/PROFILES_FETCHED";
export const ADD_PROFILE = "@@account/ADD_PROFILE";
export const REMOVE_PROFILE = "@@account/REMOVE_PROFILE";

export const fetchAccounts = filters => {
  return {
    type: FETCH_ACCOUNTS,
    filters
  };
};

export const fetchAccount = accountId => {
  return {
    type: FETCH_ACCOUNT,
    accountId
  };
};

export const accountsFetched = accounts => {
  return {
    type: ACCOUNTS_FETCHED,
    accounts
  };
};

export const accountFetched = account => {
  return {
    type: ACCOUNT_FETCHED,
    account
  };
};

export const updateAccount = (accountId, values) => {
  return {
    type: UPDATE_ACCOUNT,
    accountId,
    values
  };
};

export const updateAccountError = error => {
  return {
    type: UPDATE_ACCOUNT_ERROR,
    error
  };
};

export const accountUpdated = account => {
  return {
    type: ACCOUNT_UPDATED,
    account
  };
};

export const deleteAccount = accountId => {
  return {
    type: DELETE_ACCOUNT,
    accountId
  };
};

export const fetchAccountSearchTerms = accountId => {
  return {
    type: FETCH_ACCOUNT_SEARCH_TERMS,
    accountId
  };
};

export const accountSearchTermsFetched = (accountId, searchTerms) => {
  return {
    type: ACCOUNT_SEARCH_TERMS_FETCHED,
    accountId,
    searchTerms
  };
};

export const addAccountSearchTerm = (accountId, values) => {
  return {
    type: ADD_ACCOUNT_SEARCH_TERM,
    accountId,
    values
  };
};

export const removeAccountSearchTerm = (accountId, searchTermId) => {
  return {
    type: REMOVE_ACCOUNT_SEARCH_TERM,
    accountId,
    searchTermId
  };
};

export const fetchProfiles = accountId => {
  return {
    type: FETCH_PROFILES,
    accountId
  };
};

export const profilesFetched = (accountId, profiles) => {
  return {
    type: PROFILES_FETCHED,
    accountId,
    profiles: profiles
  };
};

export const addProfile = (accountId, values) => {
  return {
    type: ADD_PROFILE,
    accountId,
    values
  };
};

export const removeProfile = (accountId, profileId) => {
  return {
    type: REMOVE_PROFILE,
    accountId,
    profileId
  };
};
