import React from "react";
import _ from "lodash";
import Select from "react-select";

import DatePicker from "../../common/DatePicker";
import Portlet from "../../common/portlet/Portlet";
import {
  FacetItemContainer,
  DatePickerContainer,
  FacetContainer
} from "./styles";

function SearchFacet(props) {
  const { field, items, facetFilters, onFacetChange } = props;
  const sortedItems = items.sort((a, b) => {
    if (a.value === "N/A" || a.total > b.total) {
      return -1;
    }

    if (b.value === "N/A" || b.total < a.total) {
      return 1;
    }

    return 0;
  });

  const itemComponents = sortedItems.map(({ value, total }) => {
    const enabled = !!facetFilters.find(
      filter => filter.field === field && filter.value === value
    );

    function itemClicked() {
      onFacetChange({ field, value, enabled: !enabled });
    }

    return (
      <div className="row" key={`${field}-${value}`}>
        <div className="col-xl-12">
          <FacetItemContainer onClick={itemClicked}>
            <input
              type="checkbox"
              checked={enabled ? "checked" : ""}
              onChange={itemClicked}
            />
            &nbsp;
            <span>
              {value} ({total})
            </span>
          </FacetItemContainer>
        </div>
      </div>
    );
  });

  return (
    <Portlet title={_.startCase(field)}>
      <FacetContainer>{itemComponents}</FacetContainer>
    </Portlet>
  );
}

export default function SearchFacets({
  facetFilters,
  searchResult,
  onFacetChange,
  dateFilter,
  onDateFilterChanged,
  accounts,
  setSelectedAccount
}) {
  const facetsComponents = Object.keys(searchResult.facets)
    .sort()
    .map(field => (
      <SearchFacet
        key={field}
        field={field}
        facetFilters={facetFilters}
        items={searchResult.facets[field]}
        onFacetChange={onFacetChange}
      />
    ));

  const dateFilterComponent = (
    <Portlet title="Pubished Date" key="dateFilter">
      <DatePickerContainer>
        <label>From:</label>
        <DatePicker
          placeholder="Select a start date"
          selected={dateFilter.from}
          onChange={date => onDateFilterChanged("from", date)}
        />
      </DatePickerContainer>
      <DatePickerContainer>
        <label>To:</label>
        <DatePicker
          placeholder="Select an end date"
          selected={dateFilter.to}
          onChange={date => onDateFilterChanged("to", date)}
        />
      </DatePickerContainer>
    </Portlet>
  );

  const accountSelectorComponent = (
    <Portlet title="Account" key="accountSelector">
      <div className="m-form__group m-form__group--inline">
        <div className="m-form__control">
          <Select
            isClearable
            options={accounts.map(({ companyName, id }) => ({
              value: id,
              label: companyName
            }))}
            onChange={option =>
              setSelectedAccount(
                option ? { companyName: option.label, id: option.value } : null
              )
            }
          />
        </div>
      </div>
    </Portlet>
  );

  return [dateFilterComponent, accountSelectorComponent, ...facetsComponents];
}
