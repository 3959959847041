import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import { Redirect, Route, Switch, withRouter } from "react-router";
import { connect } from "react-redux";
import ReduxToastr from "react-redux-toastr";

import "./assets/vendors.bundle.css";
import "./assets/style.bundle.css";
import "./assets/datatables.bundle.css";
import "./assets/css/react-redux-toastr.min.css";
import "./assets/css/contento.css";

import "react-datepicker/dist/react-datepicker.css";

import * as authActions from "./state/actions/AuthActions";
import SecureRoute from "./components/common/SecureRoute";

import MainDashboardPage from "./containers/dashboard/MainDashboardPage";
import AccountsOverviewPage from "./containers/accounts/AccountsOverviewPage";
import NotFoundPage from "./containers/NotFoundPage";

import Login from "./containers/auth/LoginPage";
import Logout from "./components/auth/Logout";
import AccountContainer from "./containers/accounts/AccountContainer";
import NewAccountPage from "./containers/accounts/NewAccountPage";
import SearchVettedContentPage from "./containers/content/SearchVettedContentPage";
import ModalRoot from "./components/modals/ModalRoot";
import ErrorPage from "./containers/ErrorPage";
import UserOverviewPage from "./containers/users/UserOverviewPage";
import NewUserPage from "./containers/users/NewUserPage";
import UserDetailPage from "./containers/users/UserDetailPage";

import OperationsPage from "./containers/operations/OperationsPage";
import HangingPostsPage from "./containers/operations/HangingPostsPage";

import MainCalendarsPage from "containers/calendars/MainCalendarsPage";

import SourceListPage from "./containers/sources/SourceListPage";
import SourceAddPage from "./containers/sources/SourceAddPage";

import { theme } from "./theme";
import { ThemeProvider } from "styled-components";
import TopicsListPage from "./containers/topics/TopicsListPage";
import MainHeader from "components/navigation/MainHeader";

class App extends Component {
  componentDidMount() {
    if (this.props.hasValidToken) {
      this.props.renewToken();
    }
  }

  //Makes this component an error boundary
  componentDidCatch(error, info) {
    if (process.env.REACT_APP_ENV === "production") {
      Sentry.withScope(scope => {
        scope.setExtras(info);
        Sentry.captureException(error);
      });
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <>
          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
          />

          {this.props.isAuthenticated && <MainHeader />}

          <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
            <button
              className="m-aside-left-close m-aside-left-close--skin-light "
              id="m_aside_left_close_btn"
            >
              <i className="la la-close" />
            </button>
            <div className="m-aside-menu-overlay" />

            <div className="m-grid__item m-grid__item--fluid m-wrapper">
              <Switch>
                <SecureRoute exact path="/" component={MainDashboardPage} />

                <SecureRoute
                  path="/content/vetted/search"
                  component={SearchVettedContentPage}
                />

                <SecureRoute path="/sources/new" component={SourceAddPage} />
                <SecureRoute path="/sources" component={SourceListPage} />
                <SecureRoute path="/topics" component={TopicsListPage} />

                <SecureRoute path="/accounts/new" component={NewAccountPage} />
                <SecureRoute
                  path="/accounts/:accountId"
                  component={AccountContainer}
                />
                <SecureRoute
                  path="/accounts"
                  component={AccountsOverviewPage}
                />

                <SecureRoute path="/users/new" component={NewUserPage} />
                <SecureRoute path="/users/:userId" component={UserDetailPage} />
                <SecureRoute path="/users" component={UserOverviewPage} />

                <SecureRoute
                  path="/operations/hanging-posts"
                  component={HangingPostsPage}
                />
                <SecureRoute path="/operations" component={OperationsPage} />

                <SecureRoute path="/calendars" component={MainCalendarsPage} />

                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
                <Route path="/error" component={ErrorPage} />
                <Route path="/404" component={NotFoundPage} />

                <Redirect to="/404" />
              </Switch>
            </div>
          </div>
          <ModalRoot />
        </>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: !!state.auth.currentUser,
    hasValidToken: state.auth.hasValidToken
  };
};

export default withRouter(
  connect(mapStateToProps, {
    renewToken: authActions.renewToken
  })(App)
);
