import React from "react";
import moment from "moment";

/**
 * Returns a human readable description of a time difference in milliseconds.
 */
export const getHumanReadableTimeDifference = differenceInMillis => {
  if (differenceInMillis == null) {
    return "N/A";
  }

  const fromDate = moment();
  const toDate = moment().add(differenceInMillis, "milliseconds");

  const hoursUntil = toDate.diff(fromDate, "hours");

  if (hoursUntil) {
    if (hoursUntil > 24) {
      const daysUntil = toDate.diff(fromDate, "days");
      if (daysUntil > 7) {
        const weeksUntil = toDate.diff(fromDate, "weeks");
        if (weeksUntil > 4) {
          const monthsUntil = toDate.diff(fromDate, "months");
          if (monthsUntil > 12) {
            const yearsUntil = toDate.diff(fromDate, "years");
            return `${yearsUntil} ${yearsUntil === 1 ? "year" : "years"}`;
          }
          return `${monthsUntil} ${monthsUntil === 1 ? "month" : "months"}`;
        }
        return `${weeksUntil} ${weeksUntil === 1 ? "week" : "weeks"}`;
      }
      return `${daysUntil} ${daysUntil === 1 ? "day" : "days"}`;
    }
    return `${hoursUntil} ${hoursUntil === 1 ? "hour" : "hours"}`;
  }
  const minutesUntil = toDate.diff(fromDate, "minutes");

  if (minutesUntil) {
    return `${minutesUntil} ${minutesUntil === 1 ? "minute" : "minutes"}`;
  }

  // less than a minute
  return "< 1 minute";
};

/**
 * Returns a color depending on the time informed in millis.
 */
export const getColorForTime = value => {
  if (value) {
    const hours = Math.floor(value / 1000 / 60 / 60);

    if (hours > 78) {
      return "green";
    }

    if (hours > 24) {
      return "orange";
    }
  }

  return "red";
};

/**
 * Returns an element with a human readabl difference to the informed date.
 */
export const getTimeDifferenceElement = ({ date, applyColors }) => {
  const today = moment();
  const dateObj = moment(date);

  const differenceInMillis =
    date && Math.abs(dateObj.diff(today, "milliseconds"));

  const isPast = date && dateObj.isBefore(today);

  const humanReadableDescription =
    getHumanReadableTimeDifference(differenceInMillis);

  const description = isPast
    ? `${humanReadableDescription} ago`
    : humanReadableDescription;

  if (!applyColors) {
    return description;
  }

  return (
    <span style={{ color: getColorForTime(differenceInMillis) }}>
      {description}
    </span>
  );
};
