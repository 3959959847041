import { StyledInputProps } from "components/common/form/styles";
import styled from "styled-components";

export const StyledContainer = styled.div<StyledInputProps>`
  line-height: 140%;
  font-size: 16px;
  transition: border-color 0.2s ease-in-out 0s;
  font-weight: 400;
  letter-spacing: -0.16px;

  margin-top: 8px;
  border: 1px solid
    ${props =>
      props.hasError ? props.theme.colors.danger : props.theme.colors.grey02};
  border-radius: 8px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 11px 16px;

  &::placeholder {
    color: ${props => props.theme.colors.text04};
  }

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.grey01};
    color: ${props => props.theme.colors.text02};
  }
`;
