import React, { useState } from "react";
import Icon from "../common/Icon";
import { Filter } from "@styled-icons/bootstrap/Filter";
import { MoreVerticalOutline } from "@styled-icons/evaicons-outline/MoreVerticalOutline";
import { Search } from "@styled-icons/feather/Search";
import { EmptyContainer, ContentContainer } from "../common/AdminHeader";
import {
  SearchbarsContainer,
  ButtonContainer,
  SearchContainer,
  FilterText,
  SearchBar,
  ButtonGroup,
  VerticalIconContainer,
  StyledButton
} from "../common/FilterSections";

const types = ["facebook", "instagram", "linkedin", "X"];

type ActiveButtonsState = {
  group1: string[];
};

interface ChannelIssuesFilterProps {
  onFilterChange: (filters: string[]) => void;
  onSearchChange: (searchTerm: string) => void;
}

const ChannelIssuesFilter: React.FC<ChannelIssuesFilterProps> = ({
  onFilterChange,
  onSearchChange
}) => {
  const [activeButtons, setActiveButtons] = useState<ActiveButtonsState>({
    group1: ["All"]
  });
  const [searchTerm, setSearchTerm] = useState("");

  const handleButtonClick = (
    group: keyof ActiveButtonsState,
    label: string
  ) => {
    if (label === "All") {
      setActiveButtons({ group1: ["All"] });
      onFilterChange(["All"]);
    } else {
      const newSelectedTypes = activeButtons.group1.includes(label)
        ? activeButtons.group1.filter(t => t !== label)
        : [...activeButtons.group1.filter(t => t !== "All"), label];
      setActiveButtons({
        group1: newSelectedTypes.length ? newSelectedTypes : ["All"]
      });
      onFilterChange(newSelectedTypes.length ? newSelectedTypes : ["All"]);
    }
  };

  const isButtonActive = (group: keyof ActiveButtonsState, label: string) => {
    return activeButtons[group].includes(label);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    onSearchChange(value);
  };

  return (
    <EmptyContainer>
      <ContentContainer>
        <SearchbarsContainer>
          <SearchContainer>
            <Icon component={Search} size={20} />
            <SearchBar
              type="text"
              placeholder="Search by Account"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </SearchContainer>
        </SearchbarsContainer>
        <ButtonContainer>
          <Icon component={Filter} size={20} />
          <FilterText>Filters</FilterText>
          <ButtonGroup>
            <StyledButton
              active={isButtonActive("group1", "All")}
              onClick={() => handleButtonClick("group1", "All")}
            >
              All
            </StyledButton>
            {types.map(type => (
              <StyledButton
                key={type}
                active={isButtonActive("group1", type)}
                onClick={() => handleButtonClick("group1", type)}
              >
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </StyledButton>
            ))}
          </ButtonGroup>
          <VerticalIconContainer>
            <Icon component={MoreVerticalOutline} size={20} />
          </VerticalIconContainer>
        </ButtonContainer>
      </ContentContainer>
    </EmptyContainer>
  );
};

export default ChannelIssuesFilter;
