import React from "react";

import { connect } from "react-redux";
import Timeline from "../../../components/timeline/Timeline";
import * as scheduledPostActions from "../../../state/actions/ScheduledPostActions";
import { callApi } from "../../../utils/ContentoApi";
import { toastr } from "react-redux-toastr";
import PostItem from "../../../components/posts/PostItem";
import * as postActions from "../../../state/actions/PostActions";
import * as modalActions from "../../../state/actions/ModalActions";
import ChannelMultiSelect from "../../../components/common/form/ChannelMultiSelect";

class AccountSocialQueuePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduledPosts: [],
      isDeleting: false,
      visibleChannels: props.account.channels.map(c => c.id)
    };
  }

  componentDidMount() {
    this.refreshPosts();
  }

  refreshPosts() {
    this.props.fetchPosts({
      list: "scheduled",
      channels: this.state.visibleChannels
    });
  }

  handleDelete(post) {
    callApi({
      method: "delete",
      url: `/posts/${post.id}`
    })
      .then(() => {
        toastr.success(`Successfully deleted post.`);
        this.refreshPosts();
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({ scheduledPosts: [] });
      });
  }

  handleChannelSelect(channels) {
    this.setState({ visibleChannels: channels }, () => {
      this.refreshPosts();
    });
  }

  render() {
    const { isDeleting } = this.state;
    const { scheduledPosts, account } = this.props;
    return (
      <div className="m-portlet__body">
        <div>
          <ChannelMultiSelect
            input={{
              value: this.state.visibleChannels,
              onChange: this.handleChannelSelect.bind(this)
            }}
            meta={{}}
            channels={account.channels}
          />
        </div>
        <Timeline>
          {scheduledPosts.map(scheduledPost => (
            <PostItem
              key={scheduledPost.id}
              post={scheduledPost}
              onDelete={this.handleDelete.bind(this)}
              isDeleting={isDeleting}
            />
          ))}
        </Timeline>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    account: state.accounts.selectedAccount,
    scheduledPosts: state.posts.data.scheduled
  };
};

export default connect(mapStateToProps, {
  deleteScheduledPost: scheduledPostActions.deleteScheduledPost,
  fetchPosts: postActions.fetchPosts,
  openModal: modalActions.openModal
})(AccountSocialQueuePage);
