import styled from "styled-components";

export const CopyButton = styled.button`
  display: inline-block;
  background-color: #f0f0f0;
  color: #555555;
  border: none;
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 14px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;
  margin: 2px;

  &:hover {
    background-color: #d3d3d3;
    color: white;
  }

  .icon {
    margin-right: 8px;
  }
`;
