import React, { useState } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { toastr } from "react-redux-toastr";
import { required } from "../../utils/Validation";
import Select from "../common/form/Select";
import FormTitle from "../common/form/FormTitle";
import TextInput from "../common/form/TextInput";
import ImageInput from "../common/form/ImageInput";

const TopicForm = props => {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    allTopics,
    initialValues,
    onCancel,
    onSubmit,
    change
  } = props;

  const isEdit = !!initialValues;

  const [language, setLanguage] = useState(initialValues?.language || "en");
  const [parentId, setParentId] = useState(initialValues?.parentId);

  const parentTopics = [
    { value: null, label: "None" },
    ...allTopics
      .filter(parent => parent.language === (language || "en"))
      .map(topic => ({ value: topic.id, label: topic.label }))
  ];

  const languages = ["en", "nl"].map(lang => ({ value: lang, label: lang }));

  function validateTopic(values) {
    const { verified, image } = values;

    if (verified && !image) {
      const msg = "Cannot set the topic as verified without an image.";
      toastr.error(msg);
      throw new SubmissionError(msg);
    }

    delete values.createdAt;
    delete values.updatedAt;
    delete values.parent;

    onSubmit(values);
  }

  return (
    <form
      onSubmit={handleSubmit(validateTopic)}
      className="m-form m-form--fit m-form--label-align-right"
    >
      <div className="m-portlet__body">
        <FormTitle>{isEdit ? "Edit" : "Add"} Topic</FormTitle>
        <Field
          label="Parent"
          component={Select}
          showDefault={false}
          options={parentTopics}
          name="parentId"
          onChange={e => {
            setParentId(e.target.value);
          }}
        />

        <Field
          validate={required}
          label="Name"
          normalize={value => value?.toLowerCase()}
          component={TextInput}
          name="label"
        />

        <Field
          label="Color"
          normalize={value => value?.trim()?.toLowerCase()}
          component={TextInput}
          name="color"
        />

        <Field
          validate={required}
          label="Language"
          component={Select}
          showDefault={false}
          options={languages}
          onChange={e => {
            if (parentId) {
              change("parentId", null);
              alert("Parent topic cleared after changing the language");
            }

            setLanguage(e.target.value);
            setParentId(null);
          }}
          name="language"
        />

        <Field
          validate={required}
          label="Title"
          component={TextInput}
          name="title"
        />

        <Field
          validate={required}
          label="Description"
          component={TextInput}
          name="description"
        />

        <Field
          key="verified"
          label="Verified"
          component={TextInput}
          type={"checkbox"}
          name="verified"
        />

        <Field key="image" label="Image" component={ImageInput} name="image" />
      </div>
      <div className="m-portlet__foot m-portlet__foot--fit">
        <div className="m-form__actions">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-7">
              <button
                type="submit"
                className="btn btn-accent m-btn m-btn--air m-btn--custom"
                disabled={submitting}
              >
                {isEdit ? "Edit" : "Add"}
              </button>
              &nbsp;&nbsp;
              <button
                type="reset"
                className="btn btn-secondary m-btn m-btn--air m-btn--custom"
                disabled={!isEdit && (pristine || submitting)}
                onClick={onCancel || reset}
              >
                {isEdit ? "Close" : "Cancel"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export default reduxForm({
  // a unique name for the form
  form: "topicForm",
  enableReinitialize: true
})(TopicForm);
