import styled from "styled-components";

export const RecordContainer = styled.div`
  align-items: center;
  border-bottom-color: rgb(235, 237, 242);
  border-bottom-style: dashed;
  border-bottom-width: 0.90625px;
  display: flex;
  justify-content: start;
  margin-bottom: 18.59px;
  padding-bottom: 20.41px;
  width: 100%;
`;

export const ThumbnailContainer = styled.div`
  padding-right: 18.59px;
  width: 130.375px;
`;

export const Thumbnail = styled.img`
  border-radius: 4px;
  height: 74.5312px;
  width: 111.797px;
`;

export const DetailsContainer = styled.div`
  width: 100%;
`;

export const DetailsTitle = styled.a`
  color: rgb(108, 114, 147);
  text-decoration-color: rgb(108, 114, 147);
  outline-color: rgb(108, 114, 147);
  font-size: 14.3px;
  font-weight: 500;
  text-align: left;
`;

export const DetailsDescription = styled.p`
  padding-bottom: 5.2px;
  padding-top: 5.2px;
  text-align: left;
`;

export const DetailsExtraInfo = styled.span`
  color: rgb(39, 134, 251);
  font-weight: 500;
  margin-right: 9.23px;
  text-align: left;
`;

export const Tag = styled.span`
  align-items: center;
  background-color: #34bfa3;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  display: inline-flex;
  font-size: 10.4px;
  font-weight: 300;
  height: 19.5px;
  justify-content: center;
  line-height: 15.6px;
  margin-right: 5px;
  margin-top: 5px;
  padding: 9.75px 9.75px 9.75px 9.75px;

  :hover {
    background-color: #5867dd;
    cursor: pointer;
  }
`;

export const FilterToolbar = styled.div`
  margin-bottom: 20px;
`;

export const Filter = styled.span`
  align-items: center;
  background-color: #ffb822;
  border-radius: 4px;
  color: #111111;
  display: inline-flex;
  font-size: 10.4px;
  font-weight: 300;
  height: 19.5px;
  justify-content: center;
  line-height: 15.6px;
  margin: 5px 5px 5px 5px;
  padding: 9.75px 9.75px 9.75px 9.75px;
  :hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
`;

export const PaginationToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const FacetItemContainer = styled.div`
  :hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
`;

export const FacetContainer = styled.div`
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const DatePickerContainer = styled.div`
  width: 100%;
  padding: 5px 5px 5px 5px;
`;

export const TextFilterInput = styled.input`
  width: 100%;
  padding: 10px 10px 10px 10px;
  line-height: 20px;
  margin-bottom: 20px;
  font-size: 20px;
`;

export const SuggestButtonContainer = styled.div`
  display: flex;
  flex: 0.2;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
`;

export const TagTable = styled.table`
  margin-top: 10px;
  width: 90%;
  border: 1px solid #f2f2f2;
`;

export const TagTableHeader = styled.th`
  width: 25%;
  text-align: center;
  vertical-align: middle !important;
  border: 1px solid #f2f2f2;
`;

export const TagTableCell = styled.th`
  width: 25%;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #f2f2f2;
`;

export const AddTagButton = styled(Tag)`
  background-color: #5867dd;
  :hover {
    background-color: #f2f2f2;
    color: black;
    cursor: pointer;
  }
`;

export const RemovableTag = styled(Tag)`
  :hover {
    background-color: #ffcccb;
    color: black;
    cursor: pointer;
  }
`;

export const TagListItem = styled(Tag)`
  font-size: 14px;
  font-weight: 400;
`;

export const TagListModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const ArticleContentWrapper = styled.div`
  figure {
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
    img {
      max-width: 300px;
    }
  }
`;

export const KeywordToolbar = styled.div`
  margin-bottom: 20px;
`;

export const Keyword = styled.div`
  align-items: center;
  border-radius: 4px;
  color: #ffffff;
  background-color: #0bb783;
  display: inline-flex;
  font-size: 10.4px;
  font-weight: 300;
  height: 19.5px;
  justify-content: center;
  line-height: 15.6px;
  margin: 5px 5px 5px 5px;
  padding: 9.75px 9.75px 9.75px 9.75px;
  :hover {
    background-color: #04aa77;
    cursor: pointer;
  }
`;
