import React from "react";

import { connect } from "react-redux";
import { callApi } from "../../../utils/ContentoApi";
import { toastr } from "react-redux-toastr";
import DismissedList from "../../../components/recommendations/DismissedList";

class AccountSocialDismissedPage extends React.Component {
  state = { dismissedContent: [] };

  componentDidMount() {
    this.refreshDismissedContent();
  }

  refreshDismissedContent() {
    callApi({
      method: "get",
      url: `/accounts/${this.props.account.id}/contents/dismissed`
    })
      .then(result => {
        this.setState({ dismissedContent: result });
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({ dismissedContent: [] });
      });
  }

  render() {
    const { dismissedContent } = this.state;

    if (!dismissedContent) {
      return null;
    }

    return (
      <div className="m-portlet__body">
        <DismissedList
          accountId={this.props.account.id}
          results={dismissedContent}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    account: state.accounts.selectedAccount
  };
};

export default connect(mapStateToProps, {})(AccountSocialDismissedPage);
