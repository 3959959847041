import React from "react";
import styled from "styled-components";

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-bottom: 18px;
  padding: 18px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-left: -90px;
  margin-right: 20px;

  @media screen and (max-width: 1024px) {
    margin-left: 0;
  }
`;

export const PaddingDeleter = styled.div`
  margin-top: -70px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  }
`;

export const SourcesTitle = styled.h1`
  margin: 0;
  padding: 0;
  margin-left: 18px;
  font-size: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
