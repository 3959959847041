export const FETCH_POSTS = "@@post/FETCH_POSTS";
export const FETCH_POST = "@@post/FETCH_POST";
export const POSTS_FETCHED = "@@post/POSTS_FETCHED";
export const POST_FETCHED = "@@post/POST_FETCHED";

export const CREATE_POST = "@@post/CREATE_POST";
export const CREATE_POST_ERROR = "@@post/CREATE_POST_ERROR";
export const POST_CREATED = "@@post/POST_CREATED";

export const UPDATE_POST = "@@post/UPDATE_POST";
export const UPDATE_POST_ERROR = "@@post/UPDATE_POST_ERROR";
export const POST_UPDATED = "@@post/POST_UPDATED";
export const DELETE_POST = "@@post/DELETE_POST";

export const fetchPosts = ({ list, channels }) => {
  return {
    type: FETCH_POSTS,
    list,
    channels: channels
  };
};

export const fetchPost = postId => {
  return {
    type: FETCH_POST,
    postId
  };
};

export const postsFetched = (posts, list) => {
  return {
    type: POSTS_FETCHED,
    posts,
    list
  };
};

export const postFetched = post => {
  return {
    type: POST_FETCHED,
    post
  };
};

export const createPost = values => {
  return {
    type: CREATE_POST,
    values
  };
};

export const createPostError = error => {
  return {
    type: CREATE_POST_ERROR,
    error
  };
};

export const postCreated = post => {
  return {
    type: POST_CREATED,
    post
  };
};

export const updatePost = (postId, values) => {
  return {
    type: UPDATE_POST,
    postId,
    values
  };
};

export const updatePostError = error => {
  return {
    type: UPDATE_POST_ERROR,
    error
  };
};

export const postUpdated = post => {
  return {
    type: POST_UPDATED,
    post
  };
};

export const deletePost = postId => {
  return {
    type: DELETE_POST,
    postId
  };
};
