import React from "react";
import styled from "styled-components";
import { theme } from "../../theme";

interface ButtonProps extends React.CSSProperties {
  textColor?: string;
  onClick?: () => void;
}

const Button = styled.button<ButtonProps>`
  font-weight: 600;
  line-height: ${props => props.lineHeight ?? "17.5px"};
  font-size: ${props => props.fontSize ?? "14px"};

  cursor: pointer;
  background: ${props => props.background ?? theme.colors.primaryNew};
  padding: ${props => props.padding ?? "7px 14px"};
  color: ${props => props.textColor ?? "white"};
  border-radius: ${props => `${props.borderRadius ?? 24}px`};
  border-style: none;
  margin: ${props => props.margin ?? "0 4px"};
  width: ${props => props.width ?? "fit-content"};

  &:disabled {
    background-color: ${props => props.theme.colors.grey02};
    color: ${props => props.theme.colors.text04};
  }

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.m}) {
    font-size: 90%;
  }
`;

interface TextButtonProps extends ButtonProps {
  text: string;
}

export const TextButton = ({ text, onClick }: TextButtonProps) => (
  <Button
    background={"transparent"}
    textColor="#747474"
    padding={"0"}
    margin={"0 20px"}
    onClick={onClick}
  >
    {text}
  </Button>
);

export default Button;
