import React, { useRef } from "react";
import {
  Control,
  FieldValues,
  Path,
  useController,
  UseControllerProps
} from "react-hook-form";
import { Flex, Text } from "rebass/styled-components";
import Select, {
  MenuProps,
  OptionProps,
  SingleValueProps,
  StylesConfig,
  components,
  SelectInstance
} from "react-select";

import { MenuContainer, MenuEntry } from "components/common/Menu";
import {
  StyledInputFieldset,
  StyledInputLabel,
  ErrorMessage,
  HelperText
} from "components/common/form/styles";
import { theme } from "theme";
import { CalendarStatus } from "containers/calendars/CalendarStatusFilter";

export interface Option {
  label: string;
  value: CalendarStatus;
  description: string;
}

const options: Option[] = [
  {
    label: "Inactive",
    value: CalendarStatus.inactive,
    description: "This will not show in the product"
  },
  {
    label: "Voting",
    value: CalendarStatus.voting,
    description: "This will show in the product but can’t be used."
  },
  {
    label: "Live",
    value: CalendarStatus.live,
    description: "This will show in the product and can be used."
  }
];

const Option = ({ children, ...props }: OptionProps<Option, false>) => {
  const option = props.data;

  return (
    <MenuEntry
      key={option.value}
      selected={props.isSelected}
      onClick={() => {
        props.selectOption(option);
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          gap: "4px"
        }}
      >
        <Text
          sx={{
            fontSize: "13px",
            fontWeight: 500,
            lineHeight: "17.5px",
            textTransform: "capitalize"
          }}
        >
          {option.value}
        </Text>
        <Text
          sx={{
            fontSize: "11px",
            fontWeight: 400,
            lineHeight: "15.6px",
            color: "#808082"
          }}
          className="subtitle"
        >
          {option.description}
        </Text>
      </Flex>
    </MenuEntry>
  );
};

const SingleValue = ({
  children,
  ...props
}: SingleValueProps<Option, false>) => {
  const value = props.getValue()[0];
  const displayText = value ? `${value.value}` : undefined;
  return (
    <components.SingleValue {...props}>
      {value && (
        <Text fontSize={"14px"} sx={{ textTransform: "capitalize" }}>
          {displayText}
        </Text>
      )}
    </components.SingleValue>
  );
};

const Menu = ({ children, ...props }: MenuProps<Option, false>) => {
  return (
    <components.Menu {...props}>
      <MenuContainer width={320}>{children}</MenuContainer>;
    </components.Menu>
  );
};

interface Props<T extends FieldValues>
  extends UseControllerProps<T>,
    React.CSSProperties {
  label: string;
  control?: Control<T>;
  name: Path<T>;
  required?: boolean;
  helperText?: string;
}

const CalendarStatusSelector = <T extends FieldValues>(inputs: Props<T>) => {
  let {
    fieldState,
    field: { value, onChange, ...formField }
  } = useController(inputs);

  const selectRef = useRef<SelectInstance<Option>>(null);
  const { label, required, helperText } = inputs;

  const hasError = fieldState.error !== undefined;

  const styles: StylesConfig<Option, false> = {
    container: provided => ({
      ...provided,
      marginTop: "8px",
      width: "100%"
    }),
    control: provided => ({
      ...provided,
      borderRadius: "8px",
      borderColor: hasError ? theme.colors.danger : theme.colors.grey02
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "11px 16px"
    }),
    menu: () => ({}),
    input: provided => ({
      ...provided,
      padding: "0",
      margin: "0",
      fontSize: "14px"
    }),
    placeholder: provided => ({
      ...provided,
      color: theme.colors.text04
    }),
    indicatorSeparator: () => ({}), // removes
    dropdownIndicator: provided => ({
      ...provided,
      display: "none"
    })
  };

  return (
    <StyledInputFieldset>
      <StyledInputLabel>
        {`${label} `}{" "}
        {required && <span style={{ color: "#FF0E0E" }}>{` * `}</span>}
      </StyledInputLabel>
      {helperText && <HelperText>{helperText}</HelperText>}
      <Flex>
        <Select
          styles={styles}
          menuPlacement="auto"
          menuPosition="fixed"
          options={options}
          components={{ Menu, SingleValue, Option }}
          {...formField}
          isClearable={true}
          value={options.find(op => op.value === value)}
          onChange={option => {
            onChange(option?.value);
          }}
          ref={selectRef}
        />
      </Flex>
      {hasError && <ErrorMessage>{fieldState.error?.message}</ErrorMessage>}
    </StyledInputFieldset>
  );
};

export default CalendarStatusSelector;
