import React from "react";
import { FormStages } from "./CalendarEditorModal";
import ModalFooter, { IStages } from "../common/ModalFooter";

type Props = {
  currentStage: FormStages;
  setCurrentStage: (newStage: FormStages) => void;
  onSubmit?: () => void;
  actionButtonText: string;
  disableActionButton?: boolean;
  showBackButton?: boolean;
};

const CalendarEditorFooter = ({
  currentStage,
  setCurrentStage,
  onSubmit,
  actionButtonText,
  disableActionButton,
  showBackButton = true
}: Props) => {
  const stageTiles: IStages<FormStages>[] = [
    {
      title: "Calendar Details",
      stageNumber: 1,
      active: currentStage === FormStages.CALENDAR_DETAILS,
      done: currentStage !== FormStages.CALENDAR_DETAILS,
      value: FormStages.CALENDAR_DETAILS
    },
    {
      title: "Calendar Events",
      stageNumber: 2,
      active: currentStage === FormStages.CALENDAR_EVENTS,
      done: currentStage === FormStages.REVIEW,
      value: FormStages.CALENDAR_EVENTS
    }
  ];

  const onBackPressed = () => {
    switch (currentStage) {
      case FormStages.CALENDAR_DETAILS:
        console.log("Do Nothing ");
        break;
      case FormStages.CALENDAR_EVENTS:
        console.log("Setting Stage To:: ", FormStages.CALENDAR_DETAILS);
        setCurrentStage(FormStages.CALENDAR_DETAILS);
        break;
      case FormStages.REVIEW:
        console.log("Setting Stage To:: ", FormStages.CALENDAR_EVENTS);
        setCurrentStage(FormStages.CALENDAR_EVENTS);
        break;
    }
  };

  return (
    <ModalFooter
      currentStage={currentStage}
      onSubmit={onSubmit}
      actionButtonText={actionButtonText}
      disableActionButton={disableActionButton}
      showBackButton={showBackButton}
      stageTiles={stageTiles}
      onBackPressed={onBackPressed}
    />
  );
};

export default CalendarEditorFooter;
