import React, { Component } from "react";

import { connect } from "react-redux";
import * as accountActions from "../../state/actions/AccountActions";
import Portlet from "../../components/common/portlet/Portlet";
import UserForm from "../../components/users/UserForm";
import * as PropTypes from "prop-types";
import { callApi } from "../../utils/ContentoApi";
import { toastr } from "react-redux-toastr";
import { generatePassword } from "../../utils/Password";

class NewUserPage extends Component {
  createUser = values => {
    callApi({
      method: "post",
      url: `/users`,
      data: {
        ...values,
        accounts: values.accounts
          ? values.accounts.map(account => account.id)
          : null
      }
    })
      .then(user => {
        toastr.success("User created successfully");
        //forward to detail page
      })
      .catch(error => {
        toastr.error(error.message);
      });
  };

  render() {
    return (
      <Portlet title="New User">
        <UserForm
          onSubmit={values => this.createUser(values)}
          initialValues={{ password: generatePassword(6, 4, 2), roles: [] }}
        />
      </Portlet>
    );
  }
}

NewUserPage.propTypes = { updateAccount: PropTypes.any };

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps, {
  updateAccount: accountActions.updateAccount
})(NewUserPage);
