import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import Portlet from "../../components/common/portlet/Portlet";
import SourceForm from "../../components/sources/SourceForm";
import { callApi } from "../../utils/ContentoApi";

function SourceAddPage() {
  const history = useHistory();

  function backtoList() {
    history.push("/sources");
  }

  async function submit(source, isUrlFilterRegExp) {
    try {
      await callApi({
        method: "post",
        url: `/sources?isUrlFilterRegExp=${isUrlFilterRegExp}`,
        data: source
      });

      toastr.success("Success!", "The source has been created!");
      backtoList();
    } catch (error) {
      console.error(error);
      toastr.error(error.message);
    }
  }

  function onSubmit(values) {
    let { isUrlFilterRegExp, ...source } = values;
    isUrlFilterRegExp = (isUrlFilterRegExp && isUrlFilterRegExp[0]) === "true";

    if (source.handle) {
      // make sure we dont have the twitter domain on the handle
      source.handle = source.handle
        .toLowerCase()
        .replace("https://twitter.com/", "");
    }

    return submit(
      {
        ...source,
        topic: undefined,
        topics: source.topic ? [source.topic] : []
      },
      isUrlFilterRegExp
    );
  }

  return (
    <Portlet title="Add new source">
      <SourceForm
        onSubmit={onSubmit}
        onCancel={backtoList}
        initialValues={{}}
      />
    </Portlet>
  );
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps, {})(SourceAddPage);
