import React from "react";
import moment from "moment";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Portlet from "../../components/common/portlet/Portlet";
import * as accountActions from "../../state/actions/AccountActions";
import { pick, values } from "lodash";
import { callApi } from "../../utils/ContentoApi";
import { toastr } from "react-redux-toastr";
import Badge from "../../components/common/Badge";
import CountBadge from "../../components/common/CountBadge";
import FallbackImage from "../../components/common/FallbackImage";
import {
  Notification,
  Notifications,
  NotificationText,
  NotificationTime
} from "../../components/common/styles";
import autoPilotIcon from "../../assets/images/pilot-sunglasses.png";
import Auth from "../../utils/Auth";

import AccountsInNeedOfContent from "../../components/dashboard/AccountsInNeedOfContent";
import PendingPosts from "../../components/dashboard/PendingPosts";
import InactiveAccounts from "../../components/dashboard/InactiveAccounts";
import InactiveSinceOnboarding from "../../components/dashboard/InactiveSinceOnboarding";
import OnboardingAccounts from "../../components/dashboard/OnboardingAccounts";

const ACCOUNTS_PER_PAGE = 50;

class MainDashboardPage extends React.Component {
  state = {
    accounts: null,
    notifications: null,
    currentPage: 0,
    maxPage: 100
  };

  componentDidMount() {
    this.fetchDashboardAccounts();
    this.fetchDashboardNotifications();
  }

  fetchDashboardAccounts = () => {
    callApi({
      method: "get",
      url: `/accounts`,
      params: {
        limit: ACCOUNTS_PER_PAGE,
        offset: this.state.currentPage * ACCOUNTS_PER_PAGE
      }
    })
      .then(result => {
        this.setState({
          accounts: result.rows,
          maxPage:
            result.rows.length < ACCOUNTS_PER_PAGE
              ? this.state.currentPage
              : 100
        });
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({ accounts: null });
      });
  };

  fetchDashboardNotifications = () => {
    callApi({
      method: "get",
      url: `/users/${this.props.currentUser.id}/notifications`
    })
      .then(notifications => {
        this.setState({ notifications: notifications });
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({ notifications: null });
      });
  };

  handleDashboardClick = accountId => {
    return Auth.impersonateCustomer(accountId, this.props.currentUser.id);
  };

  changePage = page => {
    page = Math.min(Math.max(0, page), this.state.maxPage);
    this.setState({ currentPage: page }, this.fetchDashboardAccounts);
  };

  render() {
    const { accounts, notifications } = this.state;

    return (
      <div className="m-content">
        <div className="row">
          <div className="col-xl-7">
            <AccountsInNeedOfContent />
            <PendingPosts currentUser={this.props.currentUser} />
            <InactiveAccounts currentUser={this.props.currentUser} />
            <InactiveSinceOnboarding currentUser={this.props.currentUser} />
            <OnboardingAccounts currentUser={this.props.currentUser} />
            {accounts && (
              <Portlet title="My Accounts">
                <table
                  style={{
                    width: "100%",
                    borderSpacing: "0 10px",
                    borderCollapse: "separate"
                  }}
                >
                  <tbody>
                    {accounts.map(account => (
                      <tr key={account.id}>
                        <td
                          style={{
                            width: "70px",
                            height: "75px",
                            borderBottom: "1px solid #eee"
                          }}
                        >
                          <FallbackImage
                            src={account.logo}
                            alt={account.companyName}
                            height={50}
                            width={50}
                            style={{ maxWidth: "none" }}
                          />
                        </td>
                        <td style={{ borderBottom: "1px solid #eee" }}>
                          <span className="m-widget4__title">
                            {account.companyName}
                          </span>
                          <br />
                          <span className="m-widget4__sub">
                            {account.country}
                          </span>
                        </td>
                        <td style={{ borderBottom: "1px solid #eee" }}>
                          {account.status}
                        </td>
                        <td style={{ borderBottom: "1px solid #eee" }}>
                          {account.autoPilot && (
                            <img
                              src={autoPilotIcon}
                              alt={"Autopilot on"}
                              width={32}
                              height={32}
                            />
                          )}
                        </td>
                        <td
                          style={{
                            width: "10%",
                            textAlign: "center",
                            borderBottom: "1px solid #eee"
                          }}
                        >
                          <CountBadge count={account.suggestedCount} />
                        </td>
                        <td
                          style={{
                            width: "15%",
                            borderBottom: "1px solid #eee"
                          }}
                        >
                          {account.channels.map(channel => {
                            return <Badge key={channel.id} channel={channel} />;
                          })}
                        </td>
                        <td
                          style={{
                            width: "15%",
                            borderBottom: "1px solid #eee"
                          }}
                        >
                          <Link
                            to={`/accounts/${account.id}/social`}
                            className="m-btn m-btn--pill m-btn--hover-brand btn btn-sm btn-secondary"
                          >
                            Manage
                          </Link>
                          <Link
                            to={""}
                            className="m-btn m-btn--pill m-btn--hover-brand btn btn-sm btn-secondary"
                            style={{ marginTop: "5px" }}
                            onClick={() =>
                              this.handleDashboardClick(account.id)
                            }
                          >
                            Willow Home
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <button
                    className="m-btn m-btn--pill m-btn--hover-brand btn btn-sm btn-secondary"
                    disabled={this.state.currentPage === 0}
                    onClick={() => this.changePage(this.state.currentPage - 1)}
                  >
                    &lt; Prev
                  </button>
                  <button
                    className="m-btn m-btn--pill m-btn--hover-brand btn btn-sm btn-secondary"
                    disabled={this.state.currentPage >= this.state.maxPage}
                    onClick={() => this.changePage(this.state.currentPage + 1)}
                  >
                    Next &gt;
                  </button>
                </div>
              </Portlet>
            )}
          </div>
          <div className="col-xl-5">
            {notifications && (
              <Portlet title="Notifications">
                <Notifications>
                  {notifications.map(notification => (
                    <Notification key={notification.id}>
                      <NotificationText
                        dangerouslySetInnerHTML={{ __html: notification.text }}
                      />
                      <NotificationTime>
                        {moment(notification.createdAt).fromNow()}
                      </NotificationTime>
                    </Notification>
                  ))}
                </Notifications>
              </Portlet>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    accounts: values(pick(state.accounts.data, state.accounts.all)),
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, {
  fetchAccounts: accountActions.fetchAccounts
})(MainDashboardPage);
