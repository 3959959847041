import React, { useEffect, useState } from "react";
import { useContentoApi } from "../../utils/useContentoApi";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import Portlet from "../../components/common/portlet/Portlet";

const AccountAchievementsPage = ({ account }) => {
  const [achievements, setAchievements] = useState([]);

  const [fetchAchievements] = useContentoApi(
    `/accounts/${account.id}/achievements`
  );

  useEffect(() => {
    fetchAchievements()
      .then(setAchievements)
      .catch(error => {
        toastr.error(error.message);
      });
  }, [fetchAchievements]);

  return (
    <Portlet title="Achievements">
      <div className="m-section">
        <div className="m-section__content">
          {!achievements.length &&
            "There are no achievements for the last 7 days."}
        </div>
      </div>
      <div className="m-widget4">
        {achievements.map(achievement => (
          <div key={achievement.type} className="m-widget4__item">
            <div className="m-widget4__img">
              <img src={achievement.image} width={100} alt="" />
            </div>
            <div className="m-widget4__title">{achievement.description}</div>
          </div>
        ))}
      </div>
    </Portlet>
  );
};

const mapStateToProps = (state, props) => {
  return {
    account: state.accounts.selectedAccount
  };
};

export default connect(mapStateToProps, {})(AccountAchievementsPage);
