import styled from "styled-components";
import { Box } from "rebass";

export const SocialWrapper = styled.div`
  position: relative;
  padding: 10px;
  height: auto;
`;

export const DeleteButton = styled(Box)`
  cursor: pointer;
  position: absolute;
  width: 15px;
  height: 15px;
  top: 5px;
  right: 5px;
  background-color: white;
  border-radius: 50%;
  line-height: 15px;
  font-weight: bold;
  text-align: center;
`;
