import React, { useState, useRef } from "react";

import Button from "components/common/Button";
import { connect } from "react-redux";
import { openModal } from "state/actions/ModalActions";
import { Flex, Text } from "rebass/styled-components";
import Icon from "components/common/Icon";
import { ChevronDown, ChevronUp } from "styled-icons/fa-solid";
import Popper from "components/common/Popper";
import { MenuContainer, MenuEntry } from "components/common/Menu";
import { MODALS } from "components/modals/ModalRoot";

const CreateAccountButton = ({ openModal }: { openModal: any }) => {
  const [showOptions, setShowOptions] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div ref={ref}>
      <Button onClick={handleClick} data-tip={"New Account"}>
        <Flex style={{ gap: "8px", alignItems: "center" }}>
          <span>Create Account</span>
          <Icon component={showOptions ? ChevronUp : ChevronDown} size={12} />
        </Flex>
      </Button>
      <Popper
        placement="bottom-end"
        referenceElement={ref.current}
        visible={showOptions}
        onClose={() => setShowOptions(false)}
        exceptions={[ref.current]}
      >
        <MenuContainer margin={"8px"}>
          <CreateAccountMenuEntry
            title="Create for New User"
            subtitle="For Sales Prospects"
            onClick={() => {
              setShowOptions(false);
              openModal(MODALS.ACCOUNT_CREATION_MODAL);
            }}
          />
          <CreateAccountMenuEntry
            title="Create for Existing User"
            subtitle="For Customer Success"
            onClick={() => {
              openModal(MODALS.ACCOUNT_CREATION_MODAL, {
                forExistingUser: true
              });
              setShowOptions(false);
            }}
          />
        </MenuContainer>
      </Popper>
    </div>
  );
};

type CreateAccountMenuEntryProps = {
  title: string;
  subtitle: string;
  onClick: () => void;
};

const CreateAccountMenuEntry = ({
  title,
  subtitle,
  onClick
}: CreateAccountMenuEntryProps) => {
  return (
    <MenuEntry justifyContent="end">
      <Flex
        sx={{
          flexDirection: "column",
          gap: "4px",
          textAlign: "end"
        }}
        onClick={onClick}
      >
        <Text
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "125%"
          }}
        >
          {title}
        </Text>
        <Text
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "130%",
            color: "#808082"
          }}
          className="subtitle"
        >
          {subtitle}
        </Text>
      </Flex>
    </MenuEntry>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

export default connect(mapStateToProps, {
  openModal: openModal
})(CreateAccountButton);
