import React, { useState, useRef } from "react";
import useOutsideAlerter from "../../utils/useOutsideAlerter";

import {
  useController,
  UseControllerProps,
  FieldValues
} from "react-hook-form";

import Popper from "./Popper";
import { ErrorMessage, StyledInputFieldset } from "./form/styles";

interface DropdownProps<T extends FieldValues>
  extends UseControllerProps<T>,
    React.CSSProperties {
  required?: boolean;

  menuBuilder: ({
    selected,
    onOptionSelected
  }: {
    selected?: T;
    onOptionSelected: (newSelection: T) => void;
  }) => JSX.Element;
  dropdownContainerBuilder: ({
    selected,
    onClick
  }: {
    selected?: T;
    onClick: () => void;
    hasError: boolean;
  }) => JSX.Element;
}

const Dropdown = <T extends FieldValues>(inputs: DropdownProps<T>) => {
  const { field, fieldState } = useController(inputs);

  const hasError = fieldState.error !== undefined;

  const { dropdownContainerBuilder, menuBuilder } = inputs;
  const outsideWrapper = useRef(null);

  const ref = useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = useState(false);

  const toggleShowOptions = () => setShowOptions(!showOptions);

  const handleOnOptionSelected = (value: T) => {
    setShowOptions(false);
    field.onChange(value);
  };

  const handleDismiss = () => setShowOptions(false);
  useOutsideAlerter(outsideWrapper, handleDismiss);

  return (
    <StyledInputFieldset ref={ref}>
      {dropdownContainerBuilder({
        selected: field.value,
        hasError,
        onClick: () => {
          console.log("View Popper Clicked!!");
          toggleShowOptions();
        }
      })}
      {hasError && <ErrorMessage>{fieldState.error?.message}</ErrorMessage>}
      <Popper
        placement="bottom-start"
        referenceElement={ref.current}
        visible={showOptions}
        onClose={() => setShowOptions(false)}
        exceptions={[ref.current]}
      >
        {menuBuilder({
          selected: field.value,
          onOptionSelected: handleOnOptionSelected
        })}
      </Popper>
    </StyledInputFieldset>
  );
};

export default Dropdown;
