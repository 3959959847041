import ReactDOM from "react-dom";
import React, { useState } from "react";
import { usePopper } from "react-popper";
import OutsideClickDetector from "components/common/OutsideClickDetector";

type Placement =
  | "auto"
  | "auto-start"
  | "auto-end"
  | "top"
  | "top-start"
  | "top-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "right"
  | "right-start"
  | "right-end"
  | "left"
  | "left-start"
  | "left-end";

type Strategy = "absolute" | "fixed";

type PopperProps = {
  referenceElement: HTMLElement | null;
  placement?: Placement;
  offset?: any;
  visible?: boolean;
  children?: JSX.Element | JSX.Element[];
  strategy?: Strategy;
  onClose?: () => void;
  exceptions?: any[];
  zIndex?: number;
  usePortal?: boolean;
  arrowBg?: any;
};

const Popper = ({
  referenceElement,
  placement,
  offset = undefined,
  visible,
  children,
  strategy = "absolute",
  onClose = () => {},
  exceptions = [],
  zIndex = 11,
  usePortal = false,
  arrowBg = "transparent"
}: PopperProps) => {
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const modifiers: any[] = [
    { name: "arrow", options: { element: arrowElement } }
  ];

  if (offset) {
    modifiers.push({
      name: "offset",
      options: {
        offset
      }
    });
  }

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement || "bottom-start",
    strategy,
    modifiers
  });

  if (!visible) return null;

  const PopperComponent = (
    <div
      ref={setPopperElement as any}
      {...attributes.popper}
      style={{
        ...styles.popper,
        zIndex
      }}
      className="popper-element"
    >
      <OutsideClickDetector onClose={onClose} exceptions={exceptions}>
        {children}
      </OutsideClickDetector>
      {arrowBg && (
        <div
          ref={setArrowElement as any}
          style={{
            ...styles.arrow,
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.25)",
            background: arrowBg
          }}
          className="popper-arrow"
        />
      )}
    </div>
  );

  if (usePortal) {
    return ReactDOM.createPortal(
      PopperComponent,
      document.querySelector("#popper")!
    );
  } else {
    return PopperComponent;
  }
};

export default Popper;
