import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

export default function datePicker({ selected, placeholder, onChange }) {
  return (
    <DatePicker
      todayButton="Today"
      className="form-control"
      placeholderText={placeholder}
      highlightDates={[new Date()]}
      selected={selected ? new Date(selected) : ""}
      onChange={date => {
        if (moment(date).isValid()) {
          onChange(date);
        }
      }}
      dateFormat="dd/MM/yyyy"
    />
  );
}
