import { takeEvery, takeLatest } from "redux-saga/effects";

import * as authActions from "../actions/AuthActions";
import * as analyticsActions from "../actions/AnalyticsActions";

const trackEvent = (event, eventData) => {
  if (process.env.REACT_APP_ENV === "production") {
    window.analytics.track(event, {
      eventData
    });
  } else {
    console.log(`[Event Tracking] ${event}`, eventData);
  }
};

function trackAnalyticsEvent({ event, eventData }) {
  trackEvent(event, eventData);
}

function identifyUser(action) {
  if (process.env.REACT_APP_ENV === "production") {
    window.analytics.identify(action.userInfo.id, {
      email: action.userInfo.email,
      firstName: action.userInfo.firstName,
      lastName: action.userInfo.lastName,
      name: `${action.userInfo.fullName}`
    });
  }
}

export default function* root() {
  yield takeEvery(analyticsActions.TRACK_ANALYTICS_EVENT, trackAnalyticsEvent);
  yield takeLatest(authActions.USER_INFO_FETCHED, identifyUser);
}
