import React from "react";
import { connect } from "react-redux";
import TagListModal from "../content/vetted-search/TagListModal";
import ArticleContentModal from "../content/vetted-search/ArticleContentModal";
import ArticleDebugModal from "../content/vetted-search/ArticleDebugModal";
import SearchCheatSheetModal from "../content/vetted-search/SearchCheatSheetModal";
import EditTopicModal from "../topics/EditTopicModal";
import CreateAccountModal from "./CreateAccountModal/CreateAccountModal";
import CalendarEditorModal from "./CalendarEditorModal.tsx";

export const MODALS = {
  TAG_LIST_MODAL: "TAG_LIST_MODAL",
  ARTICLE_CONTENT_MODAL: "ARTICLE_CONTENT_MODAL",
  ARTICLE_DEBUG_MODAL: "ARTICLE_DEBUG_MODAL",
  SEARCH_CHEAT_SHEET_MODAL: "SEARCH_CHEAT_SHEET_MODAL",
  EDIT_TOPIC_MODAL: "EDIT_TOPIC_MODAL",
  ACCOUNT_CREATION_MODAL: "ACCOUNT_CREATION_MODAL",
  CALENDAR_EDITOR_MODAL: "CALENDAR_EDITOR_MODAL"
};

const MODAL_COMPONENTS = {
  [MODALS.TAG_LIST_MODAL]: TagListModal,
  [MODALS.ARTICLE_CONTENT_MODAL]: ArticleContentModal,
  [MODALS.ARTICLE_DEBUG_MODAL]: ArticleDebugModal,
  [MODALS.SEARCH_CHEAT_SHEET_MODAL]: SearchCheatSheetModal,
  [MODALS.EDIT_TOPIC_MODAL]: EditTopicModal,
  [MODALS.ACCOUNT_CREATION_MODAL]: CreateAccountModal,
  [MODALS.CALENDAR_EDITOR_MODAL]: CalendarEditorModal
};

/*
  Takes a modalType and modalProps to dynamically return the
  modal component we imported above
*/
const modalRoot = ({ modalType, modalProps }) => {
  if (!modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal {...modalProps} />;
};

const mapStateToProps = state => ({
  modalProps: state.modals.modalProps,
  modalType: state.modals.modalType
});

export default connect(mapStateToProps)(modalRoot);
