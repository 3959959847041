import styled from "styled-components";
import { Box, Flex, Image } from "rebass";

export const PostImage = styled(Image)`
  width: 40%;
  height: 115px;
  object-fit: cover;
`;

export const AttachmentContainer = styled(Flex)`
  border: 1px solid #eaebeb;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px 20px 15px 20px;
`;

export const AttachmentDetail = styled(Flex)`
  flex-direction: column;
  padding-left: 20px;
`;
export const AttachmentTitle = styled(Flex)`
  max-height: 40px;
  overflow: hidden;
  font-weight: bold;
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 8px;
  text-overflow: ellipsis;
`;
export const AttachmentDescription = styled(Flex)`
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  font-size: 13px;
  color: #8b93a6;
`;

export const AttachmentFooter = styled(Flex)`
  border-top: 1px solid #d0d6da;
  margin-top: 20px;
  padding-top: 10px;
`;

export const AttachmentButton = styled(Flex)`
  align-items: center;
  cursor: pointer;
  color: #8b93a6;
`;

export const DropZoneContainer = styled(Flex)`
  height: 150px;
  width: 100%;
  cursor: pointer;
  background-color: #ffffff;
  border: 2px dashed
    ${props =>
      props.isDragActive ? props.theme.colors.orange : props.theme.colors.blue};
  box-sizing: border-box;
  border-radius: 8px;
`;

export const DropZoneTitle = styled(Box)`
  font-weight: 600;
  line-height: normal;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.1px;
  color: ${props => props.theme.colors.black};
`;

export const DropZoneSubTitle = styled(Box)`
  font-weight: 600;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.1px;

  color: #8b93a6;
`;

export const MoreMenu = styled(Flex)`
  position: absolute;
  top: 50px;
  left: 165px;
  padding: 10px;
  width: 250px;
  background: #ffffff;
  border: 1px solid #eaebeb;
  border-radius: 8px;
  display: ${props => (props.visible ? "block" : "none")};
`;

export const MoreMenuButton = styled(Flex)`
  cursor: pointer;
  :hover {
    background-color: #f2f2f2;
  }
`;
