import React from "react";
import ModalHeader from "../common/ModalHeader";

type CreateAccountModalHeaderProps = {
  forExistingUser: boolean;
  onClose: () => void;
};

const CreateAccountModalHeader = ({
  forExistingUser,
  onClose
}: CreateAccountModalHeaderProps) => {
  return (
    <ModalHeader
      onClose={onClose}
      title={
        forExistingUser
          ? "Create New Account for Existing User"
          : "Create New Account"
      }
    />
  );
};

export default CreateAccountModalHeader;
