import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 8px;
`;

export const TableRow = styled.tr`
  background-color: #f8f9fa;
  &:hover {
    background-color: #e8e8e8;
  }
`;

export const TableHeader = styled.th`
  padding: 8px;
  margin-bottom: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
  background-color: #e6efff;

  &:first-child {
    text-align: left;
  }
`;

export const TableCell = styled.td`
  padding: 16px;
  margin-top: 20px;
  text-align: center;

  &:first-child {
    text-align: left;
  }
`;
