export const colors = {
  blue: "#33ADFF",
  orange: "#FFB145",
  black: "#1B1D1F",
  gray: "#8B93A6",
  red: "#D0154A",
  white: "#ffffff",
  facebookBlue: "#3B5998",
  twitterBlue: "#0084b4",
  linkedInBlue: "#0077B5",
  instagramRed: "#e4405f",

  //new colors
  primaryNew: "#0063FB",
  primaryFocus: "#004FC9",
  primarySubtle: "rgba(230, 239, 255, 1)",
  primaryLight: "rgba(0, 99, 251, 0.16)",
  primaryHover: "rgba(0, 89, 226, 1)",
  primaryActive: "rgba(0, 79, 201, 1)",
  primaryTransparent: "rgba(0, 99, 251, 0)",
  grey01: "rgba(96, 116, 128, 0.08)",
  grey02: "#6074801F",
  grey03: "rgba(102, 119, 128, 0.16)",
  grey04: "rgba(102, 119, 128, 0.2)",
  grey05: "rgba(247, 249, 250, 1)",
  text01: "#1C1E1F",
  text02: "#646769",
  text03: "#808082",
  darkText01: "rgba(243, 244, 245, 0.95)",
  darkText03: "rgba(243, 244, 245, 0.62)",
  text04: "#3C3F4254",
  text05: "#ffffff",
  error: "#FF0E0E",
  danger: "#FF5050"
};

export const iconStyles = {
  FACEBOOK: {
    color: colors.facebookBlue,
    iconClass: "socicon-facebook"
  },
  INSTAGRAM: {
    color: colors.instagramRed,
    iconClass: "socicon-instagram"
  },
  LINKEDIN: {
    color: colors.linkedInBlue,
    iconClass: "socicon-linkedin"
  },
  TWITTER: {
    color: colors.twitterBlue,
    iconClass: "socicon-twitter"
  }
};

export const theme = {
  colors: {
    primary: colors.blue,
    text: colors.black,
    background: "#F2F2F2",
    ...colors
  },
  breakpoints: ["850px", "1100px", "64em"],
  stdBreakpoints: {
    xs: "479px",
    sm: "767px",
    m: "990px",
    lg: "1200px",
    xl: "1440px"
  },
  font: "Nunito Sans, sans-serif",
  textStyles: {
    h5: {
      lineHeight: "24px",
      fontWeight: 700,
      fontSize: "16px",
      letterSpacing: "-0.02em"
    },
    h6: {
      fontWeight: 700,
      fontSize: "14px",
      letterSpacing: "-0.02em",
      lineHeight: "21px"
    }
  },
  variants: {
    primary: {
      fontColor: colors.gray,
      color: colors.text05,
      bg: colors.primaryNew,
      hover: {
        bg: colors.primaryHover
      },
      active: {
        bg: colors.primaryActive
      }
    },
    primaryLite: {
      color: colors.text01,
      borderColor: colors.text01,
      bg: colors.white,
      hover: {
        bg: colors.black,
        color: colors.white,
        borderColor: colors.text01
      },
      active: {
        bg: colors.grey03
      }
    },
    primaryWhite: {
      color: colors.text01,
      borderColor: colors.white,
      bg: colors.white,
      hover: {
        bg: colors.white,
        color: colors.white,
        borderColor: colors.text01
      },
      active: {
        bg: colors.white
      }
    },
    secondary: {
      color: colors.text01,
      bg: colors.grey01,
      hover: {
        bg: colors.grey02
      },
      active: {
        bg: colors.grey03
      }
    },
    secondaryQuietAlt: {
      color: colors.text02,
      bg: "transparent",
      hover: {
        bg: colors.grey01,
        color: colors.text01
      },
      active: {
        bg: colors.grey03,
        color: colors.text01
      }
    },
    secondaryQuiet: {
      color: colors.text01,
      bg: "transparent",
      hover: {
        bg: colors.grey02
      },
      active: {
        bg: colors.grey03
      }
    }
  }
};

export const SIZES = {
  xsmall: { min: 0, max: 767 },
  small: { min: 768, max: 991 },
  medium: { min: 992, max: 1200 },
  large: { min: 1200, max: Infinity }
};
