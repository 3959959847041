import React from "react";

import { connect } from "react-redux";

import { Switch, Route, Redirect } from "react-router";
import { NavLink } from "react-router-dom";
import AccountSocialQueuePage from "./AccountSocialQueuePage";
import AccountSocialSentPage from "./AccountSocialSentPage";
import * as postActions from "../../../state/actions/PostActions";
import * as modalActions from "../../../state/actions/ModalActions";
import AccountSocialSuggestedPage from "./AccountSocialSuggestedPage";
import AccountSocialDismissedPage from "./AccountSocialDismissedPage";
import AccountSocialRecommendedPageV2 from "./AccountSocialRecommendedPageV2";

class AccountSocialPage extends React.Component {
  render() {
    const { match, account, location } = this.props;

    return (
      <div className="m-portlet m-portlet--full-height m-portlet--tabs  ">
        <div className="m-portlet__head">
          <div className="m-portlet__head-tools">
            <ul
              className="nav nav-tabs m-tabs m-tabs-line   m-tabs-line--left m-tabs-line--primary"
              role="tablist"
            >
              <li className="nav-item m-tabs__item">
                <NavLink
                  to={`/accounts/${account.id}/social/recommended-v2`}
                  className={`nav-link m-tabs__link ${
                    location.pathname.endsWith("recommended-v2") ? "active" : ""
                  }`}
                >
                  <i className="flaticon-share m--hide" />
                  Automated Suggestions
                </NavLink>
              </li>
              <li className="nav-item m-tabs__item">
                <NavLink
                  to={`/accounts/${account.id}/social/suggested`}
                  className={`nav-link m-tabs__link ${
                    location.pathname.indexOf("suggested") !== -1
                      ? "active"
                      : ""
                  }`}
                >
                  <i className="flaticon-share m--hide" />
                  Suggested
                </NavLink>
              </li>
              <li className="nav-item m-tabs__item">
                <NavLink
                  to={`/accounts/${account.id}/social/queue`}
                  className={`nav-link m-tabs__link ${
                    location.pathname.indexOf("queue") !== -1 ? "active" : ""
                  }`}
                >
                  Scheduled
                </NavLink>
              </li>
              <li className="nav-item m-tabs__item">
                <NavLink
                  to={`/accounts/${account.id}/social/sent`}
                  className={`nav-link m-tabs__link ${
                    location.pathname.indexOf("sent") !== -1 ? "active" : ""
                  }`}
                >
                  Posted
                </NavLink>
              </li>
              <li className="nav-item m-tabs__item">
                <NavLink
                  to={`/accounts/${account.id}/social/dismissed`}
                  className={`nav-link m-tabs__link ${
                    location.pathname.indexOf("dismissed") !== -1
                      ? "active"
                      : ""
                  }`}
                >
                  Dismissed
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="tab-content">
          <div className="tab-pane active" id="m_user_profile_tab_1">
            <Switch>
              <Route
                path={match.url + "/recommended-v2"}
                component={AccountSocialRecommendedPageV2}
              />
              <Route
                path={match.url + "/suggested"}
                component={AccountSocialSuggestedPage}
              />
              <Route
                path={match.url + "/queue"}
                component={AccountSocialQueuePage}
              />
              <Route
                path={match.url + "/sent"}
                component={AccountSocialSentPage}
              />
              <Route
                path={match.url + "/dismissed"}
                component={AccountSocialDismissedPage}
              />
              <Redirect to={match.url + "/queue"} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    account: state.accounts.selectedAccount
  };
};

export default connect(mapStateToProps, {
  openModal: modalActions.openModal,
  fetchPosts: postActions.fetchPosts
})(AccountSocialPage);
