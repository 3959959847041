import React, { useState, useEffect, useCallback } from "react";

import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import TopicForm from "../../../components/accounts/TopicForm";
import { callApi } from "../../../utils/ContentoApi";
import { useContentoApi } from "../../../utils/useContentoApi";

const MarketingPlanTopicPage = ({ account }) => {
  const [allTopics, setAllTopics] = useState([]);
  const [accountTopics, setAccountTopics] = useState([]);

  const [fetchAllTopics] = useContentoApi(`/topics?verified=true`);
  const [fetchAccountTopics] = useContentoApi(
    `/accounts/${account.id}/news/sections/topics/followed`
  );

  const refreshAccountTopics = useCallback(() => {
    fetchAccountTopics()
      .then(setAccountTopics)
      .catch(error => {
        toastr.error(error.message);
      });
  }, [fetchAccountTopics]);

  useEffect(() => {
    refreshAccountTopics();
  }, [refreshAccountTopics]);

  useEffect(() => {
    fetchAllTopics()
      .then(setAllTopics)
      .catch(error => toastr.error(error.message));
  }, [fetchAllTopics]);

  const handleRemoveTopic = topicId => {
    callApi({
      method: "delete",
      url: `/accounts/${account.id}/news/sections/topics/${topicId}`
    })
      .then(() => {
        toastr.success("Successfully unfollowed topic");
        refreshAccountTopics();
      })
      .catch(error => {
        toastr.error(error.message);
      });
  };

  const handleAddTopic = ({ topicId, language }) => {
    callApi({
      method: "post",
      url: `/accounts/${account.id}/news/sections/topics/${topicId}`
    })
      .then(() => {
        refreshAccountTopics();
      })
      .catch(error => {
        toastr.error(error.message);
      });
  };

  return (
    <div className="m-portlet__body">
      <table className="table m-table m-table--head-bg-success">
        <thead>
          <tr>
            <th>Topic</th>
            <th>Language</th>
          </tr>
        </thead>
        <tbody>
          {accountTopics.map(({ detail: followedTopic }) => (
            <tr key={followedTopic.id}>
              <td>{followedTopic.label}</td>
              <td>{followedTopic.language}</td>
              <td>
                <button
                  className="btn m-btn--pill btn-danger btn-sm"
                  onClick={() => handleRemoveTopic(followedTopic.id)}
                >
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <TopicForm
        allTopics={allTopics}
        onSubmit={values => handleAddTopic(values)}
      />
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    account: state.accounts.selectedAccount
  };
};

export default connect(mapStateToProps, {})(MarketingPlanTopicPage);
