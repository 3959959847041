import React from "react";
import { Flex } from "rebass/styled-components";
import styled, { css } from "styled-components";
import { theme } from "../../theme";
import Icon from "./Icon";

// primary, secondary, green

const variants = {
  primary: {
    bg: theme.colors.grey01,
    color: theme.colors.text01,
    hover: {
      bg: theme.colors.grey02,
      color: theme.colors.text01
    },
    active: {
      bg: theme.colors.grey03,
      color: theme.colors.text01
    }
  },
  secondary: {
    bg: theme.colors.primaryTransparent,
    color: theme.colors.text02,
    hover: {
      bg: theme.colors.grey01,
      color: theme.colors.text01
    },
    active: {
      bg: theme.colors.primaryTransparent,
      color: theme.colors.text01
    }
  }
} as const;

type Variants = keyof typeof variants;

type ButtonWrapperProps = {
  variant: Variants;
  isActive?: boolean;
  borderStyle?: string;
  iconSize?: number;
  size?: number;
  padding?: number;
};

const ButtonWrapper = styled(Flex)<ButtonWrapperProps>`
  align-items: center;
  background-color: ${(props: ButtonWrapperProps) =>
    props.isActive
      ? variants[props.variant].active.bg
      : variants[props.variant].bg};
  border-radius: ${props => (props.borderStyle === "square" ? "4px" : "20px")};
  color: ${(props: ButtonWrapperProps) => variants[props.variant].color};
  cursor: pointer;
  font-size: ${props => {
    return `${
      props.iconSize
        ? props.iconSize
        : props.size
          ? props.size > 32
            ? 24
            : props.size
          : 24
    }px`;
  }};

  justify-content: center;
  padding: ${props => props.padding ?? 8}px;

  ${props =>
    !props.disabled &&
    css`
      &:hover {
        background-color: ${(props: ButtonWrapperProps) =>
          variants[props.variant as Variants].hover.bg};
        color: ${props => variants[props.variant as Variants].hover.color};
      }
      &:active {
        background-color: ${props =>
          variants[props.variant as Variants].active.bg};
        color: ${props => variants[props.variant as Variants].active.color};
      }
      &:focus {
        box-shadow:
          0 0 1px ${props => props.theme.colors.background1},
          0 0 0 1px ${props => props.theme.colors.background1},
          0 0 0 2px ${props => props.theme.colors.iconFill4} !important;
        background-color: ${props => variants[props.variant].hover.bg};
        transition: box-shadow 0.2s ease;
      }
    `}

  ${(props: ButtonWrapperProps) =>
    props.isActive &&
    css`
      background-color: ${variants[props.variant].active.bg};
      color: ${variants[props.variant].active.color};
      box-shadow:
        0 0 1px ${props => props.theme.colors.background1},
        0 0 0 1px ${props => props.theme.colors.background1},
        0 0 0 2px ${props => props.theme.colors.iconFill4} !important;
    `};

  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.colors.grey04};
    `}
`;

interface IconButtonProps extends ButtonWrapperProps, React.PropsWithChildren {
  icon: string | React.FC;
  iconColor?: string;
  onClick: () => void;
}

const IconButton: React.FC<IconButtonProps> = React.forwardRef(
  (
    { variant = "primary", icon, size = 36, iconColor, children, ...props },
    ref
  ) => {
    const className = typeof icon === "string" ? icon : undefined;

    return (
      <ButtonWrapper
        variant={variant}
        className={className}
        sx={{ width: size, height: size }}
        size={size}
        {...props}
      >
        {typeof icon !== "string" && (
          <Icon
            component={icon}
            size={props.iconSize || 20}
            color={iconColor}
            strokeWidth="2.5px"
          />
        )}
      </ButtonWrapper>
    );
  }
);

export default IconButton;
