import React, { useState } from "react";

import { TextFilterInput } from "./styles";
import KeywordBar from "./KeywordBar";

export default function TextFilterBar({ setTextFilter, keywords }) {
  const [query, setQuery] = useState("");

  function handleTextFilterKeyPress(e) {
    if (e.which === 10 || e.which === 13) {
      setTextFilter(query);
    }
  }

  return (
    <div>
      <TextFilterInput
        type="text"
        onKeyPress={handleTextFilterKeyPress}
        onChange={e => setQuery(e.target.value)}
        className="form-control"
        placeholder="Enter the search terms and press enter"
        value={query}
      />
      {keywords && (
        <KeywordBar
          onClickKeyword={keyword => {
            const updatedQuery = query + " " + keyword;
            setQuery(updatedQuery);
            setTextFilter(updatedQuery);
          }}
          keywords={keywords}
        />
      )}
    </div>
  );
}
