import { iconStyles } from "../../theme";
import React from "react";

import PropTypes from "prop-types";

const SocialIcon = ({ platform }) => {
  const style = iconStyles[platform];
  return (
    <span
      style={{
        backgroundColor: style.color,
        borderColor: style.color,
        cursor: "auto"
      }}
      className="btn btn-success m-btn m-btn--square m-btn--icon m-btn--icon-only"
    >
      <i className={style.iconClass} />
    </span>
  );
};

SocialIcon.propTypes = {
  platform: PropTypes.oneOf(["FACEBOOK", "LINKEDIN", "TWITTER", "INSTAGRAM"])
};

export default SocialIcon;
