import React from "react";
import { Link } from "react-router-dom";

export default function UserMenu({ user }: { user: any }) {
  return (
    <div
      id="m_header_topbar"
      className="m-topbar  m-stack m-stack--ver m-stack--general"
    >
      <div className="m-stack__item m-topbar__nav-wrapper">
        <ul className="m-topbar__nav m-nav m-nav--inline">
          {user && (
            <li
              className="m-nav__item m-topbar__user-profile  m-dropdown m-dropdown--medium m-dropdown--arrow  m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
              m-dropdown-toggle="click"
            >
              <a className="m-nav__link m-dropdown__toggle" href="#toggle">
                <span className="m-topbar__userpic m--hide">
                  <img
                    src="assets/app/media/img/users/user4.jpg"
                    className="m--img-rounded m--marginless m--img-centered"
                    alt=""
                  />
                </span>
                <span className="m-nav__link-icon m-topbar__usericon">
                  <span className="m-nav__link-icon-wrapper">
                    <i className="flaticon-user-ok"></i>
                  </span>
                </span>
                <span className="m-topbar__username m--hide">
                  {user.given_name}
                </span>
              </a>
              <div className="m-dropdown__wrapper">
                <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                <div className="m-dropdown__inner">
                  <div className="m-dropdown__header m--align-center">
                    <div className="m-card-user m-card-user--skin-light">
                      <div className="m-card-user__pic">
                        <img
                          src={user.picture}
                          className="m--img-rounded m--marginless"
                          alt={user.name}
                        />
                      </div>
                      <div className="m-card-user__details">
                        <span className="m-card-user__name m--font-weight-500">
                          {user.name}
                        </span>
                        <span className="m-card-user__email m--font-weight-300 m-link">
                          {user.email}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="m-dropdown__body">
                    <div className="m-dropdown__content">
                      <ul className="m-nav m-nav--skin-light">
                        <li className="m-nav__item">
                          <Link
                            to="/logout"
                            className="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                          >
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
