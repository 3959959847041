import React from "react";

interface ICountBadgeProps {
  count: number;
}

const CountBadge = ({ count }: ICountBadgeProps) => {
  const badgeClass = count >= 7 ? "success" : count >= 3 ? "warning" : "danger";
  return (
    <span
      style={{
        margin: 5,
        whiteSpace: "nowrap",
        fontWeight: "bold",
        fontSize: "14px"
      }}
      className={`m-badge m-badge--${badgeClass} m-badge--wide`}
    >
      {count}
    </span>
  );
};

export default CountBadge;
