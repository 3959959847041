import React, { useState, useEffect } from "react";
import Icon from "../../components/common/Icon";
import { Filter } from "@styled-icons/bootstrap/Filter";
import { MoreVerticalOutline } from "@styled-icons/evaicons-outline/MoreVerticalOutline";
import { Search } from "@styled-icons/feather/Search";
import { EmptyContainer, ContentContainer } from "./styles/AddSource.styled";
import {
  SearchbarsContainer,
  ButtonContainer,
  SearchContainer,
  FilterText,
  SearchBar,
  ButtonGroup,
  VerticalIconContainer,
  StyledButton
} from "./styles/FilterSection.styled";

interface ActiveButtons {
  group1: string[];
  group2: string[];
}

interface FilterSectionProps {
  onFiltersChanged: (filters: ActiveButtons) => void;
  onSearch: (value: string, field: "name") => void;
  onTopicSearch: (value: string, field: "topic") => void;
}

const FilterSection: React.FC<FilterSectionProps> = ({
  onFiltersChanged,
  onSearch,
  onTopicSearch
}) => {
  const [activeButtons, setActiveButtons] = useState<ActiveButtons>({
    group1: ["All"],
    group2: ["All"]
  });

  const handleButtonClick = (group: keyof ActiveButtons, button: string) => {
    setActiveButtons(prevState => {
      const groupButtons = prevState[group];
      const lowercaseButton = button.toLowerCase();
      if (lowercaseButton === "all") {
        return {
          ...prevState,
          [group]: ["All"]
        };
      } else {
        const index = groupButtons.indexOf(lowercaseButton);
        if (index === -1) {
          return {
            ...prevState,
            [group]: groupButtons.includes("All")
              ? [lowercaseButton]
              : [...groupButtons, lowercaseButton]
          };
        } else {
          return {
            ...prevState,
            [group]: groupButtons.filter(
              b => b !== lowercaseButton && b !== "All"
            )
          };
        }
      }
    });
  };

  const isButtonActive = (group: keyof ActiveButtons, button: string) => {
    return activeButtons[group].includes(button);
  };

  useEffect(() => {
    onFiltersChanged(activeButtons);
  }, [activeButtons, onFiltersChanged]);

  const [nameInput, setNameInput] = useState("");
  const [topicInput, setTopicInput] = useState("");

  useEffect(() => {
    onTopicSearch(topicInput, "topic");
  }, [topicInput]);

  useEffect(() => {
    onSearch(nameInput, "name");
  }, [nameInput, onSearch]);
  return (
    <EmptyContainer>
      <ContentContainer>
        <SearchbarsContainer>
          <SearchContainer>
            <Icon component={Search} size={20} />
            <SearchBar
              type="text"
              placeholder="Search by Title"
              value={nameInput}
              onChange={e => setNameInput(e.target.value)}
            />
          </SearchContainer>
          <SearchContainer>
            <Icon component={Search} size={20} />
            <SearchBar
              type="text"
              placeholder="Search by Category"
              value={topicInput}
              onChange={e => setTopicInput(e.target.value)}
            />
          </SearchContainer>
        </SearchbarsContainer>
        <ButtonContainer>
          <Icon component={Filter} size={20} />
          <FilterText>Filters</FilterText>
          <ButtonGroup>
            <StyledButton
              active={isButtonActive("group1", "All")}
              onClick={() => handleButtonClick("group1", "All")}
            >
              All
            </StyledButton>
            <StyledButton
              active={isButtonActive("group1", "rss")}
              onClick={() => handleButtonClick("group1", "RSS")}
            >
              RSS
            </StyledButton>
            <StyledButton
              active={isButtonActive("group1", "twitter")}
              onClick={() => handleButtonClick("group1", "Twitter")}
            >
              Twitter
            </StyledButton>
            <StyledButton
              active={isButtonActive("group1", "html-links")}
              onClick={() => handleButtonClick("group1", "HTML-Links")}
            >
              HTML, Links
            </StyledButton>
          </ButtonGroup>
          <VerticalIconContainer>
            <Icon component={MoreVerticalOutline} size={20} />
          </VerticalIconContainer>
          <ButtonGroup>
            <StyledButton
              active={isButtonActive("group2", "All")}
              onClick={() => handleButtonClick("group2", "All")}
            >
              All
            </StyledButton>
            <StyledButton
              active={isButtonActive("group2", "active")}
              onClick={() => handleButtonClick("group2", "Active")}
            >
              Active
            </StyledButton>
            <StyledButton
              active={isButtonActive("group2", "inactive")}
              onClick={() => handleButtonClick("group2", "Inactive")}
            >
              Inactive
            </StyledButton>
            <StyledButton
              active={isButtonActive("group2", "error")}
              onClick={() => handleButtonClick("group2", "Error")}
            >
              Error
            </StyledButton>
          </ButtonGroup>
        </ButtonContainer>
      </ContentContainer>
    </EmptyContainer>
  );
};

export default FilterSection;
