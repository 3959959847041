import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import { useDispatch } from "react-redux";
import { callApi } from "../../utils/ContentoApi";
import Portlet from "../../components/common/portlet/Portlet";
import TopicForm from "../../components/topics/TopicForm";
import * as modalActions from "../../state/actions/ModalActions";

export default function TopicsListPage(props) {
  const [topics, setTopics] = useState([]);
  const [textFilter, setTextFilter] = useState([]);
  const [verifiedFilter, setVerifiedFilter] = useState(false);
  const [hideNewTopicForm, setHideNewTopicForm] = useState(false);

  const dispatch = useDispatch();

  const refreshTopics = () => {
    callApi({
      method: "get",
      url: "/topics"
    })
      .then(setTopics)
      .catch(error => {
        console.error(error);
        toastr.error(error.message);
      });
  };
  useEffect(() => {
    refreshTopics();
  }, []);

  const onAdd = topic => {
    callApi({
      method: "post",
      url: `/topics`,
      data: topic
    })
      .then(result => {
        toastr.success(`Successfully added tags.`);
        refreshTopics();
      })
      .catch(error => {
        toastr.error(error.message);
      });
  };

  const onUpdate = topic => {
    callApi({
      method: "put",
      url: `/topics/${topic.id}`,
      data: topic
    })
      .then(result => {
        toastr.success(`Successfully updated topic.`);
        refreshTopics();
      })
      .catch(error => {
        toastr.error(error.message);
      });
  };

  function openEditTopicModal(topic) {
    dispatch(
      modalActions.openModal("EDIT_TOPIC_MODAL", {
        topics,
        topic,
        onSubmit: onUpdate
      })
    );
  }

  let filteredTopics = textFilter
    ? topics.filter(
        topic =>
          topic.label.indexOf(textFilter) !== -1 ||
          (topic.parent && topic.parent.label.indexOf(textFilter) !== -1)
      )
    : topics;

  if (verifiedFilter) {
    filteredTopics = filteredTopics.filter(topic => topic.verified);
  }

  return (
    <>
      <Portlet>
        <input
          type={"button"}
          value={
            hideNewTopicForm ? "Show new topic form" : "Hide new topic form"
          }
          onClick={() => setHideNewTopicForm(!hideNewTopicForm)}
        />
        {!hideNewTopicForm && (
          <TopicForm allTopics={topics} onSubmit={onAdd} showSettings />
        )}
      </Portlet>
      <Portlet title="Topic Management">
        Filter by label or parent label <br />
        <input
          type={"text"}
          onChange={e => setTextFilter(e.target.value?.toLowerCase())}
        />
        <br />
        <br />
        Verified Only <br />
        <input
          type={"checkbox"}
          onChange={e => setVerifiedFilter(e.target.checked)}
        />
        <br />
        <br />
        <br />
        <table className="table m-table m-table--head-bg-success">
          <thead>
            <tr>
              <th style={{ textAlign: "center", width: "5%" }}>Verified</th>
              <th style={{ textAlign: "center", width: "10%" }}>Image</th>
              <th style={{ textAlign: "center", width: "10%" }}>Label</th>
              <th style={{ textAlign: "center", width: "10%" }}>Parent</th>
              <th style={{ textAlign: "center", width: "5%" }}>Language</th>
              <th style={{ textAlign: "center", width: "5%" }}>Color</th>
              <th style={{ textAlign: "center", width: "10%" }}>Title</th>
              <th style={{ textAlign: "center", width: "20%" }}>Description</th>
              <th style={{ width: "5%" }}></th>
            </tr>
          </thead>
          <tbody>
            {filteredTopics.map(topic => (
              <tr key={topic.id}>
                <td style={{ textAlign: "center" }}>
                  <input
                    type="checkbox"
                    checked={topic.verified ? "checked" : ""}
                    disabled={true}
                  />
                </td>
                <td style={{ textAlign: "center" }}>
                  <img
                    alt={topic.label}
                    src={topic.image}
                    width={50}
                    height={50}
                  />
                </td>
                <td style={{ textAlign: "center" }}>{topic.label}</td>
                <td style={{ textAlign: "center" }}>{topic.parent?.label}</td>
                <td style={{ textAlign: "center" }}>{topic.language}</td>
                <td style={{ textAlign: "center" }}>{topic.color}</td>
                <td style={{ textAlign: "center" }}>{topic.title}</td>
                <td style={{ textAlign: "center" }}>{topic.description}</td>
                <td style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    className="btn btn-accent m-btn m-btn--air m-btn--custom"
                    onClick={() => openEditTopicModal(topic)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Portlet>
    </>
  );
}
