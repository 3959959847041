import React from "react";
import Icon from "components/common/Icon";
import { LineChart } from "@styled-icons/boxicons-regular/LineChart";
import { EmptyContainer, ContentContainer } from "../common/AdminHeader";
import {
  TitleContainer,
  Title,
  IconWrapper,
  ChartContainer,
  IFrameWrapper
} from "./styles/ChartSection.styled";

const ChartSection = () => {
  return (
    <EmptyContainer>
      <ContentContainer>
        <TitleContainer>
          <Title>
            <IconWrapper>
              <Icon component={LineChart} size={20} />
            </IconWrapper>
            Failed Post Analytics
          </Title>
        </TitleContainer>
        <ChartContainer>
          <IFrameWrapper>
            <iframe
              src="//metabase.internal.willow.co/public/question/59f58e05-35ec-4779-9260-590685860649"
              width="800"
              height="600"
              style={{ border: "none" }}
            ></iframe>
          </IFrameWrapper>
          <IFrameWrapper>
            <iframe
              src="//metabase.internal.willow.co/public/question/e17c268b-2611-472c-95b8-acf630c83250"
              width="800"
              height="600"
              style={{ border: "none" }}
            ></iframe>
          </IFrameWrapper>
        </ChartContainer>
      </ContentContainer>
    </EmptyContainer>
  );
};

export default ChartSection;
