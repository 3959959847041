import React, { Component } from "react";
import classNames from "classnames";
import ProfileIcon from "../../accounts/ProfileIcon";

class ChannelMultiSelect extends Component {
  selectChannel(event, channel) {
    event.preventDefault();
    const {
      input: { value, onChange }
    } = this.props;
    const newValue = [...value];
    if (newValue.includes(channel.id)) {
      newValue.splice(newValue.indexOf(channel.id), 1);
    } else {
      newValue.push(channel.id);
    }
    onChange(newValue);
  }

  render() {
    const {
      input: { value },
      label,
      meta: { touched, error, warning },
      fullWidth,
      channels,
      disabled
    } = this.props;
    const groupClasses = classNames({
      "form-group m-form__group row": true,
      "has-danger": touched && error,
      "has-warning": touched && warning
    });

    const inputWrapperClasses = classNames({
      "col-7": !fullWidth,
      "col-10": fullWidth
    });
    return (
      <div className={groupClasses}>
        {label && <label className="col-2 col-form-label">{label}</label>}
        <div className={inputWrapperClasses} style={styles.optionWrapper}>
          {channels.map(channel => {
            let onClick = e => this.selectChannel(e, channel);
            if (disabled) {
              onClick = null;
            }
            return (
              <ProfileIcon
                key={channel.id}
                style={styles.optionItem}
                onClick={onClick}
                channel={channel}
                disabled={value.indexOf(channel.id) === -1}
              />
            );
          })}
          {error && touched && (
            <div className="form-control-feedback">{error}</div>
          )}
        </div>
      </div>
    );
  }
}

export default ChannelMultiSelect;

const styles = {
  optionWrapper: {
    display: "flex",
    flexDirection: "row"
  },
  optionItem: {
    marginRight: 10,
    cursor: "pointer"
  }
};
