import React, { useState, useEffect } from "react";
import { SortUp, SortDown } from "styled-icons/fa-solid";
import { usePrevious } from "../../utils/usePrevious";

export default function AccountsHeader({ onSortChange }) {
  const [orderBy, setOrderBy] = useState(["updatedAt", "DESC"]);
  const prevOrderBy = usePrevious(orderBy);

  const defaultDirection = {
    updatedAt: "DESC",
    companyName: "ASC",
    lastUserTokenRefresh: "DESC",
    autoPilot: "DESC",
    status: "ASC",
    lastSuggestionDate: "DESC"
  };

  const handleClick = field => {
    if (orderBy[0] === field) {
      //Toggle
      setOrderBy([field, orderBy[1] === "DESC" ? "ASC" : "DESC"]);
    } else {
      setOrderBy([field, defaultDirection[field]]);
    }
  };

  useEffect(() => {
    //Only update if there was a change to avoid an infinite loop
    if (
      prevOrderBy &&
      (prevOrderBy[0] !== orderBy[0] || prevOrderBy[1] !== orderBy[1])
    ) {
      onSortChange(orderBy);
    }
  }, [orderBy, prevOrderBy, onSortChange]);

  return (
    <tr>
      <th></th>
      <th
        onClick={() => handleClick("companyName")}
        style={{ cursor: "pointer" }}
      >
        Name
        {orderBy[0] === "companyName" &&
          (orderBy[1] === "DESC" ? (
            <SortUp size={16} />
          ) : (
            <SortDown size={16} />
          ))}
      </th>
      <th>Status</th>
      <th>Billing status</th>
      <th
        onClick={() => handleClick("lastUserTokenRefresh")}
        style={{ cursor: "pointer" }}
      >
        Last login
        {orderBy[0] === "lastUserTokenRefresh" &&
          (orderBy[1] === "DESC" ? (
            <SortUp size={16} />
          ) : (
            <SortDown size={16} />
          ))}
      </th>
      <th
        onClick={() => handleClick("lastSuggestionDate")}
        style={{ cursor: "pointer" }}
      >
        Last suggestion
        {orderBy[0] === "lastSuggestionDate" &&
          (orderBy[1] === "DESC" ? (
            <SortUp size={16} />
          ) : (
            <SortDown size={16} />
          ))}
      </th>
      <th></th>
      <th>Channels</th>
      <th>Manager</th>
    </tr>
  );
}
