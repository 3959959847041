import moment from "moment";

const FormattedDate = ({
  date,
  format = "LLL"
}: {
  date: moment.MomentInput;
  format?: string;
}) => {
  return moment(date).format(format);
};

export default FormattedDate;
