import React from "react";

const Portlet = ({ title, children, cols }) => {
  return (
    <div className={"m-portlet" + (cols ? ` col-${cols}` : "")}>
      {title && (
        <div className="m-portlet__head">
          <div className="m-portlet__head-caption">
            <div className="m-portlet__head-title">
              <h3 className="m-portlet__head-text">{title}</h3>
            </div>
          </div>
        </div>
      )}

      <div className="m-portlet__body">{children}</div>
    </div>
  );
};

export default Portlet;
