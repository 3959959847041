import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import FallbackImage from "../../components/common/FallbackImage";
import emptyProfileIcon from "../../assets/images/profile-icon.png";
import { getTimeDifferenceElement } from "../../utils/date";
import Auth from "../../utils/Auth";

import DashboardAccountList from "./DashboardAccountList";

export default function PendingPosts(props) {
  const tableHeader = (
    <tr>
      <th style={{ width: "10%" }} />
      <th style={{ width: "30%" }}>Name</th>
      <th style={{ width: "20%", textAlign: "center" }}>Content type</th>
      <th style={{ width: "20%", textAlign: "center" }}>Date</th>
      <th style={{ width: "20%", textAlign: "center" }}>Time until task</th>
      <th style={{ width: "10%", textAlign: "center" }} />
    </tr>
  );

  const handleButtonClick = accountId => {
    return Auth.impersonateCustomer(accountId, props.currentUser.id);
  };

  const buildRecord = ({ task, account }) => (
    <tr key={account.id} style={{ borderBottom: "1px solid #eee" }}>
      <td
        style={{
          verticalAlign: "middle",
          textAlign: "center"
        }}
      >
        <FallbackImage
          src={account.logo || emptyProfileIcon}
          alt={account.companyName}
          height={50}
          width={50}
        />
      </td>
      <td style={{ verticalAlign: "middle" }}>
        <Link to={`/accounts/${account.id}`}>{account.companyName}</Link>
      </td>
      <td
        style={{
          verticalAlign: "middle",
          textAlign: "center"
        }}
      >
        {task.contentTypeId}
      </td>
      <td
        style={{
          verticalAlign: "middle",
          textAlign: "center"
        }}
      >
        {moment(task.date).format("DD/MM/YYYY")}
      </td>
      <td
        style={{
          verticalAlign: "middle",
          textAlign: "center"
        }}
      >
        {getTimeDifferenceElement({
          date: task.date,
          applyColors: true
        })}
      </td>
      <td
        style={{
          verticalAlign: "middle",
          textAlign: "center"
        }}
      >
        <button
          onClick={() => handleButtonClick(account.id)}
          className="m-btn m-btn--pill m-btn--hover-brand btn btn-sm btn-secondary"
        >
          Create Post
        </button>
      </td>
    </tr>
  );

  return (
    <DashboardAccountList
      {...props}
      title="Post creation needed"
      url="/admin/accounts/pending-posts"
      tableHeader={tableHeader}
      buildRecord={buildRecord}
      extraApiParams={{ workingDays: props.workingDays }}
    />
  );
}
