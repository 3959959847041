import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";

import Portlet from "../../components/common/portlet/Portlet";
import { callApi } from "../../utils/ContentoApi";

/**
 * Creates a list of accounts without pagination.
 */
export default function DashboardAccountList({
  currentUser,
  url,
  extraApiParams,
  title,
  tableHeader,
  buildRecord
}) {
  const [accountsResponse, setAccountsResponse] = useState(null);

  useEffect(() => {
    callApi({
      method: "get",
      url,
      params: {
        ...(extraApiParams || {})
      }
    })
      .then(setAccountsResponse)
      .catch(error => {
        toastr.error(error.message);
        setAccountsResponse(null);
      });
  }, [extraApiParams, url]);

  if (!accountsResponse) {
    return null;
  }

  return (
    <Portlet title={title}>
      <table className="table m-table m-table--head-bg-success">
        <thead>{tableHeader}</thead>
        <tbody>{accountsResponse.map(account => buildRecord(account))}</tbody>
      </table>
    </Portlet>
  );
}
