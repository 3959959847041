import React, { useEffect } from "react";
import Icon from "../../components/common/Icon";
import { ArrowLeftS } from "@styled-icons/remix-line/ArrowLeftS";
import { KeyboardArrowRight } from "@styled-icons/material/KeyboardArrowRight";
import { EmptyContainer } from "./styles/AddSource.styled";
import {
  ButtonContainer,
  PageButton,
  ArrowButton
} from "./styles/Pagination.styled";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  filteredResults: any[];
}

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  onPageChange,
  filteredResults
}) => {
  const rangeSize = 1;

  const visiblePages = () => {
    let start = Math.max(currentPage - rangeSize, 1);
    let end = Math.min(currentPage + rangeSize, totalPages);

    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    return pages;
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const goToPage = (page: number) => {
    onPageChange(page);
  };

  useEffect(() => {
    if (filteredResults && filteredResults.length > 0) {
      const totalFilteredResults = filteredResults.length;
      const newTotalPages = Math.ceil(totalFilteredResults / 10);
      if (newTotalPages !== totalPages) {
        onPageChange(1);
      }
    } else {
      onPageChange(1);
    }
  }, [filteredResults, totalPages, onPageChange]);

  return (
    <EmptyContainer>
      <ButtonContainer>
        <ArrowButton onClick={goToPreviousPage} disabled={currentPage === 1}>
          <Icon component={ArrowLeftS} size={40} />
        </ArrowButton>
        {currentPage > 1 + rangeSize && (
          <PageButton onClick={() => goToPage(1)}>1</PageButton>
        )}
        {currentPage > 2 + rangeSize && <span>...</span>}
        {visiblePages().map(page => (
          <PageButton key={page} onClick={() => goToPage(page)}>
            {page}
          </PageButton>
        ))}
        {currentPage < totalPages - rangeSize - 1 && <span>...</span>}
        {currentPage < totalPages - rangeSize && (
          <PageButton onClick={() => goToPage(totalPages)}>
            {totalPages}
          </PageButton>
        )}
        <ArrowButton
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
        >
          <Icon component={KeyboardArrowRight} size={40} />
        </ArrowButton>
      </ButtonContainer>
    </EmptyContainer>
  );
};

export default Pagination;
