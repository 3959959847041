import React from "react";
import { Field, reduxForm } from "redux-form";
import { required } from "../../utils/Validation";
import Select from "../common/form/Select";
import FormTitle from "../common/form/FormTitle";
import { WEEK_DAYS } from "../../constants";
import TextInput from "../common/form/TextInput";
import ChannelMultiSelect from "../common/form/ChannelMultiSelect";

const ScheduleTimeForm = props => {
  const { handleSubmit, pristine, reset, submitting, channels } = props;

  let weekDayOptions = Object.keys(WEEK_DAYS).map(weekDayId => {
    return {
      value: weekDayId,
      label: `${WEEK_DAYS[weekDayId]}`
    };
  });

  weekDayOptions = [
    ...[
      { value: "ALL", label: "All Days" },
      { value: "WORKDAYS", label: "Work Days" },
      { value: "WEEKENDS", label: "Weekends" }
    ],
    ...weekDayOptions
  ];

  return (
    <form
      onSubmit={handleSubmit}
      className="m-form m-form--fit m-form--label-align-right"
    >
      <div className="m-portlet__body">
        <FormTitle>Add Schedule Time</FormTitle>
        <Field
          validate={required}
          label="Day"
          component={Select}
          options={weekDayOptions}
          name="dayOfWeek"
        />
        <Field
          validate={required}
          label="Time"
          component={TextInput}
          type="time"
          name="time"
        />
        <Field
          validate={required}
          label="Select channels"
          component={ChannelMultiSelect}
          channels={channels}
          name="channels"
        />
      </div>
      <div className="m-portlet__foot m-portlet__foot--fit">
        <div className="m-form__actions">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-7">
              <button
                type="submit"
                className="btn btn-accent m-btn m-btn--air m-btn--custom"
                disabled={submitting}
              >
                Add
              </button>
              &nbsp;&nbsp;
              <button
                type="reset"
                className="btn btn-secondary m-btn m-btn--air m-btn--custom"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export default reduxForm({
  // a unique name for the form
  form: "scheduleTime",
  enableReinitialize: true
})(ScheduleTimeForm);
