import React from "react";

import moment from "moment";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { callApi } from "../../../utils/ContentoApi";
import ProfileIcon from "../../../components/accounts/ProfileIcon";
import * as accountActions from "../../../state/actions/AccountActions";

class AccountChannelsPage extends React.Component {
  state = { channels: [] };

  componentDidMount() {
    //fetch profiles
    this.refreshChannels();
  }

  refreshChannels() {
    callApi({
      method: "get",
      url: `/accounts/${this.props.account.id}/channels`
    })
      .then(channels => {
        this.setState({ channels: channels });
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({ channels: [] });
      });
  }

  handleRemoveChannel(accountId, channelId) {
    callApi({
      method: "delete",
      url: `/accounts/${this.props.account.id}/channels/${channelId}`
    })
      .then(() => {
        toastr.success("Successfully removed profile");
        this.refreshChannels();
        //refresh account
        this.props.fetchAccount(this.props.account.id);
      })
      .catch(error => {
        toastr.error(error.message);
      });
  }

  handleSyncPosts(accountId, channel) {
    callApi({
      method: "get",
      url: `/accounts/${this.props.account.id}/channels/${channel.id}/sync`
    })
      .then(() => {
        toastr.success(
          `Started syncing ${channel.username} ${channel.service}`
        );
      })
      .catch(error => {
        toastr.error(error.message);
      });
  }

  render() {
    const { account } = this.props;
    const { channels } = this.state;
    return (
      <div className="m-portlet__body">
        <table className="table m-table m-table--head-bg-success">
          <thead>
            <tr>
              <th>Avatar</th>
              <th>Service</th>
              <th>Username</th>
              <th>Timezone</th>
              <th>Source</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {channels.map(channel => {
              return (
                <ChannelRow
                  key={channel.id}
                  channel={channel}
                  handleSyncPosts={() =>
                    this.handleSyncPosts(account.id, channel)
                  }
                  handleRemoveChannel={() =>
                    this.handleRemoveChannel(account.id, channel.id)
                  }
                />
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

function ChannelRow({ channel, handleSyncPosts, handleRemoveChannel }) {
  return (
    <tr>
      <td>
        <ProfileIcon channel={channel} />
      </td>
      <td>
        {channel.service} - {channel.serviceType}
      </td>
      <td>{channel.username}</td>
      <td>{channel.timezone}</td>
      <td>
        {channel.channelConnections.map(connection => {
          return <ConnectionInfo key={connection.id} connection={connection} />;
        })}
      </td>
      <td>
        <button
          className="btn m-btn--pill btn-accent btn-sm"
          onClick={handleSyncPosts}
        >
          Sync
        </button>
        &nbsp;
        <button
          className="btn m-btn--pill btn-danger btn-sm"
          onClick={handleRemoveChannel}
        >
          Remove
        </button>
      </td>
    </tr>
  );
}

function ConnectionInfo({ connection }) {
  const connectionMap = {
    facebook_page: { sourceName: "Facebook", id: "pageId" },
    twitter_profile: { sourceName: "Twitter", id: "profileId" },
    linkedin_profile: { sourceName: "LinkedIn Profile", id: "profileId" },
    linkedin_business: { sourceName: "LinkedIn Business", id: "businessUrn" },
    instagram_business: {
      sourceName: "Instagram Business",
      id: "instagramUserId"
    }
  };

  const errorStatusMap = {
    AUTH_ERROR: "Needs reconnection",
    PERMISSION_REQUIRED: "Needs reconnection",
    BAD_REQUEST: "Wrong configuration"
  };

  const source = connectionMap[connection.connectionType];
  let errorStatus = "OK",
    statusColor = "green";
  if (connection.lastErrorType) {
    errorStatus = errorStatusMap[connection.lastErrorType];
    if (errorStatus) {
      statusColor = "red";
    } else if (
      connection.lastErrorType === "NOT_FOUND" &&
      connection.disabledUntil &&
      moment(connection.disabledUntil).isAfter(Date.now())
    ) {
      errorStatus = "Profile not found";
      statusColor = "red";
    } else {
      errorStatus = "OK";
      statusColor = "orange";
    }
  }

  let recentErrors;
  if (connection.recentErrorCount > 0) {
    statusColor = statusColor === "red" ? "red" : "orange";
    recentErrors = (
      <span style={{ color: statusColor }}>
        &nbsp;({connection.recentErrorCount} recent errors)
      </span>
    );
  }

  return (
    <div>
      {source.sourceName}
      <br />
      Status: <span style={{ color: statusColor }}>{errorStatus}</span>
      {recentErrors}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    account: state.accounts.selectedAccount
  };
};

export default connect(mapStateToProps, {
  fetchAccount: accountActions.fetchAccount
})(AccountChannelsPage);
