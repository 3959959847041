import React from "react";

import { connect } from "react-redux";
import { callApi } from "../../../utils/ContentoApi";
import { toastr } from "react-redux-toastr";
import RecommendationsList from "../../../components/recommendations/RecommendationsList";

class AccountSocialRecommendedPageV2 extends React.Component {
  state = {
    recommendedContent: [],
    isDeleting: null,
    isSuggesting: null,
    searchTerms: [],
    loading: true
  };

  componentDidMount() {
    this.refreshRecommendations();
  }

  refreshRecommendations() {
    this.setState({ loading: true });
    callApi({
      method: "get",
      url: `/accounts/${this.props.account.id}/contents/automatically-suggested`
    })
      .then(result => {
        const items = result.map(item => {
          //Map to old structure
          return {
            _id: item.id,
            _source: item
          };
        });
        const newState = { recommendedContent: items, loading: false };

        this.setState(newState);
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({
          recommendedContent: [],
          loading: false
        });
      });
  }

  handleChangeFilters(filters) {
    this.setState({ filters: filters }, () => {
      this.refreshRecommendations();
    });
  }

  handleSuggest(contentId, content) {
    callApi({
      method: "post",
      url: `/accounts/${this.props.account.id}/contents/${contentId}`
    })
      .then(() => {
        this.refreshRecommendations();
        this.setState({ isSuggesting: null });
        toastr.success("Success!", "The article has been suggested!");
      })
      .catch(error => {
        console.error(error);
        toastr.error("Error suggesting content", error.message);
        this.setState({ isSuggesting: null });
      });
  }

  render() {
    const { recommendedContent, loading } = this.state;

    return (
      <div className="m-portlet__body">
        <RecommendationsList
          results={recommendedContent}
          loading={loading}
          onSuggestContent={this.handleSuggest.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    account: state.accounts.selectedAccount
  };
};

export default connect(mapStateToProps, {})(AccountSocialRecommendedPageV2);
