import decode from "jwt-decode";
import { toastr } from "react-redux-toastr";
import { callApi } from "./ContentoApi";
import config from "../config";

const ACCESS_TOKEN_KEY = "session_token";

function login(credentials) {
  return callApi(
    {
      url: "auth/login",
      method: "post",
      data: credentials
    },
    false
  )
    .then(({ accessToken }) => {
      setAccessToken(accessToken);
      return accessToken;
    })
    .catch(error => {
      throw error;
    });
}

function getUserInfo() {
  const token = getAccessToken();
  if (!token) {
    throw new Error("No token");
  }

  const tokenData = decode(token);
  if (!tokenData.userId) {
    throw new Error("Invalid token");
  }

  return callApi(
    {
      url: `users/${tokenData.userId}`
    },
    false
  )
    .then(user => {
      return user;
    })
    .catch(err => {
      throw err;
    });
}

function logout() {
  return new Promise(resolve => {
    clearAccessToken();
    resolve();
  });
}

function hasValidToken() {
  const accessToken = getAccessToken();
  return !!accessToken && !isTokenExpired(accessToken);
}

/*
Background auth
 */
function renewToken() {
  return callApi(
    {
      url: "auth/refresh",
      method: "post"
    },
    false
  )
    .then(({ accessToken }) => {
      setAccessToken(accessToken);
    })
    .catch(error => {
      //throw error;
    });
}

function generateNonce(userId, nonceType) {
  return callApi({
    method: "post",
    url: `/auth/generate-nonce/${userId}`,
    data: {
      nonceType
    }
  })
    .then(result => {
      return result.nonce;
    })
    .catch(error => {
      throw error;
    });
}

// Get and store access_token in local storage
function setAccessToken(accessToken) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  scheduleRenewal();
}

function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

function clearAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  cancelRenewal();
}

let tokenRenewalTimeout;

function scheduleRenewal() {
  cancelRenewal();
  const expiresAt = getTokenExpirationDate(getAccessToken());
  //Renew in hour or before if it's close to expiring.
  const delay = Math.min(
    expiresAt.getTime() - Date.now() - 30 * 1000,
    60 * 60 * 1000
  );
  if (delay > 0) {
    tokenRenewalTimeout = setTimeout(renewToken, delay);
  }
}

function cancelRenewal() {
  clearTimeout(tokenRenewalTimeout);
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate && expirationDate < new Date();
}

function getTokenExpirationDate(accessToken) {
  try {
    const token = decode(accessToken);
    if (!token.exp) {
      return null;
    }
    const date = new Date(0);
    date.setUTCSeconds(token.exp);
    return date;
  } catch (err) {
    console.error(err);
    return null;
  }
}

async function impersonateCustomer(accountId, currentUserId) {
  //for safari we need to create the window at this level, otherwise it get's blocked
  const win = window.open();
  try {
    const nonce = await generateNonce(currentUserId);
    win.location.href = `${config.frontendUrl}/goto/dashboard/${nonce}/${accountId}`;
    win.focus();
  } catch (err) {
    toastr.error(err.message);
  }
}

export default {
  login,
  renewToken,
  hasValidToken,
  getUserInfo,
  getAccessToken,
  logout,
  generateNonce,
  impersonateCustomer
};
