//import './assets/js/vendors.bundle';
//import './assets/js/scripts.bundle';

import * as Sentry from "@sentry/browser";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { unregister } from "./registerServiceWorker";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";

import store, { history } from "./state/store";

if (process.env.REACT_APP_ENV === "production") {
  Sentry.init({
    dsn: "https://96967ba24158448b8e6a67fabbd88fda@sentry.io/1459605",
    environment: process.env.REACT_APP_ENV
  });
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

unregister();
