import React, { useState } from "react";
import { Flex, Text, Image } from "rebass/styled-components";
import { FileUpload } from "@styled-icons/material-outlined/FileUpload";
import { Trash3 as Trash } from "@styled-icons/bootstrap/Trash3";

import {
  Control,
  FieldValues,
  Path,
  useController,
  UseControllerProps
} from "react-hook-form";
import {
  StyledInputFieldset,
  StyledInputLabel,
  ErrorMessage,
  HelperText
} from "components/common/form/styles";
import ImageUpload from "components/attachment/ImageUpload";

interface Props<T extends FieldValues>
  extends UseControllerProps<T>,
    React.CSSProperties {
  label: string;
  control?: Control<T>;
  name: Path<T>;
  required?: boolean;
  helperText?: string;
}

const CalendarImageInput = <T extends FieldValues>(inputs: Props<T>) => {
  let {
    fieldState,
    field: { value, onChange },
    formState
  } = useController(inputs);

  const { label, required, helperText } = inputs;

  const hasError = fieldState.error !== undefined;

  // This value looks redundant and you are right.
  /// But it exists to let us simulate clearing the image value when a default value passed by the form.
  // Till we find a more elegant solution, this workaround exists
  const [imageValue, setImagePath] = useState<any>(value);

  return (
    <StyledInputFieldset>
      <StyledInputLabel>
        {`${label} `}{" "}
        {required && <span style={{ color: "#FF0E0E" }}>{` * `}</span>}
      </StyledInputLabel>
      {helperText && <HelperText>{helperText}</HelperText>}
      <Flex
        sx={{
          borderRadius: "5px",
          padding: "15px 10px",
          border: "1px solid #D8D8D8",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          fontSize: "14px",
          fontWeight: 700,
          lineHeight: "20px",
          color: "#4A4A4A",
          marginTop: "20px"
        }}
      >
        {value && imageValue ? (
          <ImageView
            src={value}
            onRemoveImage={() => {
              if (formState.defaultValues?.coverImage) {
                (formState.defaultValues as any).coverImage = undefined;
              }
              onChange(undefined);
              setImagePath(undefined);
              console.log("Default values:: ", formState.defaultValues);
              console.log("On Remove image called:: -Value-:: ", value);
            }}
          />
        ) : (
          <ImageUpload
            onUpload={(images: any) => {
              if (images) {
                onChange(images[0].path);
                setImagePath(images[0].path);
              }
            }}
            width="100%"
            border="none"
            idleStateView={<NoImageView onClick={() => {}} />}
            multiple={false}
            // {...formField}
          />
        )}
      </Flex>

      {hasError && <ErrorMessage>{fieldState.error?.message}</ErrorMessage>}
    </StyledInputFieldset>
  );
};

const NoImageView = ({ onClick }: { onClick: () => void }) => {
  return (
    <Flex
      sx={{
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "20px",
        color: "#4A4A4A"
      }}
      onClick={onClick}
    >
      <FileUpload size={"18px"} />
      <Text>Upload Image</Text>
    </Flex>
  );
};

const ImageView = ({
  src,
  onRemoveImage
}: {
  src: string;
  onRemoveImage: () => void;
}) => {
  return (
    <Flex sx={{ gap: "16px", alignItems: "center" }}>
      <Image
        src={src}
        width={"144px"}
        height={"96px"}
        sx={{ objectFit: "cover" }}
      />
      <Trash
        size={"14px"}
        onClick={() => onRemoveImage()}
        data-tip="Remove Image"
        cursor={"pointer"}
      />
    </Flex>
  );
};

export default CalendarImageInput;
