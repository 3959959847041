import styled from "styled-components";
import { Box, Flex } from "rebass";
import React from "react";
import ReactModal from "react-modal";

export const ModalContainer = styled(Box)`
  position: fixed; /* Stay in place */
  z-index: 500; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;

export const ModalContent = styled(Box)`
  background: #f7f8f9;
  border: 1px solid #eaebeb;
  border-radius: 8px;
  max-width: 550px;
  min-height: 200px;
  margin: 10% auto;

  padding: 15px 20px 15px 20px;
`;

export const ModalHeader = styled(Flex)`
  font-size: 16px;
  font-weight: 600;
  justify-content: space-between;
  align-items: center;
`;

export const ModalTitleWrapper = styled(Flex)`
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const ModalBorderHeader = styled(Flex)<{ padding: string }>`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.grey02};
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-between;
  letter-spacing: 0.3px;
  margin: -8px -20px 0;
  padding: ${props => props.padding ?? "0 20px 12px"};
`;

export const ModalBorderFooter = styled(Flex)<{ padding: string }>`
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.grey02};
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-between;
  letter-spacing: 0.3px;
  margin: -8px -20px 0;
  padding: ${props => props.padding ?? "0 20px 12px"};
`;

export const ModalBody = styled(Flex)`
  flex-direction: column;
  padding: 30px 70px;
  color: #646679;
  font-weight: 400;
  line-height: 20px;
  font-size: 16px;
  max-height: 72vh;
  overflow: auto;
`;

export const modalStyles = (
  maxWidth: string = "550px",
  style?: {
    contentStyle?: React.CSSProperties;
    overlayStyle?: React.CSSProperties;
  }
): ReactModal.Styles => {
  const defaultOvarlayStyle: React.CSSProperties = {
    background: "rgba(0,0,0,0.25)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    //overflowY: 'visible',
    overflowX: "hidden",
    zIndex: 101,
    padding: "15px 20px 15px 20px"
  };

  const defaultContentStyle = {
    overflow: "auto",
    maxHeight: "95vh",
    background: "#F7F8F9",
    backgroundClip: "padding-box",
    borderRadius: "16px",
    border: "1px",
    borderColor: "#EAEBEB",
    width: "100%",
    maxWidth: `${maxWidth}`,
    top: "auto",
    bottom: "auto",
    left: "auto",
    right: "auto",
    backgroundColor: "white",
    boxShadow:
      "0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22)",
    padding: "20px"
  };
  return {
    // dark background behind all modals
    overlay: {
      ...defaultOvarlayStyle,
      ...style?.overlayStyle
    },
    // modal root
    content: {
      ...defaultContentStyle,
      ...style?.contentStyle
    }
  };
};
