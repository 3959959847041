import React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { NavLink } from "react-router-dom";
import { Redirect, Route, Switch } from "react-router";
import ReactLoading from "react-loading";

import * as accountActions from "../../state/actions/AccountActions";
import config from "../../config";
import Auth from "../../utils/Auth";

import AccountBillingPage from "./AccountBillingPage";
import AccountCompanyProfilePage from "./AccountCompanyProfilePage";
import AccountSocialPage from "./social/AccountSocialPage";
import AccountMarketingPlan from "./plan/AccountMarketingPlanPage";
import AccountAchievementsPage from "./AccountAchievementsPage";

class AccountContainer extends React.Component {
  state = { generatingNonce: false };

  componentDidMount() {
    if (this.props.match.params.accountId !== "new") {
      this.props.fetchAccount(this.props.match.params.accountId);
    }
  }

  handleDashboardClick = async () => {
    //for safari we need to create the window at this level, otherwise it get's blocked
    const win = window.open();

    this.setState({ generatingNonce: true });
    try {
      const nonce = await Auth.generateNonce(this.props.currentUser.id);
      this.setState({ generatingNonce: false });
      win.location.href = `${this.props.account.isTelenetCustomer ? config.telenetUrl : config.frontendUrl}/goto/dashboard/${nonce}/${this.props.account.id}`;
      win.focus();
    } catch (err) {
      toastr.error(err.message);
    }
  };

  render() {
    const { account, match } = this.props;
    if (!account || account.id !== this.props.match.params.accountId) {
      return null;
    }

    return (
      <div>
        <div className="m-subheader ">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h3 className="m-subheader__title ">Account profile</h3>
            </div>
          </div>
        </div>
        <div className="m-content">
          <div className="row">
            <div className="col-xl-3 col-lg-4">
              <div className="m-portlet m-portlet--full-height  ">
                <div className="m-portlet__body">
                  <div className="m-card-profile">
                    <div className="m-card-profile__title m--hide">
                      Your Profile
                    </div>
                    {this.props.account.logo && (
                      <div className="m-card-profile__pic">
                        <div className="m-card-profile__pic-wrapper">
                          <img
                            src={this.props.account.logo}
                            alt={this.props.account.companyName}
                            width="130px"
                          />
                        </div>
                      </div>
                    )}

                    <div className="m-card-profile__details">
                      <span className="m-card-profile__name">
                        {this.props.account.companyName}
                      </span>
                      <a
                        href={`mailto:${this.props.account.email}`}
                        className="m-card-profile__email m-link"
                      >
                        {this.props.account.email}
                      </a>
                    </div>
                  </div>
                  <ul className="m-nav m-nav--hover-bg m-portlet-fit--sides">
                    <li className="m-nav__separator m-nav__separator--fit" />
                    <li className="m-nav__section m--hide">
                      <span className="m-nav__section-text">Section</span>
                    </li>
                    <li className="m-nav__item">
                      <NavLink
                        to={`${match.url}/profile`}
                        className="m-nav__link"
                      >
                        <i className="m-nav__link-icon flaticon-profile-1" />
                        <span className="m-nav__link-text">
                          Company Profile
                        </span>
                      </NavLink>
                    </li>
                    <li className="m-nav__item">
                      <NavLink
                        to={`${match.url}/billing`}
                        className="m-nav__link"
                      >
                        <i className="m-nav__link-icon flaticon-coins" />
                        <span className="m-nav__link-text">Billing</span>
                      </NavLink>
                    </li>
                    <li className="m-nav__item">
                      <NavLink
                        to={`/accounts/${this.props.account.id}/plan`}
                        className="m-nav__link"
                        activeClassName="active"
                      >
                        <i className="m-nav__link-icon flaticon-paper-plane" />
                        <span className="m-nav__link-text">Marketing Plan</span>
                      </NavLink>
                    </li>
                    <li className="m-nav__item">
                      <NavLink
                        to={`/accounts/${this.props.account.id}/social`}
                        className="m-nav__link"
                        activeClassName="active"
                      >
                        <i className="m-nav__link-icon flaticon-chat-1" />
                        <span className="m-nav__link-text">Social Media</span>
                      </NavLink>
                    </li>
                    <li className="m-nav__item">
                      <NavLink
                        to={`${match.url}/achievements`}
                        className="m-nav__link"
                      >
                        <i className="m-nav__link-icon flaticon-confetti" />
                        <span className="m-nav__link-text">Achievements</span>
                      </NavLink>
                    </li>
                    <li className="m-nav__separator m-nav__separator--fit" />
                    <li className="m-nav__item">
                      <div
                        onClick={this.handleDashboardClick}
                        className="m-nav__link"
                        style={{ cursor: "pointer" }}
                      >
                        <i className="m-nav__link-icon flaticon-user" />
                        <span className="m-nav__link-text">Willow Home</span>
                        {this.state.generatingNonce && (
                          <ReactLoading
                            width={10}
                            height={10}
                            type="spin"
                            color="#333"
                          />
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-8">
              <Switch>
                <Route
                  path={match.url + "/profile"}
                  component={AccountCompanyProfilePage}
                />
                <Route
                  path={match.url + "/billing"}
                  component={AccountBillingPage}
                />
                <Route
                  path={match.url + "/plan"}
                  component={AccountMarketingPlan}
                />
                <Route
                  path={match.url + "/social"}
                  component={AccountSocialPage}
                />
                <Route
                  path={match.url + "/achievements"}
                  component={AccountAchievementsPage}
                />
                <Redirect to={match.url + "/profile"} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    account: state.accounts.selectedAccount,
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, {
  fetchAccount: accountActions.fetchAccount,
  updateAccount: accountActions.updateAccount,
  deleteAccount: accountActions.deleteAccount
})(AccountContainer);
