import React, { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CreateAccountModalFooter from "./CreateAccountModalFooter";
import { Stages } from "./CreateAccountModal";
import { LinkAlt as Link } from "@styled-icons/boxicons-regular/LinkAlt";
import { toastr } from "react-redux-toastr";

import { ModalBody } from "../styles";
import { CustomerInfoFormInputs } from "./AccountDetailsForm";
import { QuoteDetailsFormInputs } from "./QuoteDetailsForm";
import {
  StyledInputFieldset,
  StyledInputLabel
} from "components/common/form/styles";
import { callApi } from "utils/ContentoApi";
import ReactLoading from "react-loading";
import { Flex } from "rebass";
import Icon from "components/common/Icon";
import { generatePassword } from "utils/Password";
import { getAccountPackages } from "./PackageSelector";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";

type ReviewAccountDetailsProps = {
  setCurrentStage: (newStage: Stages) => void;
  customerDetails: CustomerInfoFormInputs;
  quoteDetails: QuoteDetailsFormInputs;
  forExistingUser: boolean;
};

type CreateAccountAPIResponse = {
  url: string;
};

const ReviewAccountDetails = ({
  setCurrentStage,
  customerDetails,
  quoteDetails,
  forExistingUser
}: ReviewAccountDetailsProps) => {
  const dispatch = useDispatch();
  const currentUser = useSelector<any, any>(state => state.auth.currentUser);
  const [isLoading, setIsLoading] = useState(false);
  const [quote, setQuote] = useState<CreateAccountAPIResponse | undefined>();

  const addonsStrings = useMemo(
    () =>
      quoteDetails.addons?.map(a =>
        typeof a.value === "string" ? a.value : a.value.plan
      ) as string[],
    [quoteDetails.addons]
  );

  const handleCreateQuote = async () => {
    let payload: any = {
      companyName: customerDetails.companyName,
      companyWebsite: customerDetails.website,
      accountManagerId: customerDetails.accountManager.id,
      accountExecutiveId: quoteDetails.accountExecutive.id,
      message: quoteDetails.message,
      plan: quoteDetails.package.value.plan,
      addons: addonsStrings
        ? addonsStrings
        : quoteDetails.package.value.coachingAddOn
          ? [quoteDetails.package.value.coachingAddOn]
          : []
    };

    if (forExistingUser) {
      payload = {
        ...payload,
        willowUserId: customerDetails.willowUser.id
      };
    } else {
      payload = {
        ...payload,
        email: customerDetails.email,
        userFirstName: customerDetails.userFirstName,
        userLastName: customerDetails.userLastName,
        password: generatePassword(6, 4, 2)
      };
    }

    /** The backend provides different endpoints depending on if the account is for a new or existing user
     * Those endpoints accept slightly different fields but return the same result
     */
    const url = forExistingUser
      ? "/admin/business-account-existing-user"
      : "/admin/business-account-new-user";

    setIsLoading(true);
    await callApi({
      method: "post",
      url: url,
      data: payload
    })
      .then(res => {
        toastr.success(`Quote Created Successfully`, "");
        const {
          subscription: { subscription_items: items },
          quote: { billing_address: billingAddress }
        } = res;
        const plan = items.find((item: any) => item.item_type === "plan");
        const isCoaching = !!items.find(
          (item: any) => item.item_type === "addon"
        );
        dispatch(
          trackAnalyticsEvent("Quote Created", {
            packageType: isCoaching ? "Coaching" : "Tool Only",
            packageLength: [
              ...getAccountPackages("USD"),
              ...getAccountPackages("EUR")
            ]
              .find(pkg => pkg.value.plan === plan.item_price_id)!
              .value.duration.toLocaleLowerCase()
              .includes("monthly")
              ? "1 month"
              : "12 months",
            createdBy: currentUser?.fullName,
            accountType: forExistingUser ? "Existing" : "New",
            accountName: billingAddress?.company
          })
        );
        setQuote(res);
      })
      .catch(err => {
        toastr.error("API Error", err.message);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <ModalBody>
        <DisplayData label="Company Name" text={customerDetails.companyName} />
        <DisplayData label="Website URL" text={customerDetails.website} />
        {/** When creating the account for a new user, display the customer first name, last name and email */}
        {!forExistingUser && (
          <>
            <DisplayData
              label="Customer First Name"
              text={customerDetails.userFirstName}
            />

            <DisplayData
              label="Customer Last Name"
              text={customerDetails.userLastName}
            />

            <DisplayData label="Email" text={customerDetails.email} />
          </>
        )}
        {/** When creating the account for an existing user, display the user for which the account is being created */}
        {forExistingUser && (
          <DisplayData
            label="Willow User"
            text={customerDetails.willowUser.fullName}
          />
        )}
        <DisplayData
          label="Account Manager"
          text={customerDetails.accountManager.fullName}
        />
        <DisplayData
          label="Account Executive"
          text={quoteDetails.accountExecutive.fullName}
        />
        <DisplayData label="Quote Details" text={quoteDetails.message ?? ""} />
        <DisplayData
          label="Package Type & Terms"
          text={`${quoteDetails.package.value.title} - ${quoteDetails.package.value.duration}`}
        />
        <DisplayData
          label="Addons selected"
          text={`${
            addonsStrings.length ? addonsStrings.join(", ") : "0 selected"
          }`}
        />

        {isLoading && <Loader />}
        {quote && <QuoteBox url={quote.url} />}
      </ModalBody>

      <CreateAccountModalFooter
        currentStage={Stages.REVIEW}
        setCurrentStage={setCurrentStage}
        actionButtonText="Create Quote"
        disableActionButton={isLoading || !!quote} // disable action button when loading and when quote has been created
        showBackButton={!quote} // once quote has been created, hide back button
        onSubmit={handleCreateQuote}
      />
    </>
  );
};

type DisplayDataProps = {
  label: string;
  text: string;
};

const DisplayData = ({ label, text }: DisplayDataProps) => {
  return (
    <StyledInputFieldset>
      <StyledInputLabel>{label}</StyledInputLabel>
      <span style={{ color: "#858585" }}>{text}</span>
    </StyledInputFieldset>
  );
};

const Loader = () => (
  <Flex
    alignItems={"center"}
    justifyContent={"center"}
    flexDirection={"column"}
    backgroundColor={"#F2F2F2"}
  >
    <ReactLoading color="#5867dd" type="bubbles" height={50} />
    <p>Creating Quote...</p>
  </Flex>
);

const QuoteBox = ({ url }: { url: string }) => {
  return (
    <Flex
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
      backgroundColor={"#F2F2F2"}
      padding={"17px 0 21px"}
    >
      <p>Quote Created</p>
      <Flex style={{ gap: "9px" }}>
        <Icon component={Link} size={20} />
        <a href={url} style={{ textDecoration: "underline" }}>
          {url}
        </a>
      </Flex>
    </Flex>
  );
};

export default ReviewAccountDetails;
