import React from "react";
import Icon from "components/common/Icon";
import Button from "components/common/Button";
import { TooltipInfo } from "components/common/styles";
import { Globe2 as Globe } from "@styled-icons/bootstrap/Globe2";
import { Flex } from "rebass/styled-components";
import {
  EmptyContainer,
  TitleContainer,
  SourcesTitle,
  ButtonContainer,
  ContentContainer,
  PaddingDeleter
} from "./styles/AddSource.styled";

interface AddSourceProps {
  onNewSourceClick?: () => void;
  refreshSearch?: () => void;
  onDeleteSourcesWithZeroArticles?: () => void;
}

const AddSource: React.FC<AddSourceProps> = ({
  onNewSourceClick,
  refreshSearch,
  onDeleteSourcesWithZeroArticles
}) => {
  const handleNewSourceClick = () => {
    if (onNewSourceClick) {
      onNewSourceClick();
    }
  };

  const handleRefreshClick = () => {
    if (refreshSearch) {
      refreshSearch();
    }
  };

  const handleDeleteSourcesClick = () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete all sources with 0 articles?"
    );
    if (confirmed && onDeleteSourcesWithZeroArticles) {
      onDeleteSourcesWithZeroArticles();
    }
  };

  return (
    <PaddingDeleter>
      <EmptyContainer>
        <ContentContainer>
          <TitleContainer>
            <Icon component={Globe} size={20} />
            <SourcesTitle>Sources</SourcesTitle>
          </TitleContainer>
          <ButtonContainer>
            <Button onClick={handleNewSourceClick} data-tip={"New Source"}>
              <Flex style={{ gap: "8px", alignItems: "center" }}>
                Add New Source
              </Flex>
            </Button>
            <Button
              onClick={handleRefreshClick}
              background="#6074801F"
              textColor="#1C1E1F"
              data-tip={"Refresh"}
            >
              Refresh All Sources
            </Button>
            <Button
              onClick={handleDeleteSourcesClick}
              background="#FF4D4F1F"
              textColor="#FF4D4F"
              data-tip={"Delete Sources"}
            >
              Delete Sources with 0 Articles
            </Button>
            <TooltipInfo place={"bottom"} />
          </ButtonContainer>
        </ContentContainer>
      </EmptyContainer>
    </PaddingDeleter>
  );
};

export default AddSource;
