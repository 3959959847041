import styled from "styled-components";
import { Flex } from "rebass/styled-components";

export const MainContainer = styled(Flex)`
  min-height: 460px;
  max-height: 70vh;
  padding: 34px 10px;
  margin: 0px 70px 50px 0px;
  background-color: white;
  border-radius: 10px;

  flex-direction: row;
  gap: 12px;

  @media only screen and (max-width: 1050px) {
    margin: 0 20px 30px;
    flex-direction: column;
    height: 560px;
    overflow: scroll;
  }
`;

export const LegendContainer = styled(Flex)`
  flex: 0 0 45%;
  flex-direction: column;
  padding: 24px 10px;
`;

export const CalendarTableContainer = styled(Flex)`
  flex-direction: column;
  padding: 10px;
  flex: 0 0 55%;
  gap: 23px;
`;

export const TableContainer = styled.div`
  display: inline-block;
  overflow-y: scroll;
`;

export const Table = styled.table`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  width: 100%;
`;

export const TableHeadRow = styled.tr`
  background-color: #e6efff;

  th {
    padding: 10px;
  }

  th:first-child {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }

  th:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
`;

export const TableBody = styled.tbody`
  overflow-y: scroll;
`;

export const TableRow = styled.tr`
  margin: 10px 0px;
  td {
    padding: 10px;
  }
`;
