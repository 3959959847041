export const FETCH_SUGGESTED_POSTS = "@@suggestedPost/FETCH_SUGGESTED_POSTS";
export const SUGGESTED_POSTS_FETCHED =
  "@@suggestedPost/SUGGESTED_POSTS_FETCHED";
export const DELETE_SUGGESTED_POST = "@@suggestedPost/DELETE_SUGGESTED_POST";

export const fetchSuggestedPosts = () => {
  return {
    type: FETCH_SUGGESTED_POSTS
  };
};

export const suggestedPostsFetched = suggestedPosts => {
  return {
    type: SUGGESTED_POSTS_FETCHED,
    suggestedPosts
  };
};

export const deleteSuggestedPost = (accountId, postId) => {
  return {
    type: DELETE_SUGGESTED_POST,
    accountId,
    postId
  };
};
