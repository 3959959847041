// Based on https://stackoverflow.com/a/52749807

import React, { Component } from "react";
import { Image, Box } from "rebass";

export default class FallbackImage extends Component {
  state = {
    errored: false
  };

  onError = () => {
    //Only mark as errored once
    if (!this.state.errored) {
      this.setState({
        errored: true
      });
    }
  };

  render() {
    const { src, fallbackSrc, ...props } = this.props;
    const imgSrc = this.state.errored || !src ? fallbackSrc : src;
    if (!imgSrc) {
      return <Box {...props} />;
    }
    return <Image src={imgSrc} onError={this.onError} {...props} />;
  }
}
