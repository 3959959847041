import { takeLatest, takeEvery, put, select } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import * as postActions from "../actions/PostActions";
import * as scheduledPostActions from "../actions/ScheduledPostActions";

import { callApi } from "../../utils/ContentoApi";
import qs from "qs";

function* fetchPosts({ list, channels }) {
  const selectedAccount = yield select(getSelectedAccount);

  const posts = yield callApi({
    method: "get",
    url: `/accounts/${selectedAccount.id}/${list}-posts${qs.stringify(
      { channels: channels },
      { addQueryPrefix: true }
    )}`
  });
  yield put(postActions.postsFetched(posts, list));
}

function* fetchPost(action) {
  const post = yield callApi({
    method: "get",
    url: `/posts/${action.postId}`
  });
  yield put(postActions.postFetched(post));
}

const getSelectedAccount = state => {
  return state.accounts.selectedAccount;
};

function* createPost(action) {
  try {
    const selectedAccount = yield select(getSelectedAccount);
    if (!selectedAccount) {
      toastr.error(`Select an account before creating a post.`);
      return;
    }
    const createdPost = yield callApi({
      method: "post",
      url: `posts`,
      data: {
        ...action.values,
        accountId: selectedAccount ? selectedAccount.id : null
      }
    });
    toastr.success("Successfully created post.");
    yield put(postActions.postCreated(createdPost));
    //refresh scheduled or suggested
    yield put(scheduledPostActions.fetchScheduledPosts());
  } catch (error) {
    console.error(error);
    toastr.error(`Failed creating post. ${error.message}`);
    yield put(postActions.createPostError(error));
  }
}

function* updatePost(action) {
  try {
    const updatedPost = yield callApi({
      method: "put",
      url: `posts/${action.postId}`,
      data: { ...action.values }
    });
    toastr.success("Successfully updated post.");
    yield put(postActions.postUpdated(updatedPost));
  } catch (error) {
    console.error(error);
    toastr.error(`Failed updating post. ${error.message}`);
    yield put(postActions.updatePostError(error));
  }
}

function* deletePost(action) {
  try {
    yield callApi({
      method: "delete",
      url: `posts/${action.postId}`
    });
    toastr.success("Successfully deleted post.");
    yield put(postActions.fetchPosts());
  } catch (error) {
    console.error(error);
    toastr.error("Error deleting post", error.message);
  }
}

export default function* root() {
  yield takeEvery(postActions.FETCH_POSTS, fetchPosts);
  yield takeEvery(postActions.FETCH_POST, fetchPost);

  yield takeEvery(postActions.CREATE_POST, createPost);
  yield takeLatest(postActions.UPDATE_POST, updatePost);
  yield takeLatest(postActions.DELETE_POST, deletePost);
}
