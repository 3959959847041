import React, { Component } from "react";

import { connect } from "react-redux";
import * as accountActions from "../../state/actions/AccountActions";
import Portlet from "../../components/common/portlet/Portlet";
import * as PropTypes from "prop-types";
import { callApi } from "../../utils/ContentoApi";
import { toastr } from "react-redux-toastr";
import UserForm from "../../components/users/UserForm";

class UserDetailPage extends Component {
  state = { user: null };

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser() {
    callApi({
      method: "get",
      url: `/users/${this.props.match.params.userId}`
    })
      .then(user => {
        this.setState({ user: user });
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({ user: null });
      });
  }

  updateUser = values => {
    callApi({
      method: "put",
      url: `/users/${this.props.match.params.userId}`,
      data: {
        ...values,
        accounts: values.accounts
          ? values.accounts.map(account => account.id)
          : null
      }
    })
      .then(user => {
        toastr.success("Updated user");
        this.setState({ user: user });
      })
      .catch(error => {
        toastr.error(error.message);
        //this.setState({ user: null });
      });
  };

  render() {
    let { user } = this.state;

    if (!user) {
      return <p>Loading...</p>;
    }

    return (
      <Portlet title="">
        <UserForm
          onSubmit={values => this.updateUser(values)}
          initialValues={user}
        />
      </Portlet>
    );
  }
}

UserDetailPage.propTypes = { updateAccount: PropTypes.any };

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps, {
  updateAccount: accountActions.updateAccount
})(UserDetailPage);
