import React from "react";
import Switch from "react-switch";
import { connect } from "react-redux";
import * as accountActions from "../../../state/actions/AccountActions";

class MarketingPlanAutoPilotPage extends React.Component {
  render() {
    const { account, updateAccount } = this.props;
    return (
      <div className="m-portlet__body">
        <p>
          When no posts are scheduled for the next 24 hours, they will
          automatically be scheduled from the suggestions.{" "}
        </p>
        <Switch
          checked={account.autoPilot}
          onChange={value => {
            updateAccount(account.id, { autoPilot: value });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    account: state.accounts.selectedAccount
  };
};

export default connect(mapStateToProps, {
  updateAccount: accountActions.updateAccount
})(MarketingPlanAutoPilotPage);
