import React from "react";
import classNames from "classnames";
import ImageUpload from "../../attachment/ImageUpload";

const ImageInput = ({
  input,
  label,
  meta: { touched, error, warning },
  fullWidth,
  prefix
}) => {
  const groupClasses = classNames({
    "form-group m-form__group row": true,
    "has-danger": touched && error,
    "has-warning": touched && warning
  });

  const inputWrapperClasses = classNames({
    "col-7": !fullWidth,
    "col-10": fullWidth
  });

  return (
    <div className={groupClasses}>
      <label className="col-2 col-form-label">{label}</label>
      <div className={inputWrapperClasses}>
        <div className="input-group">
          {prefix && (
            <div className="input-group-prepend">
              <span className="input-group-text">{prefix}</span>
            </div>
          )}
          <ImageUpload {...input} />
          {input.value && (
            <img alt="" src={input.value} width={100} height={100} />
          )}
        </div>
        {error && touched && (
          <div className="form-control-feedback">{error}</div>
        )}
      </div>
    </div>
  );
};

export default ImageInput;
