import React from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { Flex } from "rebass";
import { closeModal } from "../../../state/actions/ModalActions";
import { modalStyles } from "../../modals/styles";
import { ArticleContentWrapper } from "./styles";

class SearchCheatSheetModal extends React.Component {
  render() {
    const { closeModal } = this.props;
    return (
      <Modal
        style={modalStyles("60vw")}
        isOpen={true}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <Flex flexDirection="column">
          <h3>Search Cheat Sheet</h3>
          <br />
          <ArticleContentWrapper>
            <h5>Search by exact word match "happy" on all fields:</h5>
            <p>happy</p>
            <h5>Search by partial word match on all fields:</h5>
            <p>*happ*</p>
            <h5>Search by exact domain:</h5>
            <p>domain:cnbc.com</p>
            <h5>Search by domain that starts with "money":</h5>
            <p>domain:money*</p>
            <h5>Search by domain that have "money" anywhere:</h5>
            <p>domain:*money*</p>
            <h5>Search by titles that contains "happy":</h5>
            <p>title:*happy*</p>
            <h5>Search by article without an exact term:</h5>
            <p>NOT trump</p>
            <h5>Search by article without a partial term:</h5>
            <p>NOT *covi*</p>
            <h5>Combine searches:</h5>
            <p>domain:cnbc.com AND title:*happy*</p>
            <h5>Combine searches with negation:</h5>
            <p>domain:cnbc.com AND NOT title:*happy*</p>
          </ArticleContentWrapper>
        </Flex>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  closeModal: closeModal
})(SearchCheatSheetModal);
