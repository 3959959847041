import React from "react";
import { connect } from "react-redux";
import * as accountActions from "../../state/actions/AccountActions";
import Portlet from "../../components/common/portlet/Portlet";
import { Link } from "react-router-dom";
import { pick, values } from "lodash";
import { callApi } from "../../utils/ContentoApi";
import { toastr } from "react-redux-toastr";
import FormattedDate from "../../components/common/FormattedDate";
import Auth from "../../utils/Auth";
import config from "../../config";
import moment from "moment";

class UserOverviewPage extends React.Component {
  state = { users: [], q: "" };

  componentDidMount() {
    this.refreshUsers();
  }

  refreshUsers() {
    callApi({
      method: "get",
      url: `/users?q=${this.state.q}`
    })
      .then(users => {
        this.setState({ users: users });
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({ users: [] });
      });
  }

  handleChangeQuery(event) {
    this.setState({ q: event.target.value });
  }

  handleQueryKeyPress(e) {
    if (e.key === "Enter") {
      this.refreshUsers();
    }
  }

  handleLoginClick = async userId => {
    //safari needs to open window as first step
    const win = window.open();

    try {
      const nonce = await Auth.generateNonce(userId);
      win.location.href = `${config.frontendUrl}/goto/home/${nonce}`;
      win.focus();
    } catch (err) {
      toastr.error(err.message);
    }
  };

  render() {
    return (
      <div>
        <div className="m-subheader ">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h3 className="m-subheader__title ">Users</h3>
            </div>
          </div>
        </div>

        <div className="m-content">
          <Portlet>
            <div className="form-group m-form__group row align-items-center">
              <div className="col-md-4">
                <div>
                  <div className="m-input-icon m-input-icon--left">
                    <input
                      name="q"
                      value={this.state.q}
                      onChange={this.handleChangeQuery.bind(this)}
                      onKeyPress={this.handleQueryKeyPress.bind(this)}
                      type="text"
                      className="form-control m-input"
                      placeholder="Search"
                    />
                    <span className="m-input-icon__icon m-input-icon__icon--left">
                      <span>
                        <i className="fa fa-search"></i>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <table className="table m-table m-table--head-bg-success">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Created</th>
                  <th>Last Login</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {!!this.state.users &&
                  this.state.users.map(user => (
                    <tr key={user.id}>
                      <td>{user.fullName}</td>
                      <td>
                        <Link to={`/users/${user.id}`}>{user.email}</Link>
                      </td>
                      <td>{user.roles.join(", ")}</td>
                      <td>
                        <FormattedDate date={user.createdAt} />
                      </td>
                      <td>
                        {user.lastTokenRefresh
                          ? moment(user.lastTokenRefresh).fromNow()
                          : "N/A"}
                      </td>
                      <td>
                        <Link
                          to={`/users/${user.id}`}
                          style={{ marginRight: 20 }}
                        >
                          Edit
                        </Link>
                        <Link
                          to={`/users`}
                          onClick={() => this.handleLoginClick(user.id)}
                        >
                          Login
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Portlet>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    accounts: values(pick(state.accounts.data, state.accounts.all))
  };
};

export default connect(mapStateToProps, {
  fetchAccounts: accountActions.fetchAccounts
})(UserOverviewPage);
