import React, { useState, useEffect } from "react";
import { callApi } from "../../utils/ContentoApi";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import Icon from "components/common/Icon";
import { Copy } from "@styled-icons/boxicons-regular/Copy";
import qs from "qs";
import { CopyButton } from "components/common/CopyButton";
import {
  EmptyContainer,
  ContentContainer
} from "components/common/AdminHeader";
import {
  Table,
  TableRow,
  TableCell,
  TableHeader
} from "components/common/Table";
import Pagination from "components/operations/Pagination";

const truncateText = (text: string, wordLimit: number): string => {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
};

interface Post {
  id: string;
  caption: string;
  scheduledAt: string;
  status: string;
  accountName: string;
  accountLogo: string;
  channel: {
    id: string;
    accountId: string;
    username: string;
    name: string;
    service: string;
    timezone: string;
    needsReconnection: boolean;
  };
  delayedBy?: string;
  type?: string;
  test?: string;
  attachment?: {
    url?: string;
  };
}

interface ErrorDetails {
  errorType: string;
  channelId: string;
  action: string;
  channelServiceType: string;
  date: string;
}

interface OperationsTableProps {
  selectedTypeFilters: string[];
  selectedTimeFilter: string;
  searchTerm: string;
}

const OperationsTable: React.FC<OperationsTableProps> = ({
  selectedTypeFilters,
  selectedTimeFilter,
  searchTerm
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [allData, setAllData] = useState<Post[]>([]);
  const [errorCodes, setErrorCodes] = useState<{ [key: string]: ErrorDetails }>(
    {}
  );
  const itemsPerPage = 10;

  useEffect(() => {
    callApi({
      method: "get",
      url: `/posts${qs.stringify(
        {
          status: "SCHEDULED",
          channelActive: true,
          toDate: moment().toISOString()
        },
        { addQueryPrefix: true }
      )}`
    })
      .then(response => {
        const transformedData = (Array.isArray(response) ? response : [])
          .map(post => ({
            ...post,
            channel: {
              ...post.channel,
              service:
                post.channel.service === "twitter" ? "X" : post.channel.service
            }
          }))
          .sort((a, b) => moment(b.scheduledAt).diff(moment(a.scheduledAt)));

        setAllData(transformedData);

        const channelIds = transformedData.map(post => post.channel.id);

        // Fetch error codes for all channels in a single call
        callApi({
          method: "get",
          url: `/channels/errors${qs.stringify(
            {
              channelIds: channelIds.slice(
                itemsPerPage * currentPage - 1,
                itemsPerPage
              )
            },
            { addQueryPrefix: true, arrayFormat: "brackets" }
          )}`
        })
          .then(errorResponse => {
            if (errorResponse && errorResponse.errors) {
              setErrorCodes(errorResponse.errors);
            } else {
              console.error("Invalid error response format:", errorResponse);
            }
          })
          .catch(error => {
            console.error("Error fetching error codes:", error);
          });
      })
      .catch(error => {
        console.error("Error fetching posts:", error);
        toastr.error("Error", error.message);
      });
  }, []);

  const filterData = () => {
    let filtered = allData;

    if (!selectedTypeFilters.includes("All")) {
      filtered = filtered.filter(post =>
        selectedTypeFilters.includes(post.channel.service)
      );
    }

    const now = moment();

    if (selectedTimeFilter === "24hours") {
      filtered = filtered.filter(post =>
        moment(post.scheduledAt).isAfter(now.subtract(24, "hours"))
      );
    } else if (selectedTimeFilter === "lastweek") {
      filtered = filtered.filter(post =>
        moment(post.scheduledAt).isAfter(now.subtract(7, "days"))
      );
    } else if (selectedTimeFilter === "olderthan30days") {
      filtered = filtered.filter(post =>
        moment(post.scheduledAt).isBefore(now.subtract(30, "days"))
      );
    }

    if (searchTerm) {
      filtered = filtered.filter(post =>
        post.accountName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    filtered = filtered.filter(post => {
      const scheduledTime = moment(post.scheduledAt);
      const now = moment();
      const duration = moment.duration(now.diff(scheduledTime));
      const hoursDelayed = duration.asHours();
      return hoursDelayed >= 6;
    });

    return filtered;
  };

  const calculateDelay = (scheduledAt: string) => {
    const now = moment();
    const scheduledTime = moment(scheduledAt);
    const duration = moment.duration(now.diff(scheduledTime));
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    return `${days}d ${hours}h ${minutes}m`;
  };

  const filteredData = filterData();
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const copyLink = (post: Post) => {
    const postLink = post.attachment?.url || `${post.id}`;
    navigator.clipboard
      .writeText(postLink)
      .then(() => {
        toastr.success(
          "Link Copied",
          "The link has been copied to your clipboard"
        );
      })
      .catch(err => {
        toastr.error("Error", "Failed to copy the link");
      });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedTypeFilters, selectedTimeFilter, searchTerm]);

  return (
    <>
      <EmptyContainer>
        <ContentContainer>
          <Table>
            <thead>
              <TableRow>
                <TableHeader>Account</TableHeader>
                <TableHeader>Type</TableHeader>
                <TableHeader>Channel</TableHeader>
                <TableHeader style={{ textAlign: "left" }}>
                  Post Text
                </TableHeader>
                <TableHeader>Error Code</TableHeader>
                <TableHeader>Scheduled For</TableHeader>
                <TableHeader>Delayed By</TableHeader>
                <TableHeader>Actions</TableHeader>
              </TableRow>
            </thead>
            <tbody>
              {currentItems.map(post => (
                <TableRow key={post.id}>
                  <TableCell>
                    <img
                      src={post.accountLogo}
                      alt={post.accountName}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "8px"
                      }}
                    />
                    {post.accountName || "Unknown Account"}
                  </TableCell>
                  <TableCell>
                    {post.channel.service.charAt(0).toUpperCase() +
                      post.channel.service.slice(1) || "Unknown Type"}
                  </TableCell>
                  <TableCell>
                    {post.channel.username || "Unknown Channel"}
                  </TableCell>
                  <TableCell className="truncate" style={{ textAlign: "left" }}>
                    {truncateText(post.caption || "Unknown", 6)}
                  </TableCell>
                  <TableCell>
                    {errorCodes[post.channel.id]?.errorType || "N/A"}
                  </TableCell>
                  <TableCell>
                    {moment(post.scheduledAt).format("DD/MM/YYYY HH:mm")}
                  </TableCell>
                  <TableCell>{calculateDelay(post.scheduledAt)}</TableCell>
                  <TableCell>
                    <CopyButton onClick={() => copyLink(post)}>
                      <Icon
                        component={Copy}
                        size={20}
                        style={{ marginRight: "8px" }}
                      />
                      Copy Link
                    </CopyButton>
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </ContentContainer>
      </EmptyContainer>
      <EmptyContainer>
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={filteredData.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </EmptyContainer>
    </>
  );
};

export default OperationsTable;
