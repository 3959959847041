export const USER_INFO_REQUESTED = "@@auth/USER_INFO_REQUESTED";
export const USER_INFO_FETCHED = "@@auth/USER_INFO_FETCHED";
export const RENEW_TOKEN = "@@auth/RENEW_TOKEN";
export const TOKEN_RENEWED = "@@auth/TOKEN_RENEWED";
export const AUTHENTICATION_REQUIRED = "@@auth/AUTHENTICATION_REQUIRED";
export const LOGOUT = "@@auth/LOGOUT";
export const LOGIN = "@@auth/LOGIN";
export const LOGIN_FAILED = "@@auth/LOGIN_FAILED";

export const login = credentials => {
  return {
    type: LOGIN,
    credentials
  };
};

export const logout = () => {
  return {
    type: LOGOUT
  };
};

export const renewToken = () => {
  return {
    type: RENEW_TOKEN
  };
};

export const tokenRenewed = () => {
  return {
    type: TOKEN_RENEWED
  };
};

export const userInfoRequested = () => {
  return {
    type: USER_INFO_REQUESTED
  };
};

export const userInfoFetched = userInfo => {
  return {
    type: USER_INFO_FETCHED,
    userInfo
  };
};

export const loginFailed = () => {
  return {
    type: LOGIN_FAILED
  };
};

export const authenticationRequired = () => {
  return {
    type: AUTHENTICATION_REQUIRED
  };
};
