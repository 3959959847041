import React from "react";
import {
  Menu,
  Option
} from "components/modals/CreateAccountModal/PackageSelector";
import { theme } from "theme";
import {
  FieldValues,
  UseControllerProps,
  useController
} from "react-hook-form";
import Select, { GroupBase, SelectInstance, StylesConfig } from "react-select";
import { Flex } from "rebass/styled-components";
import {
  StyledInputFieldset,
  StyledInputLabel
} from "components/common/form/styles";

export interface AddonSelectorProps<T extends FieldValues>
  extends UseControllerProps<T>,
    React.CSSProperties {
  label?: string;
  addons?: IAddonOption[];
}

enum AvailableAddonCharges {
  WILLOW_TRAILER_EUR = "Willow-Trailer-EUR",
  WILLOW_TRAILER_USD = "Willow-Trailer-USD",
  WILLOW_CHILL_EUR = "Willow-Chill-EUR",
  WILLOW_CHILL_USD = "Willow-Chill-USD",
  WILLOW_BING_WATCH_EUR = "Willow-Binge-Watch-EUR",
  WILLOW_BING_WATCH_USD = "Willow-Binge-Watch-USD",
  WILLOW_TEMPLATE_DESIGN_EUR = "Template-Design-EUR",
  WILLOW_TEMPLATE_DESIGN_USD = "Template-Design-USD",
  WILLOW_LONG_POST_EUR = "X-ThreadLong-Post-EUR",
  WILLOW_LONG_POST_USD = "X-ThreadLong-Post-USD",
  WILLOW_LINKEDIN_CAROUSEL_EUR = "LinkedIn-Carousel-EUR",
  WILLOW_LINKEDIN_CAROUSEL_USD = "LinkedIn-Carousel-USD",
  WILLOW_POST_SCHEDULING_EUR = "Post-Scheduling-EUR",
  WILLOW_POST_SCHEDULING_USD = "Post-Scheduling-USD"
}

const AvailableAddonLabels = [
  { value: "Willow-Trailer-EUR", name: "Willow Trailer" },
  { value: "Willow-Chill-EUR", name: "Willow Chill" },
  { value: "Willow-Binge-Watch-EUR", name: "Willow Binge Watch " },
  { value: "Template-Design-EUR", name: "Template Design" },
  { value: "X-ThreadLong-Post-EUR", name: "X ThreadLong Post" },
  { value: "LinkedIn-Carousel-EUR", name: "LinkedIn Carousel" },
  { value: "Post-Scheduling-EUR", name: "Post Scheduling" }
];

export interface IAddon {
  title: string;
  duration: string;
  plan: AvailableAddonCharges;
}

export interface IAddonOption {
  label: string;
  value: IAddon;
}

export const getAccountAddon = (
  value: IAddonOption["value"]
): void | IAddonOption => {
  const name = AvailableAddonLabels.find(s => s.value === value.plan)?.name;

  if (name) {
    return {
      label: name,
      value: value
    };
  }
};

export const getAccountAddons = (currency: "EUR" | "USD"): IAddonOption[] => [
  {
    label: "Willow Trailer",
    value: {
      title: "Willow Trailer",
      duration: "Non-recurring charge",
      plan:
        currency === "USD"
          ? AvailableAddonCharges.WILLOW_TRAILER_USD
          : AvailableAddonCharges.WILLOW_TRAILER_EUR
    }
  },
  {
    label: "Willow & Chill",
    value: {
      title: "Willow Chill",
      duration: "Non-recurring charge",
      plan:
        currency === "USD"
          ? AvailableAddonCharges.WILLOW_CHILL_USD
          : AvailableAddonCharges.WILLOW_CHILL_EUR
    }
  },
  {
    label: "Willow Binge Watch",
    value: {
      title: "Willow Binge Watch",
      duration: "Non-recurring charge",
      plan:
        currency === "USD"
          ? AvailableAddonCharges.WILLOW_BING_WATCH_USD
          : AvailableAddonCharges.WILLOW_BING_WATCH_EUR
    }
  },
  {
    label: "Willow Template Design",
    value: {
      title: "Willow Template Design",
      duration: "Non-recurring charge",
      plan:
        currency === "USD"
          ? AvailableAddonCharges.WILLOW_TEMPLATE_DESIGN_USD
          : AvailableAddonCharges.WILLOW_TEMPLATE_DESIGN_EUR
    }
  },
  {
    label: "Willow Long Post X",
    value: {
      title: "Willow Long Post X",
      duration: "Non-recurring charge",
      plan:
        currency === "USD"
          ? AvailableAddonCharges.WILLOW_LONG_POST_USD
          : AvailableAddonCharges.WILLOW_LONG_POST_EUR
    }
  },
  {
    label: "Willow Linkedin Carousel",
    value: {
      title: "Willow Linkedin Carousel",
      duration: "Non-recurring charge",
      plan:
        currency === "USD"
          ? AvailableAddonCharges.WILLOW_LINKEDIN_CAROUSEL_USD
          : AvailableAddonCharges.WILLOW_LINKEDIN_CAROUSEL_EUR
    }
  },
  {
    label: "Willow Post Scheduling",
    value: {
      title: "Willow Post Scheduling",
      duration: "Non-recurring charge",
      plan:
        currency === "USD"
          ? AvailableAddonCharges.WILLOW_POST_SCHEDULING_USD
          : AvailableAddonCharges.WILLOW_POST_SCHEDULING_EUR
    }
  }
];

export const AddonSelectorInput = <T extends FieldValues>(
  inputs: AddonSelectorProps<T>
) => {
  const {
    fieldState,
    field: { value, onChange, ...formField }
  } = useController(inputs);

  const selectRef = React.useRef<SelectInstance<IAddonOption, true>>(null);
  const hasError = fieldState.error !== undefined;

  const styles: StylesConfig<IAddonOption, true> = {
    container: provided => ({
      ...provided,
      marginTop: "8px",
      width: "100%"
    }),
    control: provided => ({
      ...provided,
      borderRadius: "8px",
      borderColor: hasError ? theme.colors.danger : theme.colors.grey02
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "11px 16px",
      background: "transparent",
      border: 0,
      borderWidth: 0,
      boxShadow: "none"
    }),
    menu: provided => ({
      ...provided,
      zIndex: 9999,
      top: "auto",
      bottom: "100%",
      background: "transparent",
      border: 0,
      borderWidth: 0,
      boxShadow: "none"
    }),
    input: provided => ({
      ...provided,
      padding: "0",
      margin: "0"
    }),
    placeholder: provided => ({
      ...provided,
      color: theme.colors.text04
    }),
    indicatorSeparator: () => ({}), // removes
    dropdownIndicator: provided => ({
      ...provided,
      display: "none"
    })
  };

  return (
    <StyledInputFieldset>
      <StyledInputLabel style={{ textTransform: "capitalize" }}>
        {`${inputs.label} `}{" "}
      </StyledInputLabel>
      <Flex style={{ position: "relative" }}>
        <Select<IAddonOption, true, GroupBase<IAddonOption>>
          {...formField}
          options={getAccountAddons("EUR") as any[]}
          styles={styles}
          name="addons"
          components={{ Menu, Option }}
          isClearable={true}
          onChange={option => {
            onChange(option);
          }}
          isMulti={true}
          value={value ?? []}
          ref={selectRef}
        />
      </Flex>
    </StyledInputFieldset>
  );
};
