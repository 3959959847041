import React from "react";

import { Flex, Text } from "rebass/styled-components";

import { IAdmin } from "./CreateAccountModal";
import Dropdown from "components/common/Dropdown";
import { Control, FieldValues, Path } from "react-hook-form";
import { required } from "utils/Validation";
import SelectorContainer from "./SelectorContainer";
import { MenuContainer, MenuEntry } from "components/common/Menu";

interface IMenu {
  value?: IAdmin;
  onOptionSelected: (value: IAdmin) => void;
  admins: IAdmin[];
}

const AdminSelectorMenu: React.FC<IMenu> = ({
  value,
  onOptionSelected: onStatusSelected,
  admins
}) => (
  <MenuContainer minWidth={280}>
    {admins.map((emp, index) => {
      const isSelected = value === emp;

      return (
        <MenuEntry
          key={emp.email + index}
          selected={isSelected}
          onClick={() => onStatusSelected(emp)}
        >
          <Flex
            sx={{
              flexDirection: "column",
              gap: "4px"
            }}
          >
            <Text
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "125%"
              }}
            >
              {emp.fullName}
            </Text>
            <Text
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "130%",
                color: "#808082"
              }}
              className="subtitle"
            >
              {emp.email}
            </Text>
          </Flex>
        </MenuEntry>
      );
    })}
  </MenuContainer>
);

type AdminSelectorProps<T extends FieldValues> = {
  label: string;
  placeholder: string;
  admins: IAdmin[];
  control?: Control<T>;
  name: Path<T>;
};

const AdminSelector = <T extends FieldValues>({
  control,
  name,
  admins,
  label,
  placeholder
}: AdminSelectorProps<T>) => {
  return (
    <Dropdown
      control={control}
      name={name}
      rules={{
        validate: {
          required
        }
      }}
      menuBuilder={(props: any) => (
        <AdminSelectorMenu
          value={props.selected}
          onOptionSelected={props.onOptionSelected}
          admins={admins}
        />
      )}
      dropdownContainerBuilder={(props: any) => (
        <SelectorContainer
          label={label}
          placeHolder={placeholder}
          displayText={props.selected?.fullName}
          onClick={props.onClick}
          hasError={props.hasError}
        />
      )}
    />
  );
};

export default AdminSelector;
