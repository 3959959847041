import { takeLatest, takeEvery, put } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";
import omit from "lodash/omit";
import * as accountActions from "../actions/AccountActions";
import { callApi } from "../../utils/ContentoApi";

function* fetchAccounts(action) {
  const accounts = yield callApi({
    method: "get",
    url: `/accounts`,
    params: {
      all: true,
      filters: action.filters
    }
  });
  yield put(accountActions.accountsFetched(accounts));
}

function* fetchAccount(action) {
  try {
    const account = yield callApi({
      method: "get",
      url: `/accounts/${action.accountId}`
    });
    yield put(accountActions.accountFetched(account));
  } catch (error) {
    toastr.error(error.message);
  }
}

function* updateAccount(action) {
  try {
    if (action.accountId) {
      const updatedAccount = yield callApi({
        method: "put",
        url: `accounts/${action.accountId}`,
        data: { ...action.values }
      });
      toastr.success("Successfully updated account.");
      yield put(accountActions.accountUpdated(updatedAccount));
    } else {
      const account = yield callApi({
        method: "post",
        url: `accounts`,
        data: { ...omit(action.values, "id") }
      });
      toastr.success("Successfully created account.");
      yield put(push(`/accounts/${account.id}/profile`));
    }
  } catch (error) {
    console.error(error);
    toastr.error(`Failed updating account. ${error.message}`);
    yield put(accountActions.updateAccountError(error));
  }
}

function* deleteAccount(action) {
  try {
    yield callApi({
      method: "delete",
      url: `accounts/${action.accountId}`
    });
    toastr.success("Successfully deleted account.");
    yield put(push(`/accounts`));
  } catch (error) {
    console.error(error);
    toastr.error("Error deleting account", error.message);
  }
}

function* fetchAccountSearchTerms(action) {
  const searchTerms = yield callApi({
    method: "get",
    url: `/accounts/${action.accountId}/search-terms`
  });
  yield put(
    accountActions.accountSearchTermsFetched(action.accountId, searchTerms)
  );
}

function* addAccountSearchTerm(action) {
  try {
    const searchTerm = yield callApi({
      method: "post",
      url: `/accounts/${action.accountId}/search-terms`,
      data: action.values
    });
    toastr.success(`Successfully added ${searchTerm.searchTerm}.`);
    yield put(accountActions.fetchAccountSearchTerms(action.accountId));
  } catch (error) {
    toastr.error("Error adding search term", error.message);
  }
}

function* removeAccountSearchTerm(action) {
  try {
    const searchTerm = yield callApi({
      method: "delete",
      url: `/accounts/${action.accountId}/search-terms/${action.searchTermId}`
    });
    toastr.success(`Successfully removed ${searchTerm.searchTerm}.`);
    yield put(accountActions.fetchAccountSearchTerms(action.accountId));
  } catch (error) {
    toastr.error("Error removing search term", error.message);
  }
}

export default function* root() {
  yield takeEvery(accountActions.FETCH_ACCOUNTS, fetchAccounts);
  yield takeEvery(accountActions.FETCH_ACCOUNT, fetchAccount);
  yield takeLatest(accountActions.UPDATE_ACCOUNT, updateAccount);
  yield takeLatest(accountActions.DELETE_ACCOUNT, deleteAccount);

  yield takeEvery(
    accountActions.FETCH_ACCOUNT_SEARCH_TERMS,
    fetchAccountSearchTerms
  );
  yield takeLatest(
    accountActions.ADD_ACCOUNT_SEARCH_TERM,
    addAccountSearchTerm
  );
  yield takeLatest(
    accountActions.REMOVE_ACCOUNT_SEARCH_TERM,
    removeAccountSearchTerm
  );
}
