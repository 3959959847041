import React from "react";

import { Link } from "react-router-dom";
import CountBadge from "../../components/common/CountBadge";
import FallbackImage from "../../components/common/FallbackImage";
import emptyProfileIcon from "../../assets/images/profile-icon.png";
import { getTimeDifferenceElement } from "../../utils/date";

import PaginatedAccountList from "./PaginatedAccountList";

export default function AccountsInNeedOfContent(props) {
  const tableHeader = (
    <tr>
      <th style={{ width: "10%" }} />
      <th style={{ width: "25%" }}>Name</th>
      <th style={{ width: "25%", textAlign: "center" }}>
        Time until next empty task
      </th>
      <th style={{ width: "15%", textAlign: "center" }}>Articles left</th>
      <th style={{ width: "20%", textAlign: "center" }}>Last sign in</th>
      <th style={{ width: "10%", textAlign: "center" }} />
    </tr>
  );

  const buildRecord = account => (
    <tr key={account.id} style={{ borderBottom: "1px solid #eee" }}>
      <td
        style={{
          verticalAlign: "middle",
          textAlign: "center"
        }}
      >
        <FallbackImage
          src={account.logo || emptyProfileIcon}
          alt={account.companyName}
          height={50}
          width={50}
        />
      </td>
      <td style={{ verticalAlign: "middle" }}>
        <Link to={`/accounts/${account.id}`}>{account.companyName}</Link>
      </td>
      <td
        style={{
          verticalAlign: "middle",
          textAlign: "center"
        }}
      >
        {getTimeDifferenceElement({
          date: account.nextEmptyTaskDate,
          applyColors: true
        })}
      </td>
      <td
        style={{
          verticalAlign: "middle",
          textAlign: "center"
        }}
      >
        <CountBadge count={account.suggestionsCount} />
      </td>
      <td
        style={{
          verticalAlign: "middle",
          textAlign: "center"
        }}
      >
        {getTimeDifferenceElement({
          date: account.lastUserTokenRefresh,
          applyColors: false
        })}
      </td>
      <td
        style={{
          verticalAlign: "middle",
          textAlign: "center"
        }}
      >
        <Link
          to={`/accounts/${account.id}/social/recommended-v2`}
          className="m-btn m-btn--pill m-btn--hover-brand btn btn-sm btn-secondary"
        >
          Suggest Content
        </Link>
      </td>
    </tr>
  );

  return (
    <PaginatedAccountList
      {...props}
      title="Need new content"
      url="/admin/accounts/need-content"
      tableHeader={tableHeader}
      buildRecord={buildRecord}
    />
  );
}
