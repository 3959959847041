import * as authActions from "../actions/AuthActions";
import Auth from "../../utils/Auth";

const initialState = {
  currentUser: null,
  isRenewingToken: false,
  hasValidToken: Auth.hasValidToken(),
  isFetchingUser: false,
  loginFailed: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActions.USER_INFO_REQUESTED:
      return Object.assign({}, state, {
        isFetchingUser: true
      });
    case authActions.USER_INFO_FETCHED:
      return Object.assign({}, state, {
        currentUser: action.userInfo,
        isFetchingUser: false,
        loginFailed: false
      });
    case authActions.RENEW_TOKEN:
      return Object.assign({}, state, {
        isRenewingToken: true
      });
    case authActions.TOKEN_RENEWED:
      return Object.assign({}, state, {
        isRenewingToken: false,
        loginFailed: false
      });
    case authActions.AUTHENTICATION_REQUIRED:
      return Object.assign({}, state, {
        currentUser: null,
        isFetchingUser: false,
        hasValidToken: false
      });
    case authActions.LOGOUT:
      return Object.assign({}, state, {
        isRenewingToken: false,
        isFetchingUser: false,
        currentUser: null,
        hasValidToken: false,
        loginFailed: false
      });
    case authActions.LOGIN:
      return Object.assign({}, state, {
        loginFailed: false
      });
    case authActions.LOGIN_FAILED:
      return Object.assign({}, state, {
        loginFailed: true
      });
    default:
      return state;
  }
};

export default authReducer;
