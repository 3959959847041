import React from "react";
import { Flex, Text } from "rebass/styled-components";
import { Calendar } from "@styled-icons/boxicons-solid/Calendar";
import { LegendContainer } from "./styles";
import Circle from "./Circle";

const CalendarStatus = ({
  ellipseColor,
  title,
  text
}: {
  ellipseColor: string;
  title: string;
  text: string;
}) => {
  return (
    <Flex sx={{ gap: "10px", padding: "10px", alignItems: "center" }}>
      <Circle color={ellipseColor} />

      <Text
        sx={{
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "19.6px"
        }}
      >
        {title}
      </Text>

      <Text
        sx={{
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "15.6px"
        }}
      >
        {text}
      </Text>
    </Flex>
  );
};

const LegendSection = () => (
  <LegendContainer>
    <Flex
      sx={{
        gap: "10px",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "22.5px",
        padding: "10px",
        alignItems: "center"
      }}
    >
      <Calendar size={"18px"} />
      <Text sx={{}}>Calendar Editor</Text>
    </Flex>
    <Text
      sx={{
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "19.6px",
        marginBottom: "12px"
      }}
    >
      Calendars created here are shown in the Ideas section of the Willow app.
      They will display in product in the order shown, but can be reordered
      here. The statuses are explained below:
    </Text>

    <CalendarStatus
      ellipseColor="#009841"
      title="Live"
      text="In the Willow app and can be used by customers."
    />

    <CalendarStatus
      ellipseColor="#FF5F00"
      title="Voting"
      text="In the Willow app but can only be voted on by customers, not used. "
    />

    <CalendarStatus
      ellipseColor="#FF0E0E"
      title="Inactive"
      text="Not in the Willow app, in draft mode. "
    />
  </LegendContainer>
);

export default LegendSection;
