import React from "react";

import { connect } from "react-redux";
import { reset } from "redux-form";
import { toastr } from "react-redux-toastr";
import InfluencerForm from "../../../components/accounts/InfluencerForm";
import { callApi } from "../../../utils/ContentoApi";

class MarketingPlanInfluencersPage extends React.Component {
  state = { influencers: [] };

  componentDidMount() {
    //fetch keywords
    this.refreshInfluencers();
  }

  handleRemoveInfluencer(influencerId) {
    callApi({
      method: "delete",
      url: `/accounts/${this.props.account.id}/influencers/${influencerId}`
    })
      .then(() => {
        toastr.success("Successfully removed influencer");
        this.refreshInfluencers();
        //TODO: refresh the account so the influencers are updated
      })
      .catch(error => {
        toastr.error(error.message);
      });
  }

  handleAddInfluencer(values) {
    callApi({
      method: "post",
      url: `/accounts/${this.props.account.id}/influencers`,
      data: values
    })
      .then(() => {
        this.props.resetForm("influencer");
        this.refreshInfluencers();
        //refresh the account so the influencers are updated
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({ influencers: [] });
      });
  }

  refreshInfluencers() {
    callApi({
      method: "get",
      url: `/accounts/${this.props.account.id}/influencers`
    })
      .then(influencers => {
        this.setState({ influencers: influencers });
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({ influencers: [] });
      });
  }

  render() {
    const { influencers } = this.state;
    return (
      <div className="m-portlet__body">
        <table className="table m-table m-table--head-bg-success">
          <thead>
            <tr>
              <th>Platform</th>
              <th>Influencer</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {influencers.map(influencer => (
              <tr key={influencer.id}>
                <td>{influencer.platform}</td>
                <td>@{influencer.handle}</td>
                <td>
                  <button
                    className="btn m-btn--pill btn-danger btn-sm"
                    onClick={() => this.handleRemoveInfluencer(influencer.id)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <InfluencerForm
          onSubmit={this.handleAddInfluencer.bind(this)}
          initialValues={{ platform: "twitter" }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    account: state.accounts.selectedAccount
  };
};

export default connect(mapStateToProps, {
  resetForm: reset
})(MarketingPlanInfluencersPage);
