import React, { useRef } from "react";
import {
  Control,
  FieldValues,
  Path,
  useController,
  UseControllerProps
} from "react-hook-form";
import { Flex, Text } from "rebass/styled-components";
import Select, {
  MenuProps,
  OptionProps,
  SingleValueProps,
  StylesConfig,
  components,
  SelectInstance
} from "react-select";

import { MenuContainer, MenuEntry } from "components/common/Menu";
import {
  StyledInputFieldset,
  StyledInputLabel,
  ErrorMessage,
  HelperText
} from "components/common/form/styles";
import { theme } from "theme";

export interface CategoryOption {
  label: string;
  value: string;
}

const Option = ({ children, ...props }: OptionProps<CategoryOption, false>) => {
  const option = props.data;

  return (
    <MenuEntry
      key={option.value}
      selected={props.isSelected}
      onClick={() => {
        props.selectOption(option);
      }}
    >
      <Text
        sx={{
          fontSize: "13px",
          fontWeight: 500,
          lineHeight: "17.5px",
          textTransform: "capitalize"
        }}
      >
        {option.value}
      </Text>
    </MenuEntry>
  );
};

const SingleValue = ({
  children,
  ...props
}: SingleValueProps<CategoryOption, false>) => {
  const value = props.getValue()[0];
  const displayText = value ? `${value.value}` : undefined;
  return (
    <components.SingleValue {...props}>
      {value && (
        <Text fontSize={"14px"} sx={{ textTransform: "capitalize" }}>
          {displayText}
        </Text>
      )}
    </components.SingleValue>
  );
};

const Menu = ({ children, ...props }: MenuProps<CategoryOption, false>) => {
  return (
    <components.Menu {...props}>
      <MenuContainer width={320}>{children}</MenuContainer>;
    </components.Menu>
  );
};

interface Props<T extends FieldValues>
  extends UseControllerProps<T>,
    React.CSSProperties {
  label: string;
  control?: Control<T>;
  name: Path<T>;
  required?: boolean;
  helperText?: string;
  categories: string[];
}

const CalendarCategorySelector = <T extends FieldValues>(inputs: Props<T>) => {
  let {
    fieldState,
    field: { value, onChange, ...formField }
  } = useController(inputs);

  const selectRef = useRef<SelectInstance<CategoryOption>>(null);
  const { label, required, helperText, categories } = inputs;

  const hasError = fieldState.error !== undefined;

  const categoryOptions: CategoryOption[] = categories.map(category => {
    return {
      label: category,
      value: category
    };
  });

  const styles: StylesConfig<CategoryOption, false> = {
    container: provided => ({
      ...provided,
      marginTop: "8px",
      width: "100%"
    }),
    control: provided => ({
      ...provided,
      borderRadius: "8px",
      borderColor: hasError ? theme.colors.danger : theme.colors.grey02
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "11px 16px"
    }),
    menu: () => ({}),
    input: provided => ({
      ...provided,
      padding: "0",
      margin: "0",
      fontSize: "14px"
    }),
    placeholder: provided => ({
      ...provided,
      color: theme.colors.text04
    }),
    indicatorSeparator: () => ({}), // removes
    dropdownIndicator: provided => ({
      ...provided,
      display: "none"
    })
  };

  return (
    <StyledInputFieldset>
      <StyledInputLabel>
        {`${label} `}{" "}
        {required && <span style={{ color: "#FF0E0E" }}>{` * `}</span>}
      </StyledInputLabel>
      {helperText && <HelperText>{helperText}</HelperText>}
      <Flex>
        <Select
          styles={styles}
          menuPlacement="auto"
          menuPosition="fixed"
          options={categoryOptions}
          components={{ Menu, SingleValue, Option }}
          {...formField}
          isClearable={true}
          value={categoryOptions.find(
            op => op.value.trim().toLowerCase() === value.trim().toLowerCase()
          )}
          onChange={option => {
            onChange(option?.value);
          }}
          ref={selectRef}
        />
      </Flex>
      {hasError && <ErrorMessage>{fieldState.error?.message}</ErrorMessage>}
    </StyledInputFieldset>
  );
};

export default CalendarCategorySelector;
