import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { callApi } from "../../utils/ContentoApi";

import { toastr } from "react-redux-toastr";
import Portlet from "../../components/common/portlet/Portlet";
import FormattedDate from "../../components/common/FormattedDate";
import FormattedPrice from "../../components/common/FormattedPrice";

function AccountBillingPage({ account }) {
  const [billingInfo, setBillingInfo] = useState();
  const fetchBillingInfo = useCallback(() => {
    callApi({
      method: "get",
      url: `/accounts/${account.id}/billing`
    })
      .then(billingInfo => {
        setBillingInfo(billingInfo);
      })
      .catch(error => {
        toastr.error(error.message);
        setBillingInfo(null);
      });
  }, [account.id]);

  useEffect(() => {
    fetchBillingInfo();
  }, [fetchBillingInfo]);

  if (!billingInfo) {
    return <Portlet title="Billing">No billing information available</Portlet>;
  }

  const sub = billingInfo.subscription;
  return (
    <Portlet title="Billing">
      <h3>Subscriptions</h3>
      <table className="table m-table m-table--head-bg-success">
        <thead>
          <tr>
            <th>Start</th>
            <th>Current Period Start</th>
            <th>Current Period End</th>
            <th>Trial Start</th>
            <th>Trial End</th>
            <th>Plan</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {sub && (
            <tr key={sub.id}>
              <td>
                <FormattedDate
                  format="YYYY-MM-DD"
                  date={sub.started_at * 1000}
                />
              </td>
              <td>
                <FormattedDate
                  format="YYYY-MM-DD"
                  date={sub.current_term_start * 1000}
                />
              </td>
              <td>
                <FormattedDate
                  format="YYYY-MM-DD"
                  date={sub.current_term_end * 1000}
                />
              </td>
              <td>
                <FormattedDate
                  format="YYYY-MM-DD"
                  date={sub.trial_start * 1000}
                />
              </td>
              <td>
                <FormattedDate
                  format="YYYY-MM-DD"
                  date={sub.trial_end * 1000}
                />
              </td>
              <td>{sub.plan_id}</td>
              <td>
                <FormattedPrice
                  price={sub.plan_amount / 100}
                  currency={sub.currency_code}
                />{" "}
                / {sub.billing_period_unit}
              </td>
              <td>{sub.status}</td>
            </tr>
          )}
        </tbody>
      </table>
      <h3>Invoices</h3>
      <table className="table m-table m-table--head-bg-success">
        <thead>
          <tr>
            <th>Date</th>
            <th>Status</th>
            <th>Sub Total</th>
            <th>Tax</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {billingInfo.invoices.map(invoice => {
            return (
              <tr key={invoice.id}>
                <td>
                  <FormattedDate
                    format="YYYY-MM-DD"
                    date={invoice.date * 1000}
                  />
                </td>
                <td>{invoice.status}</td>
                <td>
                  <FormattedPrice
                    price={invoice.sub_total / 100}
                    currency={invoice.currency_code}
                  />
                </td>
                <td>
                  <FormattedPrice
                    price={invoice.tax / 100}
                    currency={invoice.currency_code}
                  />
                </td>
                <td>
                  <FormattedPrice
                    price={invoice.total / 100}
                    currency={invoice.currency_code}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Portlet>
  );
}

const mapStateToProps = (state, props) => {
  return {
    account: state.accounts.selectedAccount
  };
};

export default connect(mapStateToProps, {})(AccountBillingPage);
