import React from "react";
import { MainContainer } from "./styles";
import CalendarTableSection from "./CalendarTable";
import LegendSection from "./LegendSection";

const MainCalendarsPage = () => {
  return (
    <MainContainer>
      <LegendSection />
      <CalendarTableSection />
    </MainContainer>
  );
};

export default MainCalendarsPage;
