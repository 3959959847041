import React from "react";

import AsyncSelect from "react-select/async";
import { callApi } from "utils/ContentoApi";
import {
  FieldValues,
  UseControllerProps,
  useController
} from "react-hook-form";
import {
  ErrorMessage,
  StyledInputFieldset,
  StyledInputLabel
} from "components/common/form/styles";
import { theme } from "theme";
import { IAdmin } from "./CreateAccountModal";
import { Flex, Text } from "rebass/styled-components";
import {
  MenuListProps,
  MenuProps,
  OptionProps,
  SingleValueProps,
  StylesConfig,
  components
} from "react-select";
import { MenuContainer, MenuEntry } from "components/common/Menu";

const searchUsers = async (searchTerm: string) => {
  const userResult = await callApi({
    method: "get",
    url: `/users?q=${searchTerm ?? ""}`
  });

  return userResult.map(createUserOption);
};

const createUserOption = (user: any) => {
  const value = {
    id: user.id,
    fullName: user.fullName,
    email: user.email
  };

  return {
    value,
    label: value.fullName
  };
};

interface UserSelectorProps<T extends FieldValues>
  extends UseControllerProps<T>,
    React.CSSProperties {
  label: string;
  required?: boolean;
}

type UserOption = {
  label: string;
  value: IAdmin;
};

const Option = ({ children, ...props }: OptionProps<UserOption, false>) => {
  const value = props.data.value;

  return (
    // <components.Option {...props}>
    <MenuEntry
      key={value.email}
      selected={props.isSelected}
      onClick={() => {
        console.log("On Click Called!!!");
        props.selectOption(props.data);
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          gap: "4px"
        }}
      >
        <Text
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "125%"
          }}
        >
          {value.fullName}
        </Text>
        <Text
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "130%",
            color: "#808082"
          }}
          className="subtitle"
        >
          {value.email}
        </Text>
      </Flex>
    </MenuEntry>
    //</components.Option>
  );
};

const SingleValue = ({
  children,
  ...props
}: SingleValueProps<UserOption, false>) => {
  const value = props.getValue()[0];
  return (
    <components.SingleValue {...props}>
      {value && <Text>{props.getValue()[0].value.fullName}</Text>}
    </components.SingleValue>
  );
};

const MenuList = ({ children, ...props }: MenuListProps<UserOption, false>) => {
  return (
    <components.MenuList {...props}>
      <Text
        style={{
          padding: "0 20px",
          color: "#C1C1C1",
          fontSize: "10px",
          marginBottom: "10px"
        }}
      >
        Type to search. Willow user must already exist.
      </Text>
      {children}
    </components.MenuList>
  );
};

const Menu = ({ children, ...props }: MenuProps<UserOption, false>) => {
  return (
    <components.Menu {...props}>
      <MenuContainer width={320}>{children}</MenuContainer>;
    </components.Menu>
  );
};

const UserSelector = <T extends FieldValues>(inputs: UserSelectorProps<T>) => {
  const { field, fieldState } = useController(inputs);

  const { label, required } = inputs;

  const hasError = fieldState.error !== undefined;

  const styles: StylesConfig<UserOption, false> = {
    container: provided => ({
      ...provided,
      marginTop: "8px"
    }),
    control: provided => ({
      ...provided,
      borderRadius: "8px",
      borderColor: hasError ? theme.colors.danger : theme.colors.grey02
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "11px 16px"
    }),
    menu: () => ({}),
    input: provided => ({
      ...provided,
      padding: "0",
      margin: "0"
    }),
    placeholder: provided => ({
      ...provided,
      color: theme.colors.text04
    }),
    indicatorSeparator: () => ({}), // removes
    dropdownIndicator: provided => ({
      ...provided,
      display: "none"
    })
  };

  return (
    <StyledInputFieldset>
      <StyledInputLabel>
        {`${label} `}{" "}
        {required && <span style={{ color: "#FF0E0E" }}>{` * `}</span>}
      </StyledInputLabel>
      <AsyncSelect
        placeholder="john.appleseed@acme.inc"
        styles={styles}
        loadOptions={searchUsers}
        cacheOptions
        defaultOptions
        menuPlacement="auto"
        menuPosition="fixed"
        components={{ Menu, SingleValue, Option, MenuList }}
        {...field}
        value={field.value ? createUserOption(field.value) : field.value}
        onChange={option => {
          field.onChange((option as any)?.value);
        }}
      />
      {hasError && <ErrorMessage>{fieldState.error?.message}</ErrorMessage>}
    </StyledInputFieldset>
  );
};

export default UserSelector;
