import React, { useState, useEffect, useCallback } from "react";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import { callApi } from "../../utils/ContentoApi";
import AddSource from "components/sources/AddSource";
import FilterSection from "components/sources/FilterSection";
import SourcesTable from "components/sources/SourcesTable";
import Pagination from "components/sources/Pagination";

export default function SourceListPage() {
  const [refreshFlag, setRefreshFlag] = useState(1);
  const [searchResults, setSearchResults] = useState(null);
  const [activeFilters, setActiveFilters] = useState({
    group1: ["All"],
    group2: ["All"]
  });
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [title, setTitle] = useState("");
  const [topic, setTopic] = useState("");
  const [isAccountSource] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const params = {
      limit:
        activeFilters.group1.includes("All") &&
        activeFilters.group2.includes("All")
          ? limit
          : 1000000,
      page,
      status: activeFilters.status,
      type: activeFilters.type,
      title,
      topic,
      isAccountSource
    };

    callApi({
      method: "get",
      url: "/sources",
      params
    })
      .then(response => {
        console.log(response.sources);
        setSearchResults(response);
      })
      .catch(error => {
        toastr.error(error.message);
      });
  }, [limit, page, activeFilters, title, topic, isAccountSource, refreshFlag]);

  useEffect(() => {}, [searchResults]);

  const handleFiltersChanged = useCallback(filters => {
    setActiveFilters(filters);
  }, []);

  const handleNewSourceClick = useCallback(() => {
    history.push("/sources/new");
  }, [history]);

  const refreshSearch = useCallback(() => {
    toastr.success(
      "Data Refreshed",
      "The data has been refreshed successfully."
    );
    setRefreshFlag(refreshFlag + 1);
  }, [refreshFlag]);

  const handleDeleteSourcesWithZeroArticles = useCallback(() => {
    if (!searchResults || !searchResults.sources) {
      toastr.error("Error", "No sources available to delete.");
      return;
    }

    const sourcesToDelete = searchResults.sources.filter(
      source => source.articleCount === 0
    );

    if (sourcesToDelete.length === 0) {
      toastr.info("Info", "No sources with zero articles to delete.");
      return;
    }

    console.log("Sources to be deleted:", sourcesToDelete);

    Promise.all(
      sourcesToDelete.map(source =>
        callApi({
          method: "delete",
          url: `/sources/${source.id}`
        })
          .then(() => {
            console.log("Source deleted:", source.id);
          })
          .catch(error => {
            console.error("Error deleting source:", error);
            toastr.error(
              "Error",
              `Error deleting source ${source.id}: ${error.message}`
            );
          })
      )
    ).then(() => {
      refreshSearch();
      toastr.success(
        "Success",
        "Sources with zero articles have been deleted."
      );
    });
  }, [searchResults, refreshSearch]);

  const handleTextFilterChange = useCallback(value => {
    setTitle(value);
  }, []);

  const handleTopicFilterChange = useCallback(value => {
    setTopic(value);
  }, []);

  return (
    <>
      <AddSource
        onNewSourceClick={handleNewSourceClick}
        refreshSearch={refreshSearch}
        onDeleteSourcesWithZeroArticles={handleDeleteSourcesWithZeroArticles}
      />
      <FilterSection
        onFiltersChanged={handleFiltersChanged}
        onSearch={handleTextFilterChange}
        onTopicSearch={handleTopicFilterChange}
      />
      <SourcesTable
        sources={searchResults ? searchResults.sources : []}
        activeFilters={activeFilters}
        setRefreshFlag={setRefreshFlag}
        topicInput={topic}
      />
      {searchResults && (
        <Pagination
          totalPages={searchResults.totalPages}
          currentPage={searchResults.page}
          onPageChange={setPage}
        />
      )}
    </>
  );
}
