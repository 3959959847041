import * as accountActions from "../actions/AccountActions";
import { keyBy, map } from "lodash";

const initialState = {
  data: [],
  searchTerms: {},
  profiles: {},
  selectedAccount: null,
  newAccount: { id: null },
  all: []
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case accountActions.ACCOUNTS_FETCHED:
      return Object.assign({}, state, {
        data: keyBy(action.accounts.rows, "id"),
        all: map(action.accounts.rows, "id")
      });
    case accountActions.ACCOUNT_FETCHED:
      return Object.assign({}, state, {
        selectedAccount: action.account
      });
    case accountActions.ACCOUNT_UPDATED:
      return Object.assign({}, state, {
        selectedAccount: action.account
      });
    case accountActions.ACCOUNT_SEARCH_TERMS_FETCHED:
      return Object.assign({}, state, {
        searchTerms: Object.assign({}, state.searchTerms, {
          [action.accountId]: action.searchTerms
        })
      });
    case accountActions.PROFILES_FETCHED:
      return Object.assign({}, state, {
        searchTerms: Object.assign({}, state.profiles, {
          [action.accountId]: action.channels
        })
      });
    default:
      return state;
  }
};

export default accountReducer;
