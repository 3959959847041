import React from "react";
import "./Timeline.css";

const Timeline = ({ children }) => {
  return (
    <div className="m-timeline-3">
      <div className="m-timeline-3__items">{children}</div>
    </div>
  );
};

export default Timeline;
