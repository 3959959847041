export const FETCH_SCHEDULED_POSTS = "@@scheduledPost/FETCH_SCHEDULED_POSTS";
export const SCHEDULED_POSTS_FETCHED =
  "@@scheduledPost/SCHEDULED_POSTS_FETCHED";
export const SCHEDULE_POST = "@@scheduledPost/SCHEDULE_POST";

export const DELETE_SCHEDULED_POST = "@@scheduledPost/DELETE_SCHEDULED_POST";
export const SCHEDULED_POST_DELETED = "@@scheduledPost/SCHEDULED_POST_DELETED";
//export const DELETE_SCHEDULED_POST_ERROR = "@@scheduledPost/DELETE_SCHEDULED_POST_ERROR";

export const fetchScheduledPosts = () => {
  return {
    type: FETCH_SCHEDULED_POSTS
  };
};

export const scheduledPostsFetched = scheduledPosts => {
  return {
    type: SCHEDULED_POSTS_FETCHED,
    scheduledPosts
  };
};

export const schedulePost = ({ post, time, channels }) => {
  return {
    type: SCHEDULE_POST,
    post,
    time,
    channels
  };
};

export const deleteScheduledPost = (accountId, postId) => {
  return {
    type: DELETE_SCHEDULED_POST,
    accountId,
    postId
  };
};

export const deletedScheduledPost = (accountId, postId) => {
  return {
    type: DELETE_SCHEDULED_POST,
    accountId,
    postId
  };
};

export const scheduledPostDeleted = (accountId, postId) => {
  return {
    type: SCHEDULED_POST_DELETED,
    accountId,
    postId
  };
};
