import React from "react";

import { connect } from "react-redux";

import { Switch, Route, Redirect } from "react-router";
import { NavLink } from "react-router-dom";
import MarketingPlanKeywordsPage from "./MarketingPlanKeywordsPage";
import AccountChannelsPage from "./AccountChannelsPage";
import MarketingPlanSchedulePage from "./MarketingPlanSchedulePage";
import MarketingPlanTagsPage from "./MarketingPlanTagsPage";
import MarketingPlanInfluencersPage from "./MarketingPlanInfluencersPage";
import MarketingPlanAutoPilotPage from "./MarketingPlanAutoPilotPage";
import MarketingPlanDocumentsPage from "./MarketingPlanDocumentsPage";
import MarketingPlanTopicPage from "./MarketingPlanTopicPage";

class AccountMarketingPlanPage extends React.Component {
  render() {
    const { match, account } = this.props;

    return (
      <div className="m-portlet m-portlet--full-height m-portlet--tabs  ">
        <div className="m-portlet__head">
          <div className="m-portlet__head-tools">
            <ul
              className="nav nav-tabs m-tabs m-tabs-line   m-tabs-line--left m-tabs-line--primary"
              role="tablist"
            >
              <li className="nav-item m-tabs__item">
                <NavLink
                  to={`/accounts/${account.id}/plan/channels`}
                  className="nav-link m-tabs__link"
                >
                  <i className="fa fa-screwdriver" />
                  Channels
                </NavLink>
              </li>
              <li className="nav-item m-tabs__item">
                <NavLink
                  to={`/accounts/${account.id}/plan/autopilot`}
                  className="nav-link m-tabs__link"
                >
                  <i className="fa fa-sync" />
                  Auto Pilot
                </NavLink>
              </li>
              <li className="nav-item m-tabs__item">
                <NavLink
                  to={`/accounts/${account.id}/plan/keywords`}
                  className="nav-link m-tabs__link"
                >
                  <i className="fa fa-search" />
                  Search Terms
                </NavLink>
              </li>
              <li className="nav-item m-tabs__item">
                <NavLink
                  to={`/accounts/${account.id}/plan/topics`}
                  className="nav-link m-tabs__link"
                >
                  <i className="fa fa-search" />
                  Topics
                </NavLink>
              </li>
              <li className="nav-item m-tabs__item">
                <NavLink
                  to={`/accounts/${account.id}/plan/tags`}
                  className="nav-link m-tabs__link"
                >
                  <i className="fa fa-hashtag" />
                  Tags
                </NavLink>
              </li>
              <li className="nav-item m-tabs__item">
                <NavLink
                  to={`/accounts/${account.id}/plan/documents`}
                  className="nav-link m-tabs__link"
                >
                  <i className="fa fa-file" />
                  Documents
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="tab-content">
          <div className="tab-pane active" id="m_user_profile_tab_1">
            <Switch>
              <Route
                path={match.url + "/channels"}
                component={AccountChannelsPage}
              />
              <Route
                path={match.url + "/autopilot"}
                component={MarketingPlanAutoPilotPage}
              />
              <Route
                path={match.url + "/keywords"}
                component={MarketingPlanKeywordsPage}
              />
              <Route
                path={match.url + "/topics"}
                component={MarketingPlanTopicPage}
              />
              <Route
                path={match.url + "/tags"}
                component={MarketingPlanTagsPage}
              />
              <Route
                path={match.url + "/influencers"}
                component={MarketingPlanInfluencersPage}
              />
              <Route
                path={match.url + "/schedule"}
                component={MarketingPlanSchedulePage}
              />
              <Route
                path={match.url + "/documents"}
                component={MarketingPlanDocumentsPage}
              />
              <Redirect to={match.url + "/channels"} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    account: state.accounts.selectedAccount
  };
};

export default connect(mapStateToProps, {})(AccountMarketingPlanPage);
