import React from "react";

import { connect } from "react-redux";
import * as accountActions from "../../state/actions/AccountActions";
import CompanyProfileForm from "../../components/accounts/CompanyProfileForm";
import Portlet from "../../components/common/portlet/Portlet";

const NewAccountPage = ({ updateAccount }) => {
  return (
    <Portlet title="New company">
      <CompanyProfileForm
        onSubmit={values => updateAccount(null, values)}
        initialValues={{
          status: "Onboarding",
          language: "en",
          timezone: "Europe/Brussels",
          features: []
        }}
      />
    </Portlet>
  );
};

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps, {
  updateAccount: accountActions.updateAccount
})(NewAccountPage);
