import { takeLatest, put, select } from "redux-saga/effects";

import * as scheduledPostActions from "../actions/ScheduledPostActions";

import { callApi } from "../../utils/ContentoApi";
import { toastr } from "react-redux-toastr";

const getSelectedAccount = state => {
  return state.accounts.selectedAccount;
};

function* fetchScheduledPosts() {
  const account = yield select(getSelectedAccount);
  const scheduledPosts = yield callApi({
    method: "get",
    url: `accounts/${account.id}/scheduled-posts`
  });
  yield put(scheduledPostActions.scheduledPostsFetched(scheduledPosts));
}

function* schedulePost({ post, time, channels }) {
  const account = yield select(getSelectedAccount);

  yield callApi({
    method: "post",
    url: `accounts/${account.id}/scheduled-posts`,
    data: {
      postId: post.id,
      time,
      channels
    }
  });
  yield put(scheduledPostActions.fetchScheduledPosts());
}

function* deleteScheduledPost({ postId, accountId }) {
  try {
    yield callApi({
      method: "delete",
      url: `accounts/${accountId}/scheduled-posts/${postId}`
    });

    yield put(scheduledPostActions.fetchScheduledPosts());
  } catch (error) {
    toastr.error("Error deleting scheduled post", error.message);
  }
}

export default function* root() {
  yield takeLatest(
    scheduledPostActions.FETCH_SCHEDULED_POSTS,
    fetchScheduledPosts
  );
  yield takeLatest(scheduledPostActions.SCHEDULE_POST, schedulePost);
  yield takeLatest(
    scheduledPostActions.DELETE_SCHEDULED_POST,
    deleteScheduledPost
  );
}
