import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { toastr } from "react-redux-toastr";

import { closeModal } from "state/actions/ModalActions";
import { modalStyles } from "../styles";
import ModalHeader from "../common/ModalHeader";
import CalendarDetailsForm, {
  CalendarDetailsFormInputs
} from "./CalendarDetailsForm";

import CalendarEventsForm, {
  CalendarEvent,
  CalendarEventsFormInputs
} from "./CalendarEventsForm";
import { Calendar } from "containers/calendars/CalendarTable";
import { CalendarStatus } from "containers/calendars/CalendarStatusFilter";
import { callApi } from "utils/ContentoApi";
import { Flex } from "rebass/styled-components";
import ReactLoading from "react-loading";

type Props = {
  onClose: () => void;
  calendar?: Calendar;
};

export enum FormStages {
  CALENDAR_DETAILS = "CALENDAR_STAGES",
  CALENDAR_EVENTS = "CALENDAR_EVENTS",
  REVIEW = "REVIEW"
}

interface CreateCalendarDTO {
  name: string;
  description: string;
  image: string;
  level: CalendarStatus;
  category: string;
  calendar_events: CalendarEvent[];
}

const CalendarEditorModal = ({ calendar, onClose }: Props) => {
  const style = modalStyles("860px", {
    contentStyle: { borderRadius: "20px", padding: "20px 0 10px 0" }
  });

  const [isLoading, setIsLoading] = useState(false);
  const [calendarCategories, setCalendarCategories] = useState<string[]>([]);

  let defaultCalendarDetails: CalendarDetailsFormInputs | undefined = undefined;
  let defaultEvents: CalendarEventsFormInputs | undefined = undefined;
  const isEditMode = calendar !== undefined;

  if (calendar) {
    defaultCalendarDetails = {
      calendarName: calendar.name,
      level: calendar.level,
      description: calendar.description,
      category: calendar.category,
      image: calendar.image
    };

    defaultEvents = {
      events: calendar.calendars_events
    };
  }

  const [currentStage, setCurrentStage] = useState<FormStages>(
    FormStages.CALENDAR_DETAILS
  );

  const [calendarDetails, setCalendarDetails] = useState<
    CalendarDetailsFormInputs | undefined
  >(defaultCalendarDetails);
  const [calendarEvents, setCalendarEvents] = useState<
    CalendarEventsFormInputs | undefined
  >(defaultEvents);

  const onSubmitCalendarDetails = (values: CalendarDetailsFormInputs) => {
    setCalendarDetails(values);
    setCurrentStage(FormStages.CALENDAR_EVENTS);
  };

  const onSubmitCalendarEvents = (values: CalendarEventsFormInputs) => {
    setCalendarEvents(values);
    const params = {
      name: calendarDetails!.calendarName,
      description: calendarDetails!.description,
      image: calendarDetails!.image,
      level: calendarDetails!.level,
      category: calendarDetails!.category,
      calendar_events: values!.events
    };
    if (!isEditMode) {
      handleCreateCalendar(params);
    } else {
      handleUpdateCalendar(params);
    }
  };

  useEffect(() => {
    callApi({
      method: "get",
      url: `/industry-categories`
    })
      .then(setCalendarCategories)
      .catch(err => toastr.error("API Error", err.message));
  }, []);

  const handleCreateCalendar = async (params: CreateCalendarDTO) => {
    setIsLoading(true);

    try {
      const res = await callApi({
        method: "post",
        url: `/calendars`,
        data: params
      });

      console.log(res);
      toastr.success(`Calendar Created Successfully`, "");
      onClose();
    } catch (err) {
      toastr.error("API Error", (err as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateCalendar = async (params: CreateCalendarDTO) => {
    setIsLoading(true);

    try {
      const res = await callApi({
        method: "put",
        url: `/calendars/${calendar!.id}`,
        data: params
      });

      console.log(res);
      toastr.success(`Calendar Updated Successfully`, "");
      onClose();
    } catch (err) {
      toastr.error("API Error", (err as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      style={style}
      isOpen={true}
      ariaHideApp={false}
      onRequestClose={onClose}
      bodyOpenClassName="overflow-hidden"
      shouldCloseOnOverlayClick={false}
    >
      {isLoading && <LoadingIndicator isEditMode={isEditMode} />}

      <ModalHeader onClose={onClose} title="Calendar Editor" />

      {currentStage === FormStages.CALENDAR_DETAILS && (
        <CalendarDetailsForm
          calendarCategories={calendarCategories}
          calendarDetails={calendarDetails}
          onSubmit={onSubmitCalendarDetails}
          setCurrentStage={setCurrentStage}
        />
      )}

      {currentStage === FormStages.CALENDAR_EVENTS && (
        <CalendarEventsForm
          calendarEvents={calendarEvents}
          onSubmit={onSubmitCalendarEvents}
          setCurrentStage={setCurrentStage}
          calendarStatus={calendarDetails?.level}
        />
      )}
    </Modal>
  );
};

const LoadingIndicator = ({ isEditMode = false }: { isEditMode?: boolean }) => (
  <Flex
    alignItems={"center"}
    justifyContent={"center"}
    flexDirection={"column"}
    backgroundColor={"transparent"}
    sx={{
      zIndex: 3,
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: "#F2F2F225"
    }}
  >
    <ReactLoading color="#5867dd" type="bubbles" height={50} />
    <p>{!isEditMode ? "Creating Calendar..." : "Updating Calendar..."}</p>
  </Flex>
);

const mapStateToProps = (state: any) => {
  return {};
};

const Wrapper = connect(mapStateToProps, {
  onClose: closeModal
})(CalendarEditorModal);

export default Wrapper;
