import React from "react";
import Icon from "components/common/Icon";
import { PieChart } from "@styled-icons/icomoon/PieChart";
import { PageButton } from "../common/PageButton";
import {
  EmptyContainer,
  PaddingDeleter,
  ContentContainer,
  TitleContainer,
  SourcesTitle,
  ButtonContainer
} from "../common/AdminHeader";

interface PageSelectionProps {
  activePage: number;
  setActivePage: (page: number) => void;
}

const PageSelection: React.FC<PageSelectionProps> = ({
  activePage,
  setActivePage
}) => {
  return (
    <PaddingDeleter>
      <EmptyContainer>
        <ContentContainer>
          <TitleContainer>
            <Icon component={PieChart} size={20} />
            <SourcesTitle>Operations</SourcesTitle>
          </TitleContainer>
          <ButtonContainer>
            <PageButton
              active={activePage === 1}
              onClick={() => setActivePage(1)}
            >
              Post Issues
            </PageButton>
            <PageButton
              active={activePage === 2}
              onClick={() => setActivePage(2)}
            >
              Channel Issues
            </PageButton>
          </ButtonContainer>
        </ContentContainer>
      </EmptyContainer>
    </PaddingDeleter>
  );
};

export default PageSelection;
