import React from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { closeModal } from "../../state/actions/ModalActions";
import { modalStyles } from "../modals/styles";
import TopicForm from "./TopicForm";

class EditTopicModal extends React.Component {
  render() {
    const { closeModal, topics, onSubmit, topic } = this.props;
    return (
      <Modal
        style={modalStyles("60vw")}
        isOpen={true}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <TopicForm
          allTopics={topics}
          onSubmit={values => {
            onSubmit({
              ...values,
              parentId:
                values.parentId && values.parentId !== "None"
                  ? values.parentId
                  : null
            });
          }}
          showSettings
          initialValues={topic}
          onCancel={closeModal}
        />
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  closeModal: closeModal
})(EditTopicModal);
