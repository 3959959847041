import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";

import Portlet from "../../components/common/portlet/Portlet";
import { callApi } from "../../utils/ContentoApi";

/**
 * Creates a list of accounts using pagination.
 */
export default function PaginatedAccountList({
  limit = 10,
  currentUser,
  url,
  extraApiParams,
  title,
  tableHeader,
  buildRecord
}) {
  const [accountsResponse, setAccountsResponse] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    callApi({
      method: "get",
      url,
      params: {
        limit,
        page: currentPage,
        ...(extraApiParams || {})
      }
    })
      .then(setAccountsResponse)
      .catch(error => {
        toastr.error(error.message);
        setAccountsResponse(null);
      });
  }, [limit, currentPage, extraApiParams, url]);

  if (!accountsResponse) {
    return null;
  }

  const { accounts, total, totalPages } = accountsResponse;

  const changePage = newPage => {
    setCurrentPage(newPage);
  };

  const hasPreviousPages = currentPage !== 0;
  const hasNextPages = (currentPage + 1) * limit >= total;

  return (
    <Portlet title={title}>
      <table className="table m-table m-table--head-bg-success">
        <thead>{tableHeader}</thead>
        <tbody>{accounts.map(account => buildRecord(account))}</tbody>
      </table>

      {totalPages > 1 && (
        <div>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            Page {currentPage + 1} of {totalPages}
          </div>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <button
              className="m-btn m-btn--pill m-btn--hover-brand btn btn-sm btn-secondary"
              disabled={!hasPreviousPages}
              onClick={() => changePage(currentPage - 1)}
            >
              &lt; Prev
            </button>
            <button
              className="m-btn m-btn--pill m-btn--hover-brand btn btn-sm btn-secondary"
              disabled={hasNextPages}
              onClick={() => changePage(currentPage + 1)}
            >
              Next &gt;
            </button>
          </div>
        </div>
      )}
    </Portlet>
  );
}
