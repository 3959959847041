import React from "react";

import { connect } from "react-redux";
import { reset } from "redux-form";
import { toastr } from "react-redux-toastr";
import * as accountActions from "../../../state/actions/AccountActions";
import TagForm from "../../../components/accounts/TagForm";
import { callApi } from "../../../utils/ContentoApi";

class MarketingPlanTagsPage extends React.Component {
  state = { tags: [] };

  componentDidMount() {
    //fetch keywords
    this.refreshTags();
  }

  handleRemoveTag(tagId) {
    callApi({
      method: "delete",
      url: `/accounts/${this.props.account.id}/tags/${tagId}`
    })
      .then(() => {
        toastr.success("Successfully removed tag");
        this.refreshTags();
        //refresh the account so the tags are updated
        this.props.fetchAccount(this.props.account.id);
      })
      .catch(error => {
        toastr.error(error.message);
      });
  }

  handleAddTag(values) {
    callApi({
      method: "post",
      url: `/accounts/${this.props.account.id}/tags`,
      data: values
    })
      .then(() => {
        this.props.resetForm("tags");
        this.refreshTags();
        //refresh the account so the tags are updated
        this.props.fetchAccount(this.props.account.id);
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({ tags: [] });
      });
  }

  refreshTags() {
    callApi({
      method: "get",
      url: `/accounts/${this.props.account.id}/tags`
    })
      .then(tags => {
        this.setState({ tags: tags });
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({ tags: [] });
      });
  }

  render() {
    const { tags } = this.state;
    return (
      <div className="m-portlet__body">
        <table className="table m-table m-table--head-bg-success">
          <thead>
            <tr>
              <th>Hash Tag</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {tags.map(tag => (
              <tr key={tag.id}>
                <td>#{tag.tag}</td>
                <td>
                  <button
                    className="btn m-btn--pill btn-danger btn-sm"
                    onClick={() => this.handleRemoveTag(tag.id)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <TagForm onSubmit={this.handleAddTag.bind(this)} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    account: state.accounts.selectedAccount
  };
};

export default connect(mapStateToProps, {
  resetForm: reset,
  fetchAccount: accountActions.fetchAccount
})(MarketingPlanTagsPage);
