import React from "react";
import styled from "styled-components";

interface PaginationProps {
  itemsPerPage: number;
  totalItems: number;
  paginate: (pageNumber: number) => void;
  currentPage: number;
}

interface StyledButtonProps {
  active: boolean;
}

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const PageButton = styled.button<StyledButtonProps>`
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: ${props => (props.active ? "#BFD8FE" : "#e6efff")};
  color: ${props => (props.active ? "#1c1e1f" : "#1c1e1f")};
  margin: 0 5px;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
`;

const ArrowButton = styled.button`
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #bfd8fe;
  margin: 0 5px;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
`;

const Pagination: React.FC<PaginationProps> = ({
  itemsPerPage,
  totalItems,
  paginate,
  currentPage
}) => {
  const pageNumbers = [];
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const maxPageNumbersToShow = 10;

  let startPage = Math.max(
    1,
    currentPage - Math.floor(maxPageNumbersToShow / 2)
  );
  let endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);

  if (endPage - startPage + 1 < maxPageNumbersToShow) {
    startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <PaginationContainer>
      <ArrowButton
        onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
      >
        &lt;
      </ArrowButton>
      {startPage > 1 && (
        <>
          <PageButton active={false} onClick={() => paginate(1)}>
            1
          </PageButton>
          {startPage > 2 && <span>...</span>}
        </>
      )}
      {pageNumbers.map(number => (
        <PageButton
          key={number}
          active={currentPage === number}
          onClick={() => paginate(number)}
        >
          {number}
        </PageButton>
      ))}
      {endPage < totalPages && (
        <>
          {endPage < totalPages - 1 && <span>...</span>}
          <PageButton active={false} onClick={() => paginate(totalPages)}>
            {totalPages}
          </PageButton>
        </>
      )}
      <ArrowButton
        onClick={() =>
          paginate(currentPage < totalPages ? currentPage + 1 : totalPages)
        }
      >
        &gt;
      </ArrowButton>
    </PaginationContainer>
  );
};

export default Pagination;
