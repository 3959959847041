import React from "react";
import { Box } from "rebass/styled-components";

const Circle = ({
  color: circleColor,
  diameter = 8
}: {
  color: string;
  diameter?: number;
}) => (
  <Box
    sx={{
      width: `${diameter}px`,
      height: `${diameter}px`,
      backgroundColor: circleColor,
      borderRadius: "50%"
    }}
  />
);

export default Circle;
