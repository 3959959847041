import styled from "styled-components";

export const RefreshButton = styled.button`
  display: inline-block;
  background-color: #e6efff;
  color: #0066cc;
  border: none;
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 14px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;
  margin: 2px;

  &:hover {
    background-color: rgba(135, 206, 250, 0.9);
    color: white;
  }

  .icon {
    margin-right: 8px;
  }
`;
