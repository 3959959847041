import React, { useEffect } from "react";

import { connect } from "react-redux";
import * as authActions from "../../state/actions/AuthActions";
import LoginForm from "../../components/auth/LoginForm";
import { toastr } from "react-redux-toastr";

const LoginPage = ({ login, loginFailed }) => {
  useEffect(() => {
    if (loginFailed) {
      toastr.error("Wrong email or password.");
    }
  });

  return (
    <div>
      <LoginForm onSubmit={login} initialValues={{}} />
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    loginFailed: state.auth.loginFailed
  };
};

export default connect(mapStateToProps, {
  login: authActions.login
})(LoginPage);
