import React from "react";
import Icon from "../../components/common/Icon";
import { Refresh } from "@styled-icons/boxicons-regular/Refresh";
import { Delete } from "@styled-icons/fluentui-system-filled/Delete";
import { EmptyContainer } from "./styles/AddSource.styled";
import { toastr } from "react-redux-toastr";
import { callApi } from "../../utils/ContentoApi";

import {
  Table,
  TableRow,
  TableCell,
  TableHeader,
  DeleteButton,
  RefreshButton
} from "./styles/SourcesTable.styled";

interface Source {
  id: number;
  title: string;
  type: string;
  status: string;
  details: string;
  topics: string[];
  articleCount: number;
  statusDetail: string;
  language: string;
  image: string;
  url: string;
  handle: string;
}

interface Props {
  sources: Source[];
  activeFilters: { group1: string[]; group2: string[] };
  topicInput: string;
  onSourceDeleted: (source: Source) => void;
  onSourceReprocessed: (source: Source) => void;
}

function getSourceDetails(source: Source) {
  const statusDetails = source.statusDetail ? source.statusDetail : "active";
  return <div>{statusDetails}</div>;
}

const SourcesTable: React.FC<Props> = ({
  sources,
  activeFilters,
  topicInput
}) => {
  const filteredSources: Source[] = sources.filter(source => {
    const isTypeMatch =
      activeFilters.group1.includes("All") ||
      activeFilters.group1.includes(source.type.toLowerCase());

    const isStatusMatch =
      activeFilters.group2.includes("All") ||
      activeFilters.group2.includes(source.status.toLowerCase());

    const isTopicMatch = topicInput
      ? source.topics.some(topic =>
          topic.toLowerCase().includes(topicInput.toLowerCase())
        )
      : true;

    return isTypeMatch && isStatusMatch && isTopicMatch;
  });

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const refreshSearch = () => {
    setTimeout(() => {
      toastr.success(
        "Data Refreshed",
        "The data has been refreshed successfully."
      );
    }, 1000);
  };

  const handleDelete = (source: Source) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this source and its associated articles?"
    );
    if (confirmed) {
      callApi({ method: "delete", url: `/sources/${source.id}` })
        .then(() => {
          toastr.success("Success!", "The source has been deleted!");
          refreshSearch();
        })
        .catch(error => {
          console.error(error);
          toastr.error("Error", error.message);
        });
    }
  };

  return (
    <EmptyContainer>
      <Table>
        <thead>
          <TableRow>
            <TableHeader>Title</TableHeader>
            <TableHeader>Type</TableHeader>
            <TableHeader>Status</TableHeader>
            <TableHeader>Details</TableHeader>
            <TableHeader>Category</TableHeader>
            <TableHeader>Articles</TableHeader>
            <TableHeader>Language</TableHeader>
            <TableHeader>Action</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {filteredSources.map((source: Source, index: number) => (
            <TableRow key={index}>
              <TableCell>
                {source.image && (
                  <img
                    height={25}
                    width={25}
                    src={source.image}
                    alt="Logo"
                    style={{ marginRight: "10px" }}
                  />
                )}
                <a
                  href={
                    source.type === "twitter" && source.handle
                      ? `https://twitter.com/${source.handle}`
                      : source.url
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {capitalizeFirstLetter(source.title)}
                </a>
              </TableCell>

              <TableCell>
                {capitalizeFirstLetter(
                  source.type === "twitter" ? "X" : source.type
                )}
              </TableCell>
              <TableCell>{capitalizeFirstLetter(source.status)}</TableCell>
              <TableCell>
                {React.cloneElement(getSourceDetails(source), {
                  children: capitalizeFirstLetter(
                    getSourceDetails(source).props.children
                  )
                })}
              </TableCell>
              <TableCell>
                {source.topics &&
                  source.topics.map(topic => (
                    <span key={topic}>
                      {capitalizeFirstLetter(topic)}
                      <br />
                    </span>
                  ))}
              </TableCell>
              <TableCell>{source.articleCount}</TableCell>
              <TableCell>
                {source.language ? source.language.toUpperCase() : "Unknown"}
              </TableCell>
              <TableCell>
                <RefreshButton onClick={() => refreshSearch()}>
                  <Icon component={Refresh} size={20} /> Refresh
                </RefreshButton>
                <DeleteButton onClick={() => handleDelete(source)}>
                  <Icon component={Delete} size={20} />
                  Delete
                </DeleteButton>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </EmptyContainer>
  );
};

export default SourcesTable;
