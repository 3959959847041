import React from "react";
import ReactLoading from "react-loading";

import FormattedDate from "../common/FormattedDate";
import { ContentPreview } from "./styles";

const SearchResultTable = ({ contentId, result, onSuggest, onDismiss }) => {
  return (
    <tr>
      <td>
        <div className="m-widget5__pic">
          <img
            className="m-widget7__img"
            style={{ height: "100px", maxWidth: "150px" }}
            src={result.thumbnail}
            alt=""
          />
        </div>
      </td>
      <td>
        <h5 className="m-widget5__title">{result.title}</h5>
        <div className="m-widget5__info">
          <span className="m-widget5__info-label">Description:&nbsp;</span>
          <span className="m-widget5__info-date m--font-info">
            {result.description}
          </span>
          <br />
          <br />
          <span className="m-widget5__info-label">Published:&nbsp;</span>
          <span className="m-widget5__info-date m--font-info">
            <FormattedDate date={result.published_date} />
          </span>
          <br />
          <br />
          <span className="m-widget5__info-date m--font-info">
            <a href={result.url} target="_blank" rel="noopener noreferrer">
              {result.domain} <i className="fa fa-external-link-alt" />
            </a>
          </span>
        </div>
      </td>
      <td>
        <div className="btn-group">
          {onSuggest && (
            <button
              type="button"
              className="btn m-btn--square  btn-primary btn-sm"
              onClick={() => onSuggest(contentId, result)}
            >
              Suggest
            </button>
          )}
          {onDismiss && (
            <button
              type="button"
              className="btn m-btn--square  btn-secondary btn-sm"
              onClick={() => onDismiss(contentId, result)}
            >
              Dismiss
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

const RecommendationsList = ({
  results,
  onDismissContent,
  onSuggestContent,
  loading
}) => {
  console.log(results);
  return (
    <div className="dataTables_wrapper">
      <div className="row">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ position: "relative" }}>
                {loading && (
                  <div style={{ position: "absolute", top: "5px" }}>
                    <ReactLoading color="#5867dd" type="bubbles" width={50} />
                  </div>
                )}
              </th>
              <th />
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {results.map(result => (
              <SearchResultTable
                key={result._id}
                contentId={result._id}
                result={result._source}
                onSuggest={onSuggestContent}
                onDismiss={onDismissContent}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecommendationsList;
