import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import ProfileIcon from "../accounts/ProfileIcon";

class PostItem extends React.Component {
  render() {
    const {
      post,
      onDelete,
      onShareNow,
      onSchedule,
      isDeleting,
      isScheduling,
      isSharing
    } = this.props;

    let postTime = null;
    let time;
    //get timezone from
    const timezone = post.channel ? post.channel.timezone : null;
    if (post.status === "SENT") time = moment(post.postedAt).tz(timezone);
    else if (post.status === "SCHEDULED")
      time = moment(post.scheduledAt).tz(timezone);

    if (time) {
      postTime = (
        <span className="m-timeline-3__item-time">
          {time.format("ddd D MMM ")}
          <br /> {time.format("HH:mm (z)")}
        </span>
      );
    }

    const attachment = post.attachment;

    return (
      <div
        style={{ marginBottom: 20 }}
        className="m-timeline-3__item m-timeline-3__item--info"
      >
        {postTime}
        <div className="m-timeline-3__item-desc">
          <div style={{ fontSize: "1.1rem", fontWeight: 500 }}>
            {post.caption}
          </div>
          {attachment && attachment.title && (
            <div
              className="media"
              style={{ border: "4px solid #f7f7fa", padding: "5px" }}
            >
              <img
                className="m--margin-right-20"
                style={{ objectFit: "cover" }}
                width="160px"
                height="160px"
                src={attachment.image}
                alt={attachment.title}
              />
              <div className="media-body">
                <div className="m-timeline-1__item-title m--margin-top-10  ">
                  <a
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {attachment.title}
                  </a>
                </div>
                <div className="m-timeline-1__item-body">
                  {attachment.description}
                </div>
              </div>
            </div>
          )}

          {attachment &&
            !attachment.title &&
            attachment.image &&
            attachment.type !== "photo" && (
              <div
                className="media"
                style={{ border: "4px solid #f7f7fa", padding: "5px" }}
              >
                <img
                  className="m--margin-right-20"
                  style={{ objectFit: "cover" }}
                  width="160px"
                  height="160px"
                  src={attachment.image}
                  alt={attachment.caption}
                />
              </div>
            )}

          {attachment && !attachment.title && attachment.type === "photo" && (
            <div
              className="media"
              style={{ border: "4px solid #f7f7fa", padding: "5px" }}
            >
              {!Array.isArray(attachment.url) && (
                <img
                  className="m--margin-right-20"
                  style={{ objectFit: "cover" }}
                  width="160px"
                  height="160px"
                  src={attachment.url}
                  alt={attachment.caption}
                />
              )}
              {Array.isArray(attachment.url) &&
                attachment.url.map(url => {
                  return (
                    <img
                      className="m--margin-right-20"
                      style={{ objectFit: "cover" }}
                      width="160px"
                      height="160px"
                      src={url}
                      alt={attachment.caption}
                      key={url}
                    />
                  );
                })}
            </div>
          )}

          {attachment && attachment.type === "video" && (
            <div
              className="media"
              style={{ border: "4px solid #f7f7fa", padding: "5px" }}
            >
              {attachment.url && (
                <video width={300} height={170} controls>
                  <source src={attachment.url} type={"video/mp4"} />
                </video>
              )}
            </div>
          )}

          <div style={{ marginTop: "5px" }}>
            <div className="btn-group">
              {post.channels &&
                post.channels.map(profile => (
                  <ProfileIcon key={profile.id} profile={profile} />
                ))}
            </div>
            <div className="btn-group pull-right">
              {onDelete && (
                <button
                  type="button"
                  disabled={isDeleting}
                  className="btn m-btn--square  btn-secondary btn-sm"
                  onClick={() => onDelete(post)}
                >
                  {isDeleting ? "Deleting..." : "Delete"}
                </button>
              )}

              {onSchedule && (
                <button
                  type="button"
                  disabled={isScheduling}
                  className="btn m-btn--square  btn-secondary btn-sm"
                  onClick={() => onSchedule(post)}
                >
                  {isScheduling ? "Scheduling..." : "Schedule"}
                </button>
              )}

              {onShareNow && (
                <button
                  type="button"
                  className="btn m-btn--square  btn-secondary btn-sm"
                  onClick={() => onShareNow(post)}
                >
                  {isSharing ? "Sharing..." : "Share"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PostItem.propTypes = {
  post: PropTypes.object,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onShareNow: PropTypes.func
};

export default PostItem;
