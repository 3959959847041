import React, { useEffect, useMemo, useState } from "react";
import {
  CalendarTableContainer,
  Table,
  TableBody,
  TableContainer,
  TableHeadRow,
  TableRow
} from "./styles";
import { Flex, Text } from "rebass/styled-components";
import { Menu } from "styled-icons/feather";
import CalendarStatusFilter, {
  CalendarStatus,
  StatusFilter
} from "./CalendarStatusFilter";
import Circle from "./Circle";
import CreateCalendarButton from "./CreateCalendarButton";
import FormattedDate from "components/common/FormattedDate";
import { connect } from "react-redux";
import { openModal } from "state/actions/ModalActions";
import { MODALS } from "components/modals/ModalRoot";
import { CalendarEvent } from "components/modals/CalendarEditorModal.tsx/CalendarEventsForm";
import { callApi } from "utils/ContentoApi";
import { IAdmin } from "components/modals/CreateAccountModal/CreateAccountModal";
import ReactLoading from "react-loading";

const CalendarStatusIndicator = ({ status }: { status: CalendarStatus }) => {
  const getStausColor = () => {
    switch (status) {
      case CalendarStatus.live:
        return "#009841";
      case CalendarStatus.voting:
        return "#FF5F00";
      case CalendarStatus.inactive:
        return "#FF0E0E";
    }
  };
  return (
    <Flex sx={{ gap: "5px", alignItems: "center" }}>
      <Circle color={getStausColor()} diameter={4} />
      <Text sx={{ textTransform: "capitalize" }}>{status}</Text>
    </Flex>
  );
};

export type Calendar = {
  id: string;
  name: string;
  level: CalendarStatus;
  calendars_events: CalendarEvent[];
  votes_count: number;
  description: string;
  category: string;
  image: string;
  updatedAt: Date;
  updatedBy: IAdmin;
};

const CalendarTableSection = ({ openModal }: { openModal: any }) => {
  let [filter, setFilter] = useState(StatusFilter.all);
  const [calendars, setCalendars] = useState<Calendar[]>([]);
  const [loading, setLoading] = useState(false);

  const onFilterChange = (newFilter: StatusFilter) => setFilter(newFilter);

  const filteredCalendars = useMemo(() => {
    if (filter === StatusFilter.all) {
      return calendars;
    }

    return calendars.filter(calendar => calendar.level === (filter as any));
  }, [filter, calendars]);

  const fetchCalendars = async () => {
    setLoading(true);
    const res = await callApi({
      method: "get",
      url: `/calendars`
    });

    setLoading(false);
    setCalendars(res);
  };

  useEffect(() => {
    fetchCalendars();
  }, []);

  return (
    <CalendarTableContainer>
      <CalendarStatusFilter filter={filter} onChangeFilter={onFilterChange} />
      <TableContainer>
        {loading && <LoadingIndicator />}
        {filteredCalendars.length === 0 && (
          <Flex>
            <Text>
              No Calendars Yet... <br /> Click the Button Below to create one
            </Text>
          </Flex>
        )}

        {filteredCalendars.length > 0 && (
          <Table>
            <thead>
              <TableHeadRow>
                <th>Calendar Name</th>
                <th>Status</th>
                <th>Events</th>
                <th>Votes</th>
                <th>Last Edited</th>
                <th>Edited By</th>
              </TableHeadRow>
            </thead>

            <TableBody>
              {filteredCalendars.map((calendar, index) => {
                return (
                  <TableRow key={`${index}-${calendar.name}`}>
                    <td>
                      <Text
                        sx={{
                          color: "#0063FB",
                          textDecoration: "underline",
                          cursor: "pointer"
                        }}
                        onClick={() =>
                          openModal(MODALS.CALENDAR_EDITOR_MODAL, {
                            calendar
                          })
                        }
                      >
                        {calendar.name}
                      </Text>
                    </td>
                    <td>
                      <CalendarStatusIndicator status={calendar.level} />
                    </td>
                    <td>{calendar.calendars_events.length}</td>
                    <td>{calendar.votes_count}</td>
                    <td>
                      <Text>
                        {FormattedDate({
                          date: calendar.updatedAt,
                          format: "DD/MM/YYYY"
                        })}
                      </Text>
                    </td>
                    <td>
                      <Flex sx={{ alignItems: "center", gap: "10px" }}>
                        {calendar.updatedBy.fullName}
                      </Flex>
                    </td>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <CreateCalendarButton />
    </CalendarTableContainer>
  );
};

const LoadingIndicator = () => (
  <Flex
    alignItems={"center"}
    justifyContent={"center"}
    flexDirection={"column"}
    backgroundColor={"transparent"}
  >
    <ReactLoading color="#5867dd" type="bubbles" height={50} />
    <p>Fetching Calenders...</p>
  </Flex>
);

const mapStateToProps = (state: any) => {
  return {};
};

export default connect(mapStateToProps, {
  openModal: openModal
})(CalendarTableSection);
