import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";

import { required, validEmail } from "../../utils/Validation";
import TextInput from "../common/form/TextInput";
import FormTitle from "../common/form/FormTitle";
import { callApi } from "../../utils/ContentoApi";
import AsyncSelect from "react-select/async";
import Select from "../common/form/Select";
import { generatePassword } from "../../utils/Password";

const searchAccounts = async inputValue => {
  const accountResult = await callApi({
    method: "get",
    url: `/accounts?all=true&q=${inputValue ? inputValue : ""}`
  });

  return accountResult.rows.map(account => ({
    value: account,
    label: account.companyName
  }));
};

class AccountPicker extends Component {
  state = { selectedAccount: null };

  render() {
    let {
      input: { onChange, value },
      error,
      touched
    } = this.props;
    return (
      <div className="form-group m-form__group row">
        <label className="col-2 col-form-label">Accounts</label>
        <div className="col-7">
          <div className="m-list-search">
            <div className="m-list-search__results">
              {value &&
                value.map((account, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingBottom: 20
                    }}
                  >
                    <span style={{ width: "30%" }}>
                      <img
                        className="m--img-rounded"
                        src={account.logo}
                        title=""
                        alt={account.companyName}
                        style={{ maxWidth: 50 }}
                      />
                    </span>
                    <span style={{ width: "30%" }}>{account.companyName}</span>
                    <div style={{ width: "30%" }}>
                      <button
                        type="button"
                        onClick={() => {
                          const updatedValues = [...value];
                          updatedValues.splice(index, 1);
                          onChange(updatedValues);
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <AsyncSelect
              styles={{
                container: provided => ({
                  ...provided,
                  width: "70%",
                  marginRight: 20
                })
              }}
              loadOptions={searchAccounts}
              onChange={selected =>
                this.setState({ selectedAccount: selected })
              }
              cacheOptions
              defaultOptions
            />
            <button
              type="button"
              onClick={() => {
                if (this.state.selectedAccount) {
                  onChange([...value, this.state.selectedAccount.value]);
                }
              }}
            >
              Add Account
            </button>
          </div>
        </div>
        {error && touched && (
          <div className="form-control-feedback">{error}</div>
        )}
      </div>
    );
  }
}

const UserForm = props => {
  const { handleSubmit, pristine, reset, submitting, handleDelete, change } =
    props;

  return (
    <form
      onSubmit={handleSubmit}
      className="m-form m-form--fit m-form--label-align-right"
      autoComplete="off"
    >
      <div className="m-portlet__body">
        <FormTitle>1. User details</FormTitle>

        <Field
          validate={required}
          label="First Name"
          component={TextInput}
          name="firstName"
        />

        <Field
          validate={required}
          label="Last Name"
          component={TextInput}
          name="lastName"
        />

        <Field
          validate={[required, validEmail]}
          label="Email"
          component={TextInput}
          name="email"
        />

        <Field
          validate={[]}
          label="Password"
          component={TextInput}
          name="password"
        />

        <button
          type="button"
          onClick={() => {
            change("password", generatePassword(6, 4, 2));
          }}
        >
          Generate Password
        </button>

        <Field
          validate={[required]}
          label="Role"
          component={MultipleSelect}
          options={[
            { value: "user", label: "User" },
            { value: "admin", label: "Admin" },
            { value: "tester", label: "Tester" }
          ]}
          name="roles"
        />

        <div className="m-form__seperator m-form__seperator--dashed m-form__seperator--space-2x" />

        <FormTitle>2. Account access</FormTitle>

        <Field name="accounts" component={AccountPicker} />
      </div>
      <div className="m-portlet__foot m-portlet__foot--fit">
        <div className="m-form__actions">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-7">
              <button
                type="submit"
                className="btn btn-accent m-btn m-btn--air m-btn--custom"
                disabled={submitting}
              >
                Save changes
              </button>
              &nbsp;&nbsp;
              <button
                type="reset"
                className="btn btn-secondary m-btn m-btn--air m-btn--custom"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Cancel
              </button>
            </div>
            <div className="col-2">
              {handleDelete && (
                <button
                  type="button"
                  className="btn btn-danger m-btn m-btn--air m-btn--custom"
                  onClick={() => handleDelete()}
                >
                  Delete account
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

function MultipleSelect(props) {
  const newProps = Object.assign({}, props, {
    multiple: true,
    showDefault: false
  });
  return <Select {...newProps} />;
}

export default reduxForm({
  // a unique name for the form
  form: "userForm",
  enableReinitialize: true
})(UserForm);
