import styled from "styled-components";

export const DeleteButton = styled.button`
  display: inline-block;
  background-color: #ff4d4f1f;
  color: red;
  border: none;
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 14px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;
  margin: 2px;

  &:hover {
    background-color: rgba(255, 192, 203, 1);
    color: white;
  }

  .icon {
    margin-right: 8px;
  }
`;
