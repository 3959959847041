import countryList from "country-list";
import timezoneList from "compact-timezone-list";
import { accountStatuses } from "@hellocontento/contento-common";

export const languages = [
  { value: "en", label: "English" },
  { value: "nl", label: "Nederlands" },
  { value: "fr", label: "Francais" },
  { value: "es", label: "Espagnol" }
];

export const features = [
  { value: "curation", label: "Manual Curation" },
  { value: "creation", label: "Creation" },
  { value: "blog_writing", label: "Blog Writing" },
  { value: "cta", label: "Call-to-Action" },
  { value: "composer_v2", label: "New Composer" },
  { value: "willow_ai", label: "Willow AI" }
];

export const accountStatusOptions = Object.values(accountStatuses).map(v => ({
  label: v,
  value: v
}));

export const accountBillingStatusOptions = [
  {
    label: "Future",
    value: "future"
  },
  {
    label: "In Trial",
    value: "in_trial"
  },
  {
    label: "Active",
    value: "active"
  },
  {
    label: "Non Renewing",
    value: "non_renewing"
  },
  {
    label: "Paused",
    value: "paused"
  },
  {
    label: "Cancelled",
    value: "cancelled"
  }
];

const countryData = countryList.getData();

export const countries = countryData.map(country => {
  return { value: country.code, label: country.name };
});

export const timezones = timezoneList.map(tz => {
  return { value: tz.tzCode, label: tz.label };
});

export const WEEK_DAYS = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday"
};

export const googleCampaignStatuses = [
  { value: "draft", label: "Draft" },
  { value: "waiting_for_approval", label: "Needs Approval" },
  { value: "active", label: "Active" },
  { value: "disabled", label: "Paused" }
];
