import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { callApi } from "../../utils/ContentoApi";
import classNames from "classnames";

const searchUsers = async inputValue => {
  const userResult = await callApi({
    method: "get",
    url: `/users?q=${inputValue ? inputValue : ""}&role=admin`
  });

  return userResult.map(getUserOption);
};

const getUserOption = user => {
  return {
    value: user.id,
    label: `${user.fullName} (${user.email})`
  };
};

const UserSelect = ({
  input: { value, onChange },
  label,
  meta: { touched, error, warning },
  fullWidth,
  user
}) => {
  const [selectedOption, setSelectedOption] = useState(
    user ? getUserOption(user) : null
  );

  const groupClasses = classNames({
    "form-group m-form__group row": true,
    "has-danger": touched && error,
    "has-warning": touched && warning
  });

  const inputWrapperClasses = classNames({
    "col-7": !fullWidth,
    "col-10": fullWidth
  });

  return (
    <div className={groupClasses}>
      {label && <label className="col-2 col-form-label">{label}</label>}
      <div className={inputWrapperClasses}>
        <AsyncSelect
          styles={{
            container: provided => ({
              ...provided,
              width: "70%",
              marginRight: 20
            })
          }}
          value={selectedOption}
          onChange={selected => {
            onChange(selected.value);
            setSelectedOption(selected);
          }}
          loadOptions={searchUsers}
          cacheOptions
          defaultOptions
        />
        {error && touched && (
          <div className="form-control-feedback">{error}</div>
        )}
      </div>
    </div>
  );
};

export default UserSelect;
