import styled, { css } from "styled-components";

interface PageButtonProps {
  active: boolean;
}

export const PageButton = styled.button<PageButtonProps>`
  display: inline-block;
  background-color: ${props => (props.active ? "#e6efff" : "transparent")};
  color: ${props => (props.active ? "#0066cc" : "#000")};
  border: none;
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 14px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;
  margin: 2px;

  &:hover {
    background-color: rgba(135, 206, 250, 0.9);
    color: white;
  }

  ${props =>
    props.active &&
    css`
      background-color: #e6efff;
      color: #0066cc;
    `}
`;
