import React from "react";
import { iconStyles } from "../../theme";

const ProfileIcon = ({ channel, onClick, disabled, badgeOnly }) => {
  const iconStyle = iconStyles[channel.service.toUpperCase()];

  const badgeStyle = {
    position: "absolute",
    right: 0,
    bottom: 0,
    backgroundColor: iconStyle.color,
    color: "white",
    borderRadius: "100%",
    opacity: disabled ? 0.4 : 1,
    width: 25,
    height: 25,
    fontSize: 16,
    paddingTop: 4,
    textAlign: "center"
  };

  const profileStyle = {
    position: "relative",
    display: "inline-block",
    cursor: onClick ? "pointer" : null,
    width: 50,
    marginRight: 20
  };

  const imageStyle = {
    opacity: disabled ? 0.4 : 1
  };

  return (
    <div title={channel.username} style={profileStyle} onClick={onClick}>
      <span style={badgeStyle}>
        <i className={iconStyle.iconClass} />
      </span>
      <img
        style={imageStyle}
        src={channel.avatar}
        alt=""
        width="50"
        height="50"
      />
    </div>
  );
};

export default ProfileIcon;
