import React from "react";

import { connect } from "react-redux";
import qs from "qs";
import { callApi } from "../../../utils/ContentoApi";
import { toastr } from "react-redux-toastr";
import * as modalActions from "../../../state/actions/ModalActions";
import RecommendationsList from "../../../components/recommendations/RecommendationsList";

class AccountSocialSuggestedPage extends React.Component {
  state = {
    suggestedContent: [],
    isDeleting: null,
    isNotifying: false
  };

  componentDidMount() {
    this.refreshSuggestedContent();
  }

  refreshSuggestedContent() {
    callApi({
      method: "get",
      url: `/accounts/${this.props.account.id}/contents/suggested${qs.stringify(
        this.state.filters,
        {
          addQueryPrefix: true
        }
      )}`
    })
      .then(result => {
        const items = result.map(item => {
          //Map to old structure
          return {
            _id: item.id,
            _source: item
          };
        });
        this.setState({ suggestedContent: items });
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({ suggestedContent: [] });
      });
  }

  handleDismiss(contentId, content) {
    this.setState({ isDeleting: contentId });

    callApi({
      method: "delete",
      url: `/accounts/${this.props.account.id}/contents/${contentId}`
    })
      .then(() => {
        toastr.success(`Successfully dismissed content.`);
        this.refreshSuggestedContent();
        this.setState({ isDeleting: null });
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({ isDeleting: null });
      });
  }

  handleNotify = () => {
    if (this.state.isNotifying) {
      return;
    }

    this.setState({ isNotifying: true });
    callApi({
      method: "post",
      url: `/accounts/${this.props.account.id}/notifications`,
      data: {
        templateName: "content_suggestions",
        medium: "email"
      }
    })
      .then(() => {
        toastr.success(`Successfully notified users.`);
        this.setState({ isNotifying: false });
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({ isNotifying: false });
      });
  };

  render() {
    const { suggestedContent } = this.state;

    return (
      <div className="m-portlet__body">
        <div style={{ marginBottom: "10px" }}>
          <button
            onClick={this.handleNotify}
            className="btn btn-secondary m-btn m-btn--icon m-btn--pill"
          >
            <span>
              <i className="fa flaticon-alert" />
              <span>Notify account</span>
            </span>
          </button>
        </div>
        <RecommendationsList
          results={suggestedContent}
          onDismissContent={this.handleDismiss.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    account: state.accounts.selectedAccount
  };
};

export default connect(mapStateToProps, {
  openModal: modalActions.openModal
})(AccountSocialSuggestedPage);
