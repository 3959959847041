import React from "react";
import { CalendarStatus } from "containers/calendars/CalendarStatusFilter";
import { FormStages } from "./CalendarEditorModal";
import { useForm } from "react-hook-form";
import { maxLength, required } from "utils/Validation";
import { ModalBody } from "../styles";
import StyledTextInput from "components/common/form/StyledTextInput";
import CalendarStatusSelector from "./CalendarStatusSelector";
import CalendarCategorySelector from "./CalendarCategorySelector";
import CalendarImageInput from "./CalendarImageInput";
import CalendarEditorFooter from "./CalendarEditorFooter";

export interface CalendarDetailsFormInputs {
  calendarName: string;
  level: CalendarStatus;
  description: string;
  category: string;
  image: string;
}

type Props = {
  setCurrentStage: (newStage: FormStages) => void;
  onSubmit: (values: CalendarDetailsFormInputs) => void;
  calendarDetails?: CalendarDetailsFormInputs;
  calendarCategories: string[];
};

const CalendarDetailsForm = ({
  calendarCategories,
  calendarDetails,
  onSubmit,
  setCurrentStage
}: Props) => {
  const { handleSubmit, control } = useForm<CalendarDetailsFormInputs>({
    defaultValues: calendarDetails ?? {
      level: CalendarStatus.inactive,
      category: "Other"
    }
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        <StyledTextInput
          control={control}
          label="Calendar Name"
          name="calendarName"
          placeholder="Example... International Holidays"
          helperText="Be as specific as possible with the name of your calendar. Write in English. "
          required
          rules={{
            validate: {
              required
            }
          }}
        />

        <CalendarStatusSelector
          control={control}
          required
          name="level"
          label="Calendar Status"
          helperText="This will determine the calendars visibility in the product. Default status is Inactive."
          defaultValue={calendarDetails?.level}
          rules={{
            validate: {
              required
            }
          }}
        />

        <StyledTextInput
          control={control}
          required
          label="Calendar Description"
          name="description"
          placeholder="Example...Public holiday dates from across the globe"
          helperText="Briefly explain the contents of the calendar. "
          maxLength={65}
          rules={{
            validate: {
              required,
              maxLength: val => maxLength(val, 65)
            }
          }}
        />

        <CalendarCategorySelector
          control={control}
          required
          name="category"
          label="Calendar Category"
          helperText="If applicable, select the industry this calendar is specifically made for."
          categories={calendarCategories}
          rules={{
            validate: {
              required
            }
          }}
        />

        <CalendarImageInput
          control={control}
          required
          name="image"
          label="Calendar Cover Image"
          rules={{
            validate: {
              required
            }
          }}
        />
      </ModalBody>

      <CalendarEditorFooter
        currentStage={FormStages.CALENDAR_DETAILS}
        setCurrentStage={setCurrentStage}
        actionButtonText="Save & Continue"
        showBackButton={false}
      />
    </form>
  );
};

export default CalendarDetailsForm;
