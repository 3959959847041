import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import PageSelection from "components/operations/PageSelection";
import ChartSection from "components/operations/ChartSection";
import OperationFilterSection from "components/operations/OperationFilterSection";
import OperationsTable from "components/operations/OperationsTable";
import ChannelIssues from "components/operations/ChannelIssues";

interface CurrentUser {
  id: string;
  name: string;
  email: string;
}

interface AuthState {
  currentUser: CurrentUser | null;
}

interface AppState {
  auth: AuthState;
}

const OperationsPage = ({ currentUser }: PropsFromRedux) => {
  const [activePage, setActivePage] = useState(1);
  const [selectedTypeFilters, setSelectedTypeFilters] = useState<string[]>([
    "All"
  ]);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState<string>("All");
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleTypeFilterChange = (filters: string[]) => {
    setSelectedTypeFilters(filters);
  };

  const handleTimeFilterChange = (filter: string) => {
    setSelectedTimeFilter(filter);
  };

  const handleSearchChange = (term: string) => {
    setSearchTerm(term);
  };

  return (
    <>
      <PageSelection activePage={activePage} setActivePage={setActivePage} />

      {activePage === 1 && (
        <>
          <ChartSection />
          <OperationFilterSection
            onFilterChange={handleTypeFilterChange}
            onTimeFilterChange={handleTimeFilterChange}
            onSearchChange={handleSearchChange}
          />
          <OperationsTable
            selectedTypeFilters={selectedTypeFilters}
            selectedTimeFilter={selectedTimeFilter}
            searchTerm={searchTerm}
          />
        </>
      )}
      {activePage === 2 && <ChannelIssues />}
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    currentUser: state.auth.currentUser
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OperationsPage);
