import * as suggestedPostActions from "../actions/SuggestedPostActions";

const initialState = {
  data: [],
  isFetching: false
};

const suggestedPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case suggestedPostActions.FETCH_SUGGESTED_POSTS:
      return Object.assign({}, state, {
        data: [],
        isFetching: true
      });
    case suggestedPostActions.SUGGESTED_POSTS_FETCHED:
      return Object.assign({}, state, {
        data: action.suggestedPosts,
        isFetching: false
      });
    default:
      return state;
  }
};

export default suggestedPostReducer;
