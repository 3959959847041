import React, { ReactNode, useState } from "react";
import UploadZone from "./UploadZone";

type Props = {
  onUpload: (files: any) => void;
  onUploadStart?: Function;
  onChange?: Function;
  /** View or component to display when no file is selected. Defaults to a plus icon  */
  idleStateView?: ReactNode;
  width?: string;
  height?: string;
  border?: string;
  multiple?: boolean;
  accept: string;
};

const FileUpload = ({
  onUpload,
  onUploadStart,
  onChange,
  idleStateView,
  width,
  height,
  border,
  multiple,
  accept
}: Props) => {
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const onDrop = (files: File[]) => {
    // console.log("Files:: ", files);
    setIsUploading(true);
    if (onUploadStart) {
      onUploadStart();
    }
    setIsUploading(false);

    if (onUpload) {
      onUpload(files);
    }

    if (onChange) {
      onChange(files[0].name);
    }
  };

  return (
    <UploadZone
      accept={accept}
      isUploading={isUploading}
      progress={progress}
      onDrop={onDrop}
      idleStateView={idleStateView}
      width={width}
      height={height}
      border={border}
      multiple={multiple}
    />
  );
};

export default FileUpload;
