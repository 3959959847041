import React, { useState, useEffect } from "react";
import moment from "moment";
import { accountStatuses } from "@hellocontento/contento-common";

import Portlet from "../../components/common/portlet/Portlet";
import { Link } from "react-router-dom";
import Badge from "../../components/common/Badge";
import CountBadge from "../../components/common/CountBadge";

import FallbackImage from "../../components/common/FallbackImage";
import AccountsFilter from "../../components/accounts/AccountsFilter";
import AccountsHeader from "../../components/accounts/AccountsHeader";
import emptyProfileIcon from "../../assets/images/profile-icon.png";
import Pagination from "../../components/common/Pagination";
import { useContentoApi } from "../../utils/useContentoApi";

const AccountsOverviewPage = () => {
  const [filters, setFilters] = useState({ status: accountStatuses.ACTIVE });
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 25,
    orderBy: ["updatedAt", "DESC"]
  });
  const [accountsResult, setAccountsResult] = useState();

  const [searchAccounts] = useContentoApi("/accounts");

  useEffect(() => {
    setLoading(true);
    searchAccounts({
      params: {
        filters,
        all: true,
        ...pagination
      }
    })
      .then(setAccountsResult)
      .finally(() => setLoading(false));
  }, [filters, searchAccounts, pagination]);

  const handleSortChange = orderBy => {
    setPagination({ limit: pagination.limit, offset: 0, orderBy: orderBy });
  };

  console.log(accountsResult);

  return (
    <div>
      <div className="m-subheader ">
        <div className="d-flex align-items-center">
          <div className="mr-auto">
            <h3 className="m-subheader__title">Accounts</h3>
          </div>
        </div>
      </div>
      <div className="m-content">
        <Portlet>
          <AccountsFilter
            filters={filters}
            onChange={setFilters}
            loading={loading}
          />
          <div className="dataTables_wrapper">
            <div className="row">
              <table className="table m-table m-table--head-bg-success">
                <thead>
                  <AccountsHeader onSortChange={handleSortChange} />
                </thead>
                <tbody>
                  {accountsResult &&
                    accountsResult.rows.map(account => (
                      <tr key={account.id}>
                        <td>
                          <FallbackImage
                            src={account.logo}
                            fallbackSrc={emptyProfileIcon}
                            alt={account.companyName}
                            style={{ height: 50, maxWidth: 100 }}
                          />
                        </td>
                        <td>
                          <Link to={`/accounts/${account.id}`}>
                            {account.companyName}
                          </Link>
                        </td>
                        <td>{account.status}</td>
                        <td>{account.billing?.billingStatus}</td>
                        <td>
                          {account.lastUserTokenRefresh
                            ? moment(account.lastUserTokenRefresh).fromNow()
                            : "N/A"}
                        </td>
                        <td>
                          {account.lastSuggestionDate
                            ? moment(account.lastSuggestionDate).fromNow()
                            : "N/A"}
                        </td>
                        <td>
                          <CountBadge count={account.suggestedCount} />
                        </td>
                        <td>
                          {account.channels.map(channel => {
                            return <Badge key={channel.id} channel={channel} />;
                          })}
                        </td>
                        <td>
                          {account.accountManagerData
                            ? `${account.accountManagerData.fullName}`
                            : ""}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {accountsResult && (
              <Pagination
                page={pagination.offset / pagination.limit + 1}
                totalPages={accountsResult.count / pagination.limit}
                limit={pagination.limit}
                onSelectLimit={limit => {
                  setPagination({ ...pagination, limit });
                }}
                onSelectPage={page => {
                  setPagination({
                    ...pagination,
                    offset: (page - 1) * pagination.limit
                  });
                }}
                loading={loading}
              />
            )}
          </div>
        </Portlet>
      </div>
    </div>
  );
};

export default AccountsOverviewPage;
