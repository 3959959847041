import React from "react";
import { Field, reduxForm } from "redux-form";
import { required, validEmail } from "../../utils/Validation";
import TextInput from "../../components/common/form/TextInput";

const LoginForm = ({ handleSubmit }) => {
  return (
    <div className="form-group">
      <div className="col-sm-12 col-lg-6">
        <form
          onSubmit={handleSubmit}
          className="m-form m-form--fit m-form--label-align-right"
        >
          <div>
            <Field
              validate={[required, validEmail]}
              label="Email"
              component={TextInput}
              name="email"
              fullWidth
            />
            <Field
              type="password"
              validate={[required]}
              label="Password"
              component={TextInput}
              name="password"
              fullWidth
            />
            <div className="m-form__seperator m-form__seperator--dashed m-form__seperator--space-2x" />
            <div className="col text-center">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Log in
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default reduxForm({
  // a unique name for the form
  form: "loginForm",
  enableReinitialize: true
})(LoginForm);
