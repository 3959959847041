import StyledTextInput from "components/common/form/StyledTextInput";
import React from "react";
import { required, validEmail, validWebsite } from "utils/Validation";
import CreateAccountModalFooter from "./CreateAccountModalFooter";
import { IAdmin as IUser, Stages } from "./CreateAccountModal";
import { useForm } from "react-hook-form";

import { ModalBody } from "../styles";
import AdminSelector from "./AdminSelector";
import UserSelector from "./UserSelector";

type CustomerDetailsFormProps = {
  setCurrentStage: (newStage: Stages) => void;
  onSubmit: (values: CustomerInfoFormInputs) => void;
  admins: IUser[];
  isLoadingAdmins: boolean;
  customerInfo?: CustomerInfoFormInputs;
  forExistingUser: boolean;
};

export interface CustomerInfoFormInputs {
  companyName: string;
  website: string;
  userFirstName: string;
  userLastName: string;
  email: string;
  accountManager: IUser;
  willowUser: IUser;
}

/**
 * The [forExistingUser] variable is used to signify if we are creating the account for an exising user or a new user
 * When creating for a new user, a user is created simultanously as the account and both are linked.
 * When creating for an existing user, admin is required to signify the user for which the account is being created
 */
const CustomerDetailsForm = ({
  setCurrentStage,
  admins,
  onSubmit,
  customerInfo,
  forExistingUser
}: CustomerDetailsFormProps) => {
  const { handleSubmit, control } = useForm<CustomerInfoFormInputs>({
    defaultValues: customerInfo
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        <Title forExistingUser={forExistingUser} />

        <StyledTextInput
          control={control}
          label="Company Name"
          name="companyName"
          placeholder="Acme Inc."
          required
          rules={{
            validate: {
              required
            }
          }}
        />

        <StyledTextInput
          control={control}
          label="Website URL"
          name="website"
          placeholder="https://www.acme.inc"
          required
          rules={{
            validate: {
              required,
              validWebsite: validWebsite as any
            }
          }}
        />

        {forExistingUser && (
          <UserSelector
            control={control}
            name="willowUser"
            label="Willow User"
            required
            rules={{
              validate: {
                required
              }
            }}
          />
        )}

        {!forExistingUser && (
          <>
            <StyledTextInput
              control={control}
              label="Customer First Name"
              name="userFirstName"
              placeholder="Jane"
              required
              rules={{
                validate: {
                  required
                }
              }}
            />

            <StyledTextInput
              control={control}
              label="Customer Last Name"
              name="userLastName"
              placeholder="Doe"
              required
              rules={{
                validate: {
                  required
                }
              }}
            />

            <StyledTextInput
              control={control}
              type="email"
              label="Email"
              name="email"
              placeholder="john.appleseed@acme.inc"
              required
              rules={{
                validate: {
                  required,
                  validEmail: validEmail as any
                }
              }}
            />
          </>
        )}

        <AdminSelector
          control={control}
          name="accountManager"
          label="Account Manager"
          placeholder="Select an Account Manager"
          admins={admins}
        />
      </ModalBody>

      <CreateAccountModalFooter
        currentStage={Stages.ACCOUNT_PREPARATION}
        setCurrentStage={setCurrentStage}
        actionButtonText="Continue"
        showBackButton={false}
      />
    </form>
  );
};

const Title = ({ forExistingUser }: { forExistingUser: boolean }) => (
  <div style={{ display: "inline-block", marginBottom: "20px" }}>
    {forExistingUser && (
      <span style={{ fontWeight: "700" }}>
        This form is only for those who are already a Willow customer.{" "}
      </span>
    )}
    <span>
      Once completed, the customer will self onboard. Please ensure the details
      are correct before sending this out.
    </span>
  </div>
);

export default CustomerDetailsForm;
