import React from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { Flex } from "rebass";
import { closeModal } from "../../../state/actions/ModalActions";
import { modalStyles } from "../../modals/styles";
import { ArticleContentWrapper } from "./styles";

class ArticleContentModal extends React.Component {
  render() {
    const { closeModal, record } = this.props;
    return (
      <Modal
        style={modalStyles("60vw")}
        isOpen={true}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <Flex flexDirection="column">
          <h1>{record.title}</h1>
          <ArticleContentWrapper
            dangerouslySetInnerHTML={{ __html: record.article_html }}
          />
        </Flex>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  closeModal: closeModal
})(ArticleContentModal);
