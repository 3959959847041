import React from "react";
import Select from "react-select";
import ReactLoading from "react-loading";
import {
  accountStatusOptions,
  accountBillingStatusOptions
} from "../../constants";

class AccountsFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { q: "" };

    this.handleChangeQ = this.handleChangeQ.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleChangeQ(event) {
    this.setState({ q: event.target.value });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.props.onChange({ ...this.props.filters, q: this.state.q });
      event.preventDefault();
    }
  }

  handleChangeStatus = selected => {
    this.props.onChange({ ...this.props.filters, status: selected.value });
  };

  handleChangeBillingStatus = selected => {
    this.props.onChange({
      ...this.props.filters,
      billingStatus: selected.value
    });
  };

  render() {
    const { filters } = this.props;

    return (
      <form style={{ position: "relative" }}>
        <div style={{ position: "absolute", top: 10, right: 5 }}>
          {this.props.loading && (
            <ReactLoading color="#777" type="spin" width={30} />
          )}
        </div>
        <div className="form-group m-form__group row align-items-center">
          <div className="col-md-3">
            <div className="m-form__group m-form__group--inline">
              <div className="m-form__label">
                <label className="m-label m-label--single">Company name:</label>
              </div>
              <div className="m-input-icon m-input-icon--left">
                <input
                  name="q"
                  value={this.state.q}
                  onChange={this.handleChangeQ}
                  onKeyDown={this.handleKeyDown}
                  type="text"
                  className="form-control m-input"
                  placeholder="Search"
                />
                <span className="m-input-icon__icon m-input-icon__icon--left">
                  <span>
                    <i className="fa fa-search"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="m-form__group m-form__group--inline">
              <div className="m-form__label">
                <label className="m-label m-label--single">Status:</label>
              </div>
              <div className="m-form__control">
                <Select
                  options={[
                    { value: null, label: "All" },
                    ...accountStatusOptions
                  ]}
                  defaultValue={{
                    value: filters.status,
                    label: filters.status
                  }}
                  onChange={this.handleChangeStatus}
                />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="m-form__group m-form__group--inline">
              <div className="m-form__label">
                <label className="m-label m-label--single">
                  Billing Status:
                </label>
              </div>
              <div className="m-form__control">
                <Select
                  options={[
                    { value: null, label: "All" },
                    ...accountBillingStatusOptions
                  ]}
                  defaultValue={{
                    value: null,
                    label: "All"
                  }}
                  onChange={this.handleChangeBillingStatus}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default AccountsFilter;
