import React from "react";
import Button from "components/common/Button";
import { connect } from "react-redux";
import { openModal } from "state/actions/ModalActions";
import { Flex } from "rebass/styled-components";
import { MODALS } from "components/modals/ModalRoot";

const CreateCalendarButton = ({ openModal }: { openModal: any }) => {
  return (
    <Button
      onClick={() => openModal(MODALS.CALENDAR_EDITOR_MODAL)}
      data-tip={"New Calendar"}
      marginTop={"30px"}
      borderRadius={"16"}
    >
      <Flex style={{ gap: "8px", alignItems: "center" }}>
        <span>+ Create New Calendar</span>
      </Flex>
    </Button>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

export default connect(mapStateToProps, {
  openModal: openModal
})(CreateCalendarButton);
