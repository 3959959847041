import React from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { closeModal } from "../../../state/actions/ModalActions";
import { modalStyles } from "../../modals/styles";

class ArticleDebugModal extends React.Component {
  render() {
    const { closeModal, record } = this.props;
    return (
      <Modal
        style={modalStyles("60vw")}
        isOpen={true}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <textarea cols="90" rows="40" readOnly>
          {JSON.stringify(
            { ...record, article_html: "<contents hidden>" },
            null,
            4
          )}
        </textarea>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  closeModal: closeModal
})(ArticleDebugModal);
