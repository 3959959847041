import React from "react";

const FormTitle = ({ children }) => {
  return (
    <div className="m-form__heading">
      <h3 className="m-form__heading-title">{children}</h3>
    </div>
  );
};
export default FormTitle;
