import React from "react";

import { Link } from "react-router-dom";
import FallbackImage from "../../components/common/FallbackImage";
import emptyProfileIcon from "../../assets/images/profile-icon.png";
import { getTimeDifferenceElement } from "../../utils/date";
import Auth from "../../utils/Auth";

import PaginatedAccountList from "./PaginatedAccountList";

const SEARCH_PERIOD_IN_DAYS = 7;

export default function InactiveAccounts(props) {
  const tableHeader = (
    <tr>
      <th style={{ width: "10%" }} />
      <th style={{ width: "25%" }}>Name</th>
      <th style={{ width: "25%", textAlign: "center" }}>Last published post</th>
      <th style={{ width: "20%", textAlign: "center" }}>Last sign in</th>
      <th style={{ width: "10%", textAlign: "center" }} />
    </tr>
  );

  const handleButtonClick = accountId => {
    return Auth.impersonateCustomer(accountId, props.currentUser.id);
  };

  const buildRecord = account => (
    <tr key={account.id} style={{ borderBottom: "1px solid #eee" }}>
      <td
        style={{
          verticalAlign: "middle",
          textAlign: "center"
        }}
      >
        <FallbackImage
          src={account.logo || emptyProfileIcon}
          alt={account.companyName}
          height={50}
          width={50}
        />
      </td>
      <td style={{ verticalAlign: "middle" }}>
        <Link to={`/accounts/${account.id}`}>{account.companyName}</Link>
      </td>
      <td
        style={{
          verticalAlign: "middle",
          textAlign: "center"
        }}
      >
        {getTimeDifferenceElement({
          date: account.latestPublishedPostDate
        })}
      </td>
      <td
        style={{
          verticalAlign: "middle",
          textAlign: "center"
        }}
      >
        {getTimeDifferenceElement({
          date: account.lastUserTokenRefresh
        })}
      </td>
      <td
        style={{
          verticalAlign: "middle",
          textAlign: "center"
        }}
      >
        <button
          onClick={() => handleButtonClick(account.id)}
          className="m-btn m-btn--pill m-btn--hover-brand btn btn-sm btn-secondary"
        >
          Create Post
        </button>
      </td>
    </tr>
  );

  return (
    <PaginatedAccountList
      {...props}
      title="Inactive accounts (No posts for 7 days)"
      url="/admin/accounts/inactive"
      tableHeader={tableHeader}
      buildRecord={buildRecord}
      extraApiParams={{ periodInDays: SEARCH_PERIOD_IN_DAYS }}
    />
  );
}
