import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Portlet from "../../components/common/portlet/Portlet";
import { Link } from "react-router-dom";
import { callApi } from "../../utils/ContentoApi";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import qs from "qs";

const HangingPostsPage = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    callApi({
      method: "get",
      url: `/posts${qs.stringify(
        {
          status: "SCHEDULED",
          channelActive: true,
          toDate: moment().toISOString()
        },
        { addQueryPrefix: true }
      )}`
    })
      .then(setPosts)
      .catch(error => {
        toastr.error(error.message);
        setPosts([]);
      });
  }, []);

  const deletePost = post => {
    const confirmed = window.confirm("Sure you want to delete this post?");
    if (confirmed) {
      callApi({
        method: "delete",
        url: `/posts/${post.id}`
      })
        .then(() => {
          toastr.success("Deleted post");
        })
        .catch(error => {
          toastr.error(error.message);
        });
    }
  };

  return (
    <div>
      <div className="m-subheader ">
        <div className="d-flex align-items-center">
          <div className="mr-auto">
            <h3 className="m-subheader__title ">Hanging Posts</h3>
          </div>
        </div>
      </div>
      <div className="m-content">
        <Portlet>
          <table className="table m-table m-table--head-bg-success">
            <thead>
              <tr>
                <th>Post Id</th>
                <th>Caption</th>
                <th>Channel Name</th>
                <th>Scheduled At</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {posts.map(post => (
                <tr key={post.id}>
                  <td>{post.id}</td>
                  <td>{post.caption}</td>
                  <td>{post.channel.username}</td>
                  <td>{post.scheduledAt}</td>
                  <td>{post.status}</td>
                  <td>
                    <Link to={`/accounts/${post.channel.accountId}`}>
                      Go to account
                    </Link>
                    <br />
                    <a href="#delete" onClick={() => deletePost(post)}>
                      Delete
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Portlet>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(HangingPostsPage);
