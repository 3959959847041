import styled from "styled-components";

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100%;
  background: #fff;
  margin-bottom: 18px;
  padding: 18px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 8px;
`;

export const TableRow = styled.tr`
  background-color: #f8f9fa;
  &:hover {
    background-color: #e8e8e8;
  }
`;

export const TableHeader = styled.th`
  padding: 8px;
  margin-bottom: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
  background-color: #e6efff;

  &:first-child {
    text-align: left;
  }
`;

export const TableCell = styled.td`
  padding: 16px;
  margin-top: 20px;
  text-align: center;

  &:first-child {
    text-align: left;
  }
`;

export const DeleteButton = styled.button`
  display: inline-block;
  background-color: #ff4d4f1f;
  color: red;
  border: none;
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 14px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;
  margin: 2px;

  &:hover {
    background-color: rgba(255, 192, 203, 1);
    color: white;
  }

  .icon {
    margin-right: 8px;
  }
`;

export const RefreshButton = styled.button`
  display: inline-block;
  background-color: #e6efff;
  color: #0066cc;
  border: none;
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 14px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;
  margin: 2px;

  &:hover {
    background-color: rgba(135, 206, 250, 0.9);
    color: white;
  }

  .icon {
    margin-right: 8px;
  }
`;
