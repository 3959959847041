import React from "react";
import moment from "moment";

import { FilterToolbar, Filter } from "./styles";

export default function SearchActiveFilters({
  facetFilters,
  textFilter,
  onTextSearchRemoved,
  onFacetFilterRemoved,
  onDateFilterChanged,
  dateFilter
}) {
  const badges = [];

  if (dateFilter.from) {
    badges.push(
      <Filter
        onClick={() => onDateFilterChanged("from", null)}
        key="dateFilterFrom"
      >
        start date : {moment(dateFilter.from).format("DD/MM/YYYY")}
      </Filter>
    );
  }

  if (dateFilter.to) {
    badges.push(
      <Filter
        onClick={() => onDateFilterChanged("to", null)}
        key="dateFilterTo"
      >
        end date : {moment(dateFilter.to).format("DD/MM/YYYY")}
      </Filter>
    );
  }

  if (textFilter) {
    badges.push(
      <Filter onClick={() => onTextSearchRemoved()} key="textSearch">
        text : {textFilter}
      </Filter>
    );
  }

  facetFilters.forEach(filter => {
    const { field, value } = filter;
    badges.push(
      <Filter
        onClick={() => onFacetFilterRemoved(filter)}
        key={`${field}=${value}`}
      >
        {field} : {value}
      </Filter>
    );
  });

  return <FilterToolbar>{badges}</FilterToolbar>;
}
