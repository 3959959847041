import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import ChannelIssuesTable from "components/operations/ChannelIssuesTable";
import ChannelIssuesFilter from "components/operations/ChannelIssuesFilter";

// Define the type for CurrentUser
interface CurrentUser {
  id: string;
  name: string;
  email: string;
}

// Define the type for AuthState
interface AuthState {
  currentUser: CurrentUser | null;
}

// Define the type for AppState
interface AppState {
  auth: AuthState;
}

const ChannelIssuesPage = ({ currentUser }: PropsFromRedux) => {
  const [selectedTypeFilters, setSelectedTypeFilters] = useState<string[]>([
    "All"
  ]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleTypeFilterChange = (filters: string[]) => {
    setSelectedTypeFilters(filters);
  };

  const handleSearchChange = (term: string) => {
    setSearchTerm(term);
  };

  return (
    <>
      <ChannelIssuesFilter
        onFilterChange={handleTypeFilterChange}
        onSearchChange={handleSearchChange}
      />
      <ChannelIssuesTable
        selectedTypeFilters={selectedTypeFilters}
        searchTerm={searchTerm}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    currentUser: state.auth.currentUser
  };
};

// Create a connector
const connector = connect(mapStateToProps);

// Create a type for the props from Redux
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ChannelIssuesPage);
