import React from "react";
import moment from "moment";
import { ExclamationTriangle } from "styled-icons/fa-solid";

const Badge = ({ channel }) => {
  const { service, lastScheduledPostTime, channelConnections } = channel;

  let badgeClass = "";
  const daysScheduled = moment(lastScheduledPostTime).diff(
    moment(),
    "days",
    true
  );
  if (!lastScheduledPostTime || daysScheduled <= 0) {
    badgeClass = "danger";
  } else if (daysScheduled <= 7) {
    badgeClass = "warning";
  } else {
    badgeClass = "success";
  }

  let dateMessage = "None so far.";
  if (lastScheduledPostTime) {
    const days = moment(lastScheduledPostTime).diff(moment(), "days");
    const date = moment(lastScheduledPostTime).format("MMM D");
    const day = days === 1 || days === -1 ? "day" : "days";
    dateMessage = `${date} (${
      days >= 0 ? `in ${days} ${day}` : `${Math.abs(days)} ${day} ago`
    })`;
  }

  let hasIssues = false;

  channelConnections.forEach(connection => {
    hasIssues =
      hasIssues ||
      (connection.disabledUntil &&
        moment(connection.disabledUntil).isAfter(Date.now())) ||
      ["AUTH_ERROR", "PERMISSION_REQUIRED"].includes(connection.lastErrorType);
  });

  return (
    <span
      style={{ margin: 5, whiteSpace: "nowrap" }}
      className={`m-badge m-badge--${badgeClass} m-badge--wide`}
      title={`Last scheduled content: ${dateMessage}`}
    >
      {hasIssues && (
        <ExclamationTriangle style={{ marginRight: 5 }} size={12} />
      )}
      {service}
    </span>
  );
};

export default Badge;
