import React from "react";
import range from "lodash/range";
import ReactLoading from "react-loading";

const Pagination = ({
  onSelectPage,
  onSelectLimit,
  totalPages,
  page,
  limit,
  loading = false
}) => {
  const leftBound = page - 2;
  const rightBound = page + 2;
  const lastPage = totalPages;

  const startPage = leftBound > 1 ? leftBound : 1;
  const endPage = rightBound < lastPage ? rightBound : lastPage;

  const pages = range(startPage, endPage + 1);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-5">
        <div
          className="dataTables_info"
          id="m_table_1_info"
          role="status"
          aria-live="polite"
        >
          Showing {page * limit - limit} to {page * limit} of{" "}
          {totalPages * limit} entries
        </div>
      </div>
      <div className="col-sm-12 col-md-7 dataTables_pager">
        <div
          className="dataTables_length"
          id="m_table_1_length"
          style={{ position: "relative" }}
        >
          {loading && (
            <div style={{ position: "absolute", left: "-40px", top: 5 }}>
              <ReactLoading color="#777" type="spin" width={20} />
            </div>
          )}

          <label>
            Display&nbsp;&nbsp;&nbsp;&nbsp;
            <select
              name="m_table_1_length"
              onChange={event => {
                onSelectLimit(event.target.value);
              }}
              value={limit}
              className="form-control form-control-sm"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </label>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers"
          id="m_table_1_paginate"
        >
          <ul className="pagination">
            {page > 1 && (
              <li
                className="paginate_button page-item previous"
                id="m_table_1_previous"
              >
                <a
                  href="#page-link"
                  onClick={e => {
                    e.preventDefault();
                    onSelectPage(page - 1);
                  }}
                  aria-controls="m_table_1"
                  data-dt-idx="0"
                  tabIndex="0"
                  className="page-link"
                >
                  <i className="la la-angle-left" />
                </a>
              </li>
            )}
            {pages.map((p, index) => {
              return (
                <li
                  key={index}
                  className={`paginate_button page-item ${
                    p === page ? "active" : ""
                  }`}
                >
                  <a
                    href="#page-link"
                    onClick={e => {
                      e.preventDefault();
                      onSelectPage(p);
                    }}
                    className="page-link"
                  >
                    {p}
                  </a>
                </li>
              );
            })}

            {page < totalPages && (
              <li className="paginate_button page-item next">
                <a
                  onClick={e => {
                    e.preventDefault();
                    onSelectPage(page + 1);
                  }}
                  href="#page-link"
                  className="page-link"
                >
                  <i className="la la-angle-right" />
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Pagination;
