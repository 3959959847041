import React from "react";
import { Field, reduxForm } from "redux-form";
import { required, validEmail, validWebsite } from "../../utils/Validation";
import Select from "../common/form/Select";
import TextInput from "../common/form/TextInput";
import FormTitle from "../common/form/FormTitle";
import {
  languages,
  countries,
  timezones,
  features,
  accountStatusOptions
} from "../../constants";
import TextAreaInput from "../common/form/TextAreaInput";
import UserSelect from "./UserSelect";
import Checkboxes from "../common/form/Checkboxes";
import telenetLogo from "../../assets/images/telenet-logo.png";

const CompanyProfileForm = props => {
  const { handleSubmit, pristine, reset, submitting, handleDelete } = props;

  return (
    <form
      onSubmit={handleSubmit}
      className="m-form m-form--fit m-form--label-align-right"
    >
      <div className="m-portlet__body">
        <FormTitle>1. Company details</FormTitle>

        <Field
          validate={required}
          label="Company name"
          component={TextInput}
          name="companyName"
        />
        <Field
          validate={[required, validWebsite]}
          label="Website"
          component={TextInput}
          name="companyWebsite"
        />
        <Field
          validate={[required]}
          label="Status"
          component={Select}
          options={accountStatusOptions}
          name="status"
        />

        <Field
          validate={[required, validEmail]}
          label="Email"
          component={TextInput}
          name="email"
        />
        <Field label="Phone" component={TextInput} name="phone" />

        <Field
          label="Timezone"
          validate={[required]}
          component={Select}
          options={timezones}
          showDefault={false}
          name="timezone"
        />
        <Field
          label="Language"
          component={Select}
          options={languages}
          name="language"
        />
        <Field label="Logo" component={TextInput} name="logo" />
        <Field
          label="Description"
          component={TextAreaInput}
          name="companyDescription"
        />

        <div className="m-form__seperator m-form__seperator--dashed m-form__seperator--space-2x" />

        <FormTitle>2. Billing address</FormTitle>

        <Field
          label="Billing address"
          component={TextInput}
          name="billingAddress"
        />
        <Field label="Billing city" component={TextInput} name="billingCity" />
        <Field
          label="Billing zip"
          component={TextInput}
          name="billingZipCode"
        />
        <Field
          label="Billing state"
          component={TextInput}
          name="billingState"
        />
        <Field
          label="Billing country"
          component={Select}
          options={countries}
          name="billingCountry"
        />

        <FormTitle>3. Account manager</FormTitle>
        <Field
          validate={required}
          label="Account Manager"
          component={UserSelect}
          user={props.initialValues.accountManagerData}
          name="accountManagerId"
        />
        <FormTitle>4. Chargebee</FormTitle>
        <Field
          label="Chargebee Customer ID"
          component={TextInput}
          name="chargebeeCustomerId"
        />
        <Field
          label="Chargebee Subscription ID"
          component={TextInput}
          name="chargebeeSubscriptionId"
        />
        <FormTitle>5. Features</FormTitle>
        <Field
          label="Features"
          component={Checkboxes}
          options={features.filter(
            feature =>
              !props.initialValues.isTelenetCustomer ||
              !["willow_ai", "composer_v2"].includes(feature.value)
          )}
          name="features"
        />
        {props.initialValues.isTelenetCustomer && (
          <>
            <FormTitle>
              <img
                src={telenetLogo}
                alt={"Telenet Logo"}
                width={32}
                height={32}
              />
              <span> Telenet Information</span>
            </FormTitle>
            <Field
              label="Telenet Account Manager"
              component={TextInput}
              name="telenetAccountManager"
            />
            <Field
              label="Telenet Customer ID"
              component={TextInput}
              name="telenetCustomerId"
            />
          </>
        )}
      </div>
      <div className="m-portlet__foot m-portlet__foot--fit">
        <div className="m-form__actions">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-7">
              <button
                type="submit"
                className="btn btn-accent m-btn m-btn--air m-btn--custom"
                disabled={submitting}
              >
                Save changes
              </button>
              &nbsp;&nbsp;
              <button
                type="reset"
                className="btn btn-secondary m-btn m-btn--air m-btn--custom"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Cancel
              </button>
            </div>
            <div className="col-2">
              <button
                type="button"
                className="btn btn-danger m-btn m-btn--air m-btn--custom"
                onClick={() => handleDelete()}
              >
                Delete account
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export default reduxForm({
  // a unique name for the form
  form: "companyProfile",
  enableReinitialize: true
})(CompanyProfileForm);
