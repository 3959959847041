import React from "react";
import { ModalBorderFooter } from "../styles";
import { Flex } from "rebass";
import { Check } from "styled-icons/fa-solid";
import Icon from "components/common/Icon";
import Button, { TextButton } from "components/common/Button";
import styled from "styled-components";

export interface IStages<T> extends StageTileProps {
  value: T;
}

interface StageTileProps {
  title: string;
  stageNumber: number;
  active: boolean;
  done: boolean;
}

const StageTile = ({
  title,
  stageNumber,
  active = true,
  done = false
}: StageTileProps) => {
  return (
    <StyledTile active={active}>
      <StyledCircle active={active}>
        {done ? (
          <Icon component={Check} size={16} strokeWidth="2.5px" />
        ) : (
          stageNumber
        )}
      </StyledCircle>
      <span>{title}</span>
    </StyledTile>
  );
};

type Props<T> = {
  currentStage: T;
  onBackPressed: () => void;
  onSubmit?: () => void;
  actionButtonText: string;
  disableActionButton?: boolean;
  showBackButton?: boolean;
  stageTiles: IStages<T>[];
};

const ModalFooter = <T,>({
  stageTiles,
  currentStage,
  showBackButton,
  onBackPressed,
  disableActionButton,
  onSubmit,
  actionButtonText
}: Props<T>) => {
  return (
    <ModalBorderFooter padding={"10px 60px"}>
      <StyledStages>
        {stageTiles.map((stageTile, index) => (
          <Flex key={`index-${stageTile.stageNumber}`} sx={{ gap: "24px" }}>
            {stageTile.stageNumber > 1 && <span>{">"}</span>}
            <StageTile
              stageNumber={stageTile.stageNumber}
              title={stageTile.title}
              active={stageTile.active ?? currentStage === stageTile.value}
              done={stageTile.done}
            />
          </Flex>
        ))}
      </StyledStages>
      <Flex>
        {showBackButton && <TextButton text="Back" onClick={onBackPressed} />}

        <Button
          borderRadius={20}
          lineHeight="20px"
          fontSize="16px"
          padding="10px 20px"
          minWidth={"174px"}
          type={"submit"}
          disabled={disableActionButton}
          onClick={onSubmit}
        >
          {actionButtonText}
        </Button>
      </Flex>
    </ModalBorderFooter>
  );
};

export default ModalFooter;

interface StyledTileProps extends React.CSSProperties {
  active?: boolean;
}

const StyledStages = styled(Flex)`
  gap: 24px;
  color: #3c3f4254;
`;

const StyledTile = styled(Flex)<StyledTileProps>`
  font-size: 14px;
  font-style: normal;
  line-height: 17.5px;
  letter-spacing: -0.05px;
  text-align: center;

  column-gap: 8px;
  justify-content: center;
  align-items: center;
  color: ${props => (props.active ? "#1c1e1f" : "#808082")};
`;

const StyledCircle = styled(Flex)<StyledTileProps>`
  width: 24px;
  height: 24px;
  color: white;
  background: ${props => (props.active ? "#1c1e1f" : "#3C3F4254")};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`;
