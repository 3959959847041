import React from "react";
import { Field, reduxForm } from "redux-form";
import { required } from "../../utils/Validation";
import Select from "../common/form/Select";
import FormTitle from "../common/form/FormTitle";

const TopicForm = props => {
  const { handleSubmit, pristine, reset, submitting, allTopics } = props;

  return (
    <form
      onSubmit={handleSubmit}
      className="m-form m-form--fit m-form--label-align-right"
    >
      <div className="m-portlet__body">
        <FormTitle>Follow topic</FormTitle>

        <Field
          validate={required}
          label="Topic"
          options={allTopics.map(t => ({
            value: t.id,
            label: `${t.label} - ${t.language}`
          }))}
          component={Select}
          name="topicId"
        />
      </div>
      <div className="m-portlet__foot m-portlet__foot--fit">
        <div className="m-form__actions">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-7">
              <button
                type="submit"
                className="btn btn-accent m-btn m-btn--air m-btn--custom"
                disabled={submitting}
              >
                Add
              </button>
              &nbsp;&nbsp;
              <button
                type="reset"
                className="btn btn-secondary m-btn m-btn--air m-btn--custom"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export default reduxForm({
  // a unique name for the form
  form: "followTopic",
  enableReinitialize: true
})(TopicForm);
