import numeral from "numeral";

const currencies = {
  USD: "$",
  EUR: "€"
};
const FormattedPrice = ({ price, currency = "USD", rounded = false }) => {
  if (rounded) {
    return currencies[currency.toUpperCase()] + numeral(price).format(`0,0`);
  } else {
    return currencies[currency.toUpperCase()] + numeral(price).format(`0,0.00`);
  }
};

export default FormattedPrice;
