import React from "react";
import { NavItemList, NavItem } from "./styles";
import { DesktopMac as Desktop } from "@styled-icons/material-twotone";
import { Home } from "@styled-icons/fluentui-system-filled/Home";
import { PieChart, Compass } from "@styled-icons/evaicons-solid";
import { Globe2 as Globe } from "@styled-icons/bootstrap/Globe2";
import { Groups } from "@styled-icons/material-rounded/Groups";
import { Calendar } from "@styled-icons/boxicons-solid/Calendar";
import Icon from "components/common/Icon";

type MainNavigationProps = {
  wasClicked: any;
};

const MainNavigation = ({ wasClicked }: MainNavigationProps) => {
  return (
    <NavItemList onClick={wasClicked}>
      <NavItem exact to={`/`}>
        <Icon component={Home} size={14} />
        <label>Dashboard</label>
      </NavItem>
      <NavItem to={`/accounts`}>
        <Icon component={Desktop} size={14} />
        <label>Accounts</label>
      </NavItem>
      <NavItem to={`/users`}>
        <Icon component={Groups} size={14} />
        <label>Users</label>
      </NavItem>

      <NavItem to={`/operations`}>
        <Icon component={PieChart} size={14} />
        <label>Operations</label>
      </NavItem>

      <NavItem to={`/calendars`}>
        <Icon component={Calendar} size={14} />
        <label>Calendars</label>
      </NavItem>

      <NavItem to={`/content/vetted/search`}>
        <Icon component={Compass} size={14} />
        <label>Content</label>
      </NavItem>

      <NavItem to={`/sources`}>
        <Icon component={Globe} size={14} />
        <label>Sources</label>
      </NavItem>
    </NavItemList>
  );
};

// export default withRouter(MainNavigation as any);
export default MainNavigation;
