import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";
import authReducer from "./AuthReducer";
import accountReducer from "./AccountReducer";
import postReducer from "./PostReducer";
import scheduledPostReducer from "./ScheduledPostReducer";
import suggestedPostReducer from "./SuggestedPostReducer";
import modalReducer from "./ModalReducer";
import { connectRouter } from "connected-react-router";
import * as authActions from "../actions/AuthActions";

const reducers = {
  auth: authReducer,
  accounts: accountReducer,
  posts: postReducer,
  scheduledPosts: scheduledPostReducer,
  suggestedPosts: suggestedPostReducer,
  modals: modalReducer,
  form: formReducer,
  toastr: toastrReducer
};

export function createReducers(history) {
  const appReducer = combineReducers({
    router: connectRouter(history),
    ...reducers
  });

  //Use a root reducer to be able to reset the state of everything on logout.
  //https://stackoverflow.com/a/35641992
  return (state = {}, action) => {
    if (action.type === authActions.LOGOUT) {
      state = undefined;
    }
    return appReducer(state, action);
  };
}
