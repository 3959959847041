import React, { Component } from "react";
import classNames from "classnames";

class Select extends Component {
  render() {
    const {
      input,
      label,
      meta: { touched, error, warning },
      fullWidth,
      options,
      multiple,
      showDefault = true
    } = this.props;
    const groupClasses = classNames({
      "form-group m-form__group row": true,
      "has-danger": touched && error,
      "has-warning": touched && warning
    });

    const inputWrapperClasses = classNames({
      "col-7": !fullWidth,
      "col-10": fullWidth
    });

    return (
      <div className={groupClasses}>
        <label className="col-2 col-form-label">{label}</label>
        <div className={inputWrapperClasses}>
          <select
            className="form-control m-input"
            {...input}
            multiple={multiple}
          >
            {showDefault && (
              <option key="" value="">
                Select...
              </option>
            )}
            {options.map(item => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          {error && touched && (
            <div className="form-control-feedback">{error}</div>
          )}
        </div>
      </div>
    );
  }
}

export default Select;
