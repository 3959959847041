import React from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { closeModal } from "../../../state/actions/ModalActions";
import { TagListItem, TagListModalBody } from "./styles";
import { modalStyles } from "../../modals/styles";

class TagListModal extends React.Component {
  onTagSelected = tag => {
    const { onVerifiedTagAdded, record, closeModal } = this.props;
    onVerifiedTagAdded(record, tag);
    closeModal();
  };

  getModalBody = () => {
    const { allAvailableTags, closeModal } = this.props;

    if (!allAvailableTags.length) {
      return (
        <TagListModalBody>
          <h3>There are no new tags to apply</h3>
          <button
            className="btn btn-accent m-btn m-btn--air m-btn--custom"
            onClick={closeModal}
          >
            Close
          </button>
        </TagListModalBody>
      );
    }

    return (
      <TagListModalBody>
        <h3>Select a tag to be added</h3>
        <br />
        <br />
        <div>
          {allAvailableTags.map(tag => (
            <TagListItem onClick={() => this.onTagSelected(tag)} key={tag}>
              {tag}
            </TagListItem>
          ))}
        </div>
      </TagListModalBody>
    );
  };

  render() {
    const { closeModal } = this.props;

    return (
      <Modal
        style={modalStyles("60vw")}
        isOpen={true}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        {this.getModalBody()}
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  closeModal: closeModal
})(TagListModal);
