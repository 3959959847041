import React from "react";
import { Route, Redirect } from "react-router";
import ReactLoading from "react-loading";
import * as authActions from "../../state/actions/AuthActions";
import { connect } from "react-redux";

class SecureRoute extends React.Component {
  constructor(props) {
    super(props);
    this.renderWrapper = this.renderWrapper.bind(this);
    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {
    if (this.props.hasValidToken) {
      this.props.fetchUserInfo();
    }
    this.setState({
      isLoading: false
    });
  }

  renderWrapper(renderProps) {
    if (this.props.isAuthenticated) {
      if (this.props.isAdmin) {
        const C = this.props.component;
        return this.props.render ? (
          this.props.render(renderProps)
        ) : (
          <C {...renderProps} />
        );
      } else {
        return <Redirect to={"/logout"} />;
      }
    }

    if (this.props.isFetchingUser || this.state.isLoading) {
      return <ReactLoading color="#666" type="bars" />;
    }

    return <Redirect to={"/login"} />;
  }

  render() {
    return <Route path={this.props.path} render={this.renderWrapper} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    isFetchingUser: state.auth.isFetchingUser,
    isAuthenticated: !!state.auth.currentUser,
    hasValidToken: state.auth.hasValidToken,
    isAdmin:
      state.auth.currentUser && state.auth.currentUser.roles.includes("admin")
  };
};

export default connect(mapStateToProps, {
  fetchUserInfo: authActions.userInfoRequested
})(SecureRoute);
