import React from "react";
import { ModalBorderHeader } from "../styles";
import IconButton from "components/common/IconButton";
import { X } from "styled-icons/feather";

type Props = {
  onClose: () => void;
  title: string;
};

const ModalHeader = ({ onClose, title }: Props) => {
  return (
    <ModalBorderHeader padding={"0 30px 4px"}>
      <span>{title}</span>
      <IconButton
        variant="secondary"
        icon={X}
        onClick={() => {
          console.log("Close Modal Called");
          onClose();
        }}
      />
    </ModalBorderHeader>
  );
};

export default ModalHeader;
