import React from "react";

import { connect } from "react-redux";
import Timeline from "../../../components/timeline/Timeline";
import PostItem from "../../../components/posts/PostItem";
import { callApi } from "../../../utils/ContentoApi";
import { toastr } from "react-redux-toastr";
import * as postActions from "../../../state/actions/PostActions";

class AccountSocialSentPage extends React.Component {
  state = { sentPosts: [], isDeleting: false };

  componentDidMount() {
    this.refreshPosts();
  }

  refreshPosts() {
    this.props.fetchPosts({ list: "sent" });
  }

  handleDelete(post) {
    this.setState({ isDeleting: true });

    callApi({
      method: "delete",
      url: `/posts/${post.id}`
    })
      .then(() => {
        toastr.success(`Successfully deleted post.`);
        this.refreshPosts();
        this.setState({ isDeleting: false });
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({ isDeleting: false });
      });
  }

  render() {
    const { isDeleting } = this.state;
    const { sentPosts } = this.props;
    return (
      <div className="m-portlet__body">
        <Timeline>
          {sentPosts.map(post => (
            <PostItem
              key={post.id}
              post={post}
              onDelete={this.handleDelete.bind(this)}
              isDeleting={isDeleting}
            />
          ))}
        </Timeline>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    account: state.accounts.selectedAccount,
    sentPosts: state.posts.data.sent
  };
};

export default connect(mapStateToProps, {
  fetchPosts: postActions.fetchPosts
})(AccountSocialSentPage);
