import React from "react";

import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { callApi } from "../../../utils/ContentoApi";

class MarketingPlanKeywordsPage extends React.Component {
  state = { isLoading: false, keywords: [] };

  componentDidMount() {
    //fetch keywords
    this.refreshKeywords();
  }

  refreshKeywords() {
    callApi({
      method: "get",
      url: `/accounts/${this.props.account.id}/keywords`
    })
      .then(keywords => {
        this.setState({ keywords: keywords, isLoading: true });
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({ keywords: [], isLoading: false });
      });
  }

  render() {
    const { keywords } = this.state;
    return (
      <div className="m-portlet__body">
        <table className="table m-table m-table--head-bg-success">
          <thead>
            <tr>
              <th>Keyword</th>
            </tr>
          </thead>
          <tbody>
            {keywords.map(keyword => (
              <tr key={keyword}>
                <td>{keyword}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    account: state.accounts.selectedAccount
  };
};

export default connect(mapStateToProps, {})(MarketingPlanKeywordsPage);
