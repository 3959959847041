import React, { Component } from "react";
import classNames from "classnames";

class TextAreaInput extends Component {
  render() {
    const {
      input,
      label,
      meta: { touched, error, warning },
      placeholder,
      fullWidth
    } = this.props;
    const groupClasses = classNames({
      "form-group m-form__group row": true,
      "has-danger": touched && error,
      "has-warning": touched && warning
    });

    const inputWrapperClasses = classNames({
      "col-7": !fullWidth,
      "col-10": fullWidth
    });

    return (
      <div className={groupClasses}>
        <label htmlFor="example-text-input" className="col-2 col-form-label">
          {label}
        </label>
        <div className={inputWrapperClasses}>
          <textarea
            className="form-control m-input"
            {...input}
            placeholder={placeholder}
          >
            {input.value}
          </textarea>
          {error && touched && (
            <div className="form-control-feedback">{error}</div>
          )}
        </div>
      </div>
    );
  }
}

export default TextAreaInput;
