import React from "react";
import axios from "axios";
import ReactLoading from "react-loading";

import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import DocumentUpload from "../../../components/accounts/DocumentUpload";

class MarketingPlanDocumentsPage extends React.Component {
  state = {
    isLoading: true,
    documentPath: "",
    uploadVisible: false
  };

  componentDidMount() {
    //fetch keywords
    this.setHasDocument();
  }

  setHasDocument() {
    const path = `https://storage.googleapis.com/contento-documents/marketing-plan/${this.props.account.id}.pdf`;
    //Check google storage for document.
    axios
      .head(path)
      .then(result => {
        this.setState({ documentPath: path, isLoading: false });
      })
      .catch(error => {
        this.setState({
          documentPath: "",
          uploadVisible: true,
          isLoading: false
        });
      });
  }

  handleDownloadPDF = () => {
    window.open(`${this.state.documentPath}?time=${Math.random()}`);
  };

  handleUploadPDF = () => {
    this.setState({
      uploadVisible: true
    });
  };

  onDocumentUpload = doc => {
    if (doc[0]) {
      toastr.success("Document uploaded");
      this.setState({
        documentPath: doc[0].path,
        uploadVisible: false
      });
    } else {
      toastr.success("Error uploading document");
    }
  };

  render() {
    const { account } = this.props;
    const { documentPath, uploadVisible, isLoading } = this.state;

    return (
      <div className="m-portlet__body">
        {isLoading && (
          <ReactLoading type="spin" color="#ccc" width={40} height={40} />
        )}

        {documentPath && (
          <button
            onClick={this.handleDownloadPDF}
            className="btn btn-primary m-btn m-btn--icon m-btn--pill"
            style={{ marginBottom: 20 }}
          >
            <span>
              <i className="fa fa-download " />
              <span>Download PDF</span>
            </span>
          </button>
        )}

        {!uploadVisible && !isLoading && (
          <button
            onClick={this.handleUploadPDF}
            className="btn btn-outline-primary m-btn m-btn--icon m-btn--pill"
            style={{ marginLeft: 20, marginBottom: 20 }}
          >
            <span>
              <i className="fa fa-upload " />
              <span>Upload PDF</span>
            </span>
          </button>
        )}

        {uploadVisible && (
          <DocumentUpload
            onDocumentUpload={this.onDocumentUpload}
            accountId={account.id}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    account: state.accounts.selectedAccount
  };
};

export default connect(mapStateToProps, {})(MarketingPlanDocumentsPage);
