import React from "react";
import range from "lodash/range";
import ReactLoading from "react-loading";

export default function PaginationV2({
  onSelectPage,
  onSelectLimit,
  totalPages,
  totalRecords,
  page,
  limit,
  loading = false
}) {
  if (!totalRecords) {
    return null;
  }

  const leftBound = page - 2;
  const rightBound = page + 2;
  const lastPage = totalPages;

  const startPage = leftBound > 1 ? leftBound : 1;
  const endPage = rightBound < lastPage ? rightBound : lastPage;

  const pages = range(startPage, endPage + 1);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "10px"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "5px"
        }}
      >
        {loading && (
          <div style={{ width: "25px", height: "20px" }}>
            <ReactLoading color="#777" type="spin" width={20} height={20} />
          </div>
        )}
        <span>
          Showing <strong>{(page - 1) * limit + 1}</strong> to{" "}
          <strong>{page * limit}</strong> of <strong>{totalRecords}</strong>{" "}
          entries
        </span>
      </div>

      <div
        className="dataTables_length"
        id="m_table_1_length"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <ul className="pagination" style={{ margin: "0 5px 0 0" }}>
          {page > 1 && (
            <li
              className="paginate_button page-item previous"
              id="m_table_1_previous"
            >
              <a
                href="#page-link"
                onClick={e => {
                  e.preventDefault();
                  onSelectPage(page - 1);
                }}
                aria-controls="m_table_1"
                data-dt-idx="0"
                tabIndex="0"
                className="page-link"
              >
                <i className="la la-angle-left" />
              </a>
            </li>
          )}
          {pages.map((p, index) => {
            return (
              <li
                key={index}
                className={`paginate_button page-item ${
                  p === page ? "active" : ""
                }`}
              >
                <a
                  href="#page-link"
                  onClick={e => {
                    e.preventDefault();
                    onSelectPage(p);
                  }}
                  className="page-link"
                >
                  {p}
                </a>
              </li>
            );
          })}

          {page < totalPages && (
            <li className="paginate_button page-item next">
              <a
                onClick={e => {
                  e.preventDefault();
                  onSelectPage(page + 1);
                }}
                href="#page-link"
                className="page-link"
              >
                <i className="la la-angle-right" />
              </a>
            </li>
          )}
        </ul>

        <select
          name="m_table_1_length"
          onChange={event => {
            onSelectLimit(event.target.value);
          }}
          value={limit}
          className="form-control form-control-sm"
          style={{ width: "60px" }}
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
      </div>
    </div>
  );
}
