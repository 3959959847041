import * as postActions from "../actions/PostActions";

const initialState = {
  data: { suggested: [], scheduled: [], sent: [] },
  isFetching: false
};

const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case postActions.FETCH_POSTS:
      return Object.assign({}, state, {
        data: initialState.data,
        isFetching: true
      });
    case postActions.POSTS_FETCHED:
      const data = Object.assign({}, state.data, {
        [action.list]: action.posts
      });

      return Object.assign({}, state, {
        data: data,
        isFetching: false
      });
    default:
      return state;
  }
};

export default postReducer;
