import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import {
  RecordContainer,
  ThumbnailContainer,
  Thumbnail,
  DetailsTitle,
  DetailsDescription,
  DetailsExtraInfo,
  Tag,
  SuggestButtonContainer,
  DetailsContainer,
  TagTableHeader,
  TagTableCell,
  TagTable,
  AddTagButton,
  RemovableTag
} from "./styles";
import { getTimeDifferenceElement } from "../../../utils/date";
import * as modalActions from "../../../state/actions/ModalActions";

class SearchResults extends React.Component {
  promptAddVerifiedTag = record => {
    const { onVerifiedTagAdded, openModal, allAvailableTags } = this.props;

    openModal("TAG_LIST_MODAL", {
      onVerifiedTagAdded,
      record,
      // remove the tags already applied
      allAvailableTags: allAvailableTags.filter(
        t => !record.verified_tags || !record.verified_tags.includes(t)
      )
    });
  };

  promptViewArticleContent = record => {
    const { openModal } = this.props;
    openModal("ARTICLE_CONTENT_MODAL", {
      record
    });
  };

  promptDebugArticle = record => {
    const { openModal } = this.props;
    openModal("ARTICLE_DEBUG_MODAL", {
      record
    });
  };

  render() {
    const {
      records,
      selectedAccount,
      onSuggest,
      onVerifiedTagAdded,
      onVerifiedTagRemoved
    } = this.props;

    if (!records || !records.length) {
      return <h4>No results for the informed filters</h4>;
    }

    const recordContainers = records.map(record => {
      const isSuggestedAlready =
        selectedAccount &&
        !!record.suggested_to.find(s => s.account_id === selectedAccount.id);

      return (
        <RecordContainer key={record.id}>
          <ThumbnailContainer>
            <Thumbnail src={record.thumbnail}></Thumbnail>
          </ThumbnailContainer>
          <DetailsContainer>
            <div>
              <DetailsTitle
                href={record.url}
                target="_blank"
                referrerPolicy="no-referrer"
              >
                {record.title || record.url}
              </DetailsTitle>
              <i
                title="Preview Article"
                onClick={() => {
                  this.promptViewArticleContent(record);
                }}
                style={{ cursor: "pointer" }}
                className="m-menu__link-icon flaticon-eye m--margin-left-10"
              />
              <i
                title="Debug Article"
                onClick={() => {
                  this.promptDebugArticle(record);
                }}
                style={{ cursor: "pointer" }}
                className="m-menu__link-icon flaticon-search m--margin-left-10"
              />
            </div>
            <DetailsDescription>{record.description}</DetailsDescription>
            <div>
              <span>Domain: </span>
              <DetailsExtraInfo>{record.domain}</DetailsExtraInfo>
              <span>Published: </span>
              <DetailsExtraInfo
                title={moment(record.published_date).format("DD/MM/YYYY HH:mm")}
              >
                {getTimeDifferenceElement({
                  date: record.published_date,
                  applyColors: false
                })}
              </DetailsExtraInfo>
            </div>
            <div>
              <TagTable>
                <thead>
                  <tr>
                    <TagTableHeader>Source Tags</TagTableHeader>
                    <TagTableHeader>Predicted Tags</TagTableHeader>
                    <TagTableHeader>Verified Tags</TagTableHeader>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TagTableCell>
                      {record.source_tags &&
                        record.source_tags.map(tag => (
                          <Tag
                            onClick={() => onVerifiedTagAdded(record, tag)}
                            key={tag}
                          >
                            {tag}
                          </Tag>
                        ))}
                    </TagTableCell>
                    <TagTableCell>
                      {record.predicted_tags &&
                        record.predicted_tags.map(tag => (
                          <Tag
                            onClick={() => onVerifiedTagAdded(record, tag)}
                            key={tag}
                          >
                            {tag}
                          </Tag>
                        ))}
                    </TagTableCell>
                    <TagTableCell>
                      {record.verified_tags &&
                        record.verified_tags.map(tag => (
                          <RemovableTag
                            onClick={() => onVerifiedTagRemoved(record, tag)}
                            key={tag}
                          >
                            {tag}
                          </RemovableTag>
                        ))}
                      <AddTagButton
                        onClick={() => this.promptAddVerifiedTag(record)}
                      >
                        + Add Tag
                      </AddTagButton>
                    </TagTableCell>
                  </tr>
                </tbody>
              </TagTable>
            </div>
          </DetailsContainer>
          <SuggestButtonContainer>
            <button
              disabled={
                selectedAccount && !isSuggestedAlready ? "" : "disabled"
              }
              type="button"
              className="btn m-btn--square  btn-primary btn-sm"
              onClick={() => onSuggest(record, selectedAccount.id)}
            >
              {isSuggestedAlready
                ? "Already Suggested"
                : !selectedAccount
                  ? "Select an account to suggest"
                  : "Suggest"}
            </button>
          </SuggestButtonContainer>
        </RecordContainer>
      );
    });

    return recordContainers;
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  openModal: modalActions.openModal
})(SearchResults);
