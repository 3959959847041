import React from "react";
import { Box, Text } from "rebass/styled-components";
import { Check, ExclamationCircle } from "styled-icons/fa-solid";
import styled from "styled-components";

const inputSizes = {
  md: {
    borderRadius: 8,
    padding: "12px 16px",
    fontSize: 16,
    marginTop: 8
  },
  sm: {
    borderRadius: 8,
    padding: "8px 12px",
    fontSize: 14,
    marginTop: 0
  }
};

type InputSizes = keyof typeof inputSizes;

export const Form = styled.form``;

export const HelperText = styled(Text)`
  color: ${props => props.theme.colors.text03};
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  letter-spacing: -0.16px;
  button {
    color: ${props => props.theme.colors.blue};
    background: none;
    margin: 0;
    padding: 2px 0px;
    border: none;
    cursor: pointer;
    font-weight: 700;
    border-bottom: 1px solid transparent;

    &:hover {
      color: ${props => props.theme.colors.linkBlueHover};
      border-bottom: 1px solid ${props => props.theme.colors.linkBlueHover};
    }
    &:active {
      color: ${props => props.theme.colors.linkBlueActive};
      border-bottom: 1px solid ${props => props.theme.colors.linkBlueActive};
    }
  }
`;

export const ErrorMessage = styled(Box)`
  box-sizing: border-box;
  color: ${props => props.theme.colors.red};
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  margin: 6px 0;
`;

export const StyledInputWrapper = styled.div`
  position: relative;
`;

export interface StyledInputProps extends React.CSSProperties {
  inputSize?: InputSizes;
  hasError?: boolean;
}

export const StyledInput = styled.input<StyledInputProps>`
  line-height: 140%;
  font-size: 16px;
  transition: border-color 0.2s ease-in-out 0s;
  font-weight: 400;
  letter-spacing: -0.16px;

  margin-top: 8px;
  border: 1px solid
    ${props =>
      props.hasError ? props.theme.colors.danger : props.theme.colors.grey02};
  border-radius: ${props =>
    props.borderRadius ?? inputSizes[props.inputSize ?? "md"].borderRadius}px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 11px 30px 11px 16px;

  &::placeholder {
    color: ${props => props.theme.colors.text04};
  }

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.grey01};
    color: ${props => props.theme.colors.text02};
  }
`;

export const StyledInputLabel = styled.span`
  color: ${props => props.theme.colors.text01};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.5px;

  & small {
    display: block;
    margin-bottom: 2px;
  }
`;

export const StyledInputFieldset = styled(Box)`
  margin-bottom: 23px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.16px;
`;

export const StyledTextArea = styled.textarea<StyledInputProps>`
  resize: none;
  height: ${props => props.height};
  margin-top: 8px;
  border: 1px solid
    ${props =>
      props.hasError ? props.theme.colors.danger : props.theme.colors.grey02};
  border-radius: ${props =>
    props.borderRadius ?? inputSizes[props.inputSize ?? "md"].borderRadius}px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 12px 16px 12px 16px;
  line-height: 22px;
  font-size: 16px;
  transition: border-color 0.2s ease-in-out 0s;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
  }
`;

export const StyledInputIconWrapper = styled.div`
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  top: 18px;
  right: 10px;
  border-radius: 50%;
  top: 18px;
  cursor: pointer;
  color: ${props => props.theme.colors.text03};

  span {
    font-size: 18px;
  }
  &:hover {
    background-color: ${props => props.theme.colors.grey01};
    color: ${props => props.theme.colors.text01};
  }
`;

export const SuccessIndicatorWrapper = styled.div`
  background: ${props => props.theme.colors.green};
  border-radius: 50%;
  height: 16px;
  width: 16px;

  /* color: ${props => props.theme.colors.white}; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const SuccessIndicator = styled(Check)`
  background: ${props => props.theme.colors.green};
  border-radius: 50%;
  color: ${props => props.theme.colors.white};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const StyledSelect = styled.select<StyledInputProps>`
  appearance: none;

  line-height: 140%;
  font-size: 16px;
  transition: border-color 0.2s ease-in-out 0s;
  font-weight: 400;
  letter-spacing: -0.16px;

  margin-top: 8px;
  border: 1px solid
    ${props =>
      props.hasError ? props.theme.colors.danger : props.theme.colors.grey02};
  border-radius: ${props => inputSizes[props.inputSize ?? "md"].borderRadius}px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 11px 16px;

  &::placeholder {
    color: ${props => props.theme.colors.text04};
  }

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.grey01};
    color: ${props => props.theme.colors.text02};
  }
`;

export const StyledOption = styled.option<StyledInputProps>`
  appearance: none;
  width: fit-content;
  padding: 20px;
  border-radius: 10px;
`;

export const IconExclamationCircle = styled(ExclamationCircle)`
  width: 15px;
  height: 15px;
  margin-right: 5px;
`;
