import styled from "styled-components";
import Button from "components/common/Button";

interface StyledButtonProps {
  active: boolean;
}

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const SearchbarsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 10px;
  padding-left: 5px;
`;

export const FilterText = styled.span`
  margin-right: 30px;
`;

export const SearchBar = styled.input`
  border: none;
  padding: 8px;
  margin-right: 10px;
  margin-left: 10px;
  max-width: 230px;
  min-width: 200px;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  color: black;
  margin-top: 1px;
`;
export const VerticalIconContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`;

export const StyledButton = styled(Button)<StyledButtonProps>`
  background: ${props => (props.active ? "#000" : "#6074801f")};
  color: ${props => (props.active ? "#fff" : "#1c1e1f")};
`;
